import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { submit, initialize } from 'redux-form'

import Button from '../../../atoms/Button'

import PrilezitostiModal from '../PrilezitostiModal'
import FilterForm from './FilterForm'

import { FORMS } from '../../../utils/enums'

function FilterModal(props) {
	const { isOpen, onCloseButton, onSubmit, initValues, resetFilters, leadFilterValues } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [activeMenuItem, setActiveMenuItem] = useState(null)
	const [menuItems, setMenuItems] = useState([])

	useEffect(() => {
		if (isOpen) {
			dispatch(initialize(FORMS.PRILEZITOSTI_FILTER, initValues))
		}
	}, [initValues, dispatch, isOpen])

	useEffect(() => {
		if (leadFilterValues?.filter) {
			setMenuItems(
				leadFilterValues?.filter?.map((item) => {
					return {
						label: item?.nazov,
						value: {
							id: item?.technickyNazov,
							options: item?.hodnoty,
							label: item?.nazov
						}
					}
				})
			)
		}
	}, [leadFilterValues])

	return (
		<PrilezitostiModal
			isOpen={isOpen}
			onCloseButton={onCloseButton}
			size={'m'}
			title={<h3>{t('components:Prilezitosti.Rozšírený filter')}</h3>}
			handleOutsideClick={onCloseButton}
			footer={
				<div className='flex-box-center'>
					<Button classes={'dark'} type={'line'} label={t('translation:Common.Zrušiť vybrané parametre')} onClick={resetFilters} />
					<div className='btn-box-modal-footer'>
						<Button type={'secondary'} label={t('translation:Common.Zavrieť')} onClick={onCloseButton} />
						<Button label={t('components:Prilezitosti.Zobraziť výsledky')} onClick={() => dispatch(submit(FORMS.PRILEZITOSTI_FILTER))} />
					</div>
				</div>
			}
			body={
				<div className={'modal-filter-body-layout'}>
					<div className={'left-side-menu-box'}>
						{menuItems.map((menuItem, index) => (
							<div
								className={cx('menu-item', { last: index + 1 === menuItems?.length }, { active: activeMenuItem?.id === menuItem?.value?.id })}
								onClick={() => setActiveMenuItem(menuItem?.value)}
							>
								{menuItem?.label}
							</div>
						))}
					</div>
					<div className={'filter-menu-body'}>
						<FilterForm selectedFilter={activeMenuItem} filterOptions={leadFilterValues?.filter} onSubmit={(formValues) => onSubmit(formValues)} />
					</div>
				</div>
			}
			modalBodyClasses={'filter-modal-body'}
			destroyOnClose
		/>
	)
}

FilterModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCloseButton: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	initValues: PropTypes.any.isRequired,
	resetFilters: PropTypes.func.isRequired,
	leadFilterValues: PropTypes.any.isRequired
}

export default FilterModal
