import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { get, isEmpty } from 'lodash'
import { openDataUriWindow } from '../../../utils/files'
import { formatDate } from '../../../utils/date'
import ElementEmptyContent from '../../ElementEmptyContent'

function DokumentyTabContent(props) {
	const { dokumenty } = props
	const { t } = useTranslation()

	return (
		<div>
			<div className={'info-box-title mb-10'}>{t('components:Prilezitosti.Dokumenty príležitosti')}</div>
			{isEmpty(dokumenty) ? (
				<div className={'relative'} style={{ minHeight: '318px' }}>
					<ElementEmptyContent text={t('translation:Prilezitosti.Príležitosť neobsahuje žiadne dokumenty')} />
				</div>
			) : (
				<div className={'info-box grid grid-cols-2 gap-10'}>
					{dokumenty.map((dokument) => (
						<div className={'document-wrapper flex justify-between items-center'}>
							<a
								className='document document-name'
								onClick={(e) => {
									e.preventDefault()
									openDataUriWindow(dokument.id, dokument.nazov)
								}}
							>
								{get(dokument, 'nazov')}
							</a>
							<span className={'timestamp date'}>{formatDate(get(dokument, 'pridanyOd'), '-', 'DD.MM.YYYY')}</span>
						</div>
					))}
				</div>
			)}
		</div>
		/* TODO use Dokumenty aktivít when BE is ready */
	)
}

DokumentyTabContent.propTypes = {
	dokumenty: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default DokumentyTabContent
