import { get } from 'lodash'
import { RESET_STORAGE } from '../types/search'

import {
	OP_PRILEZITOSTI_LOAD_DONE,
	OP_PRILEZITOSTI_LOAD_FAIL,
	OP_PRILEZITOSTI_LOAD_START,
	PRILEZITOST_DETAIL_LOAD_DONE,
	PRILEZITOST_DETAIL_LOAD_FAIL,
	PRILEZITOST_DETAIL_LOAD_START,
	OP_PRILEZITOSTI_MOZNOSTI_LOAD_DONE,
	OP_PRILEZITOSTI_MOZNOSTI_LOAD_FAIL,
	OP_PRILEZITOSTI_MOZNSOTI_LOAD_START,
	PRILEZITOSTI_LOAD_DONE,
	PRILEZITOSTI_LOAD_FAIL,
	PRILEZITOSTI_LOAD_START,
	PRILEZITOSTI_RESET_FILTERS,
	PRILEZITOSTI_RESET_OP_FILTERS,
	PRILEZITOSTI_SET_FILTERS,
	PRILEZITOSTI_SET_OP_FILTERS,
	PRILEZITOSTI_IDENTITA_LOAD_START,
	PRILEZITOSTI_IDENTITA_LOAD_DONE,
	PRILEZITOSTI_IDENTITA_LOAD_FAIL
} from '../types/prilezitosti'
import { PRILEZITOSTI_PAGE_SIZE_OPTIONS } from '../utils/enums'

export const initState = {
	prilezitosti: {
		data: [],
		page: 0,
		pageSize: PRILEZITOSTI_PAGE_SIZE_OPTIONS['10'],
		total: 0,
		isLoading: false,
		isFailure: false
	},
	filters: {},
	opFilters: {},
	opPrilezitosti: {
		data: [],
		page: 0,
		pageSize: PRILEZITOSTI_PAGE_SIZE_OPTIONS['100'],
		total: 0,
		isLoading: false,
		isFailure: false
	},
	prilezitost: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	moznosti: {
		data: [],
		isLoading: false,
		isFailure: false
	},
	identita: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case PRILEZITOSTI_LOAD_START:
			return {
				...state,
				prilezitosti: {
					...state.prilezitosti,
					isLoading: true,
					isFailure: false
				}
			}
		case PRILEZITOSTI_LOAD_DONE:
			return {
				...state,
				prilezitosti: {
					...state.prilezitosti,
					data: get(action, 'payload.prilezitosti.content'),
					pageSize: get(action, 'payload.prilezitosti.pageSize'),
					page: get(action, 'payload.prilezitosti.page'),
					total: get(action, 'payload.prilezitosti.total'),
					isLoading: false,
					isFailure: false
				}
			}
		case PRILEZITOSTI_LOAD_FAIL:
			return {
				...state,
				prilezitosti: {
					...initState.prilezitosti,
					isLoading: false,
					isFailure: true
				}
			}
		case PRILEZITOSTI_SET_FILTERS:
			return {
				...state,
				filters: {
					...get(action, 'payload.filters')
				}
			}
		case PRILEZITOSTI_RESET_FILTERS:
			return {
				...state,
				filters: {
					...initState.filters
				}
			}
		case OP_PRILEZITOSTI_LOAD_START:
			return {
				...state,
				opPrilezitosti: {
					...state.prilezitosti,
					isLoading: true,
					isFailure: false
				}
			}
		case OP_PRILEZITOSTI_LOAD_DONE:
			return {
				...state,
				opPrilezitosti: {
					...state.opPrilezitosti,
					data: get(action, 'payload.prilezitosti.content'),
					pageSize: get(action, 'payload.prilezitosti.pageSize'),
					page: get(action, 'payload.prilezitosti.page'),
					total: get(action, 'payload.prilezitosti.total'),
					isLoading: false,
					isFailure: false
				}
			}
		case OP_PRILEZITOSTI_LOAD_FAIL:
			return {
				...state,
				opPrilezitosti: {
					...initState.opPrilezitosti,
					isLoading: false,
					isFailure: true
				}
			}
		case PRILEZITOSTI_SET_OP_FILTERS:
			return {
				...state,
				opFilters: {
					...get(action, 'payload.filters')
				}
			}
		case PRILEZITOSTI_RESET_OP_FILTERS:
			return {
				...state,
				opFilters: {
					...initState.opFilters
				}
			}
		case PRILEZITOST_DETAIL_LOAD_START:
			return {
				...state,
				prilezitost: {
					...state.prilezitost,
					isLoading: true,
					isFailure: false
				}
			}
		case PRILEZITOST_DETAIL_LOAD_DONE:
			return {
				...state,
				prilezitost: {
					...state.prilezitost,
					data: get(action, 'payload.prilezitost'),
					isLoading: false,
					isFailure: false
				}
			}
		case PRILEZITOST_DETAIL_LOAD_FAIL:
			return {
				...state,
				prilezitost: {
					...initState.prilezitost,
					isLoading: false,
					isFailure: true
				}
			}
		case OP_PRILEZITOSTI_MOZNSOTI_LOAD_START:
			return {
				...state,
				moznosti: {
					...initState.moznosti,
					isLoading: true
				}
			}
		case OP_PRILEZITOSTI_MOZNOSTI_LOAD_DONE:
			return {
				...state,
				moznosti: {
					...get(action, 'payload.moznosti'),
					isLoading: false,
					isFailure: false
				}
			}
		case OP_PRILEZITOSTI_MOZNOSTI_LOAD_FAIL:
			return {
				...state,
				moznosti: {
					...initState.moznosti,
					isFailure: true
				}
			}
		case PRILEZITOSTI_IDENTITA_LOAD_START:
			return {
				...state,
				identita: {
					...initState.identita,
					isLoading: true
				}
			}
		case PRILEZITOSTI_IDENTITA_LOAD_DONE:
			return {
				...state,
				identita: {
					data: { ...get(action, 'payload.identita') },
					isLoading: false,
					isFailure: false
				}
			}
		case PRILEZITOSTI_IDENTITA_LOAD_FAIL:
			return {
				...state,
				identita: {
					...initState.identita,
					isFailure: true
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
