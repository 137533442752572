import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash'

// icons
import { Link } from 'react-router-dom'
import { ReactComponent as WarningIcon } from '../../../resources/img/icons/icon_namietka.svg'

// utils
import { formatDate } from '../../../utils/date'
import { formatAddress } from '../../../utils/address'
import { formatDegrees } from '../../../utils/degrees'
import { setRouteParams, ZMLUVY_ZOZNAM } from '../../../utils/routes'
import { getAccessToken } from '../../../utils/auth'
import { openLiveAgentTicketPreview } from '../../../utils/files'

function VseobecneTabContent(props) {
	const { prilezitost } = props
	const { t } = useTranslation()
	const isFO = isEmpty(get(prilezitost, 'opLead.ICO')) && isEmpty(get(prilezitost, 'opLead.obchodneMeno'))

	const isSameContactInfo = (opInfo, leadOpinfo) => {
		return opInfo === leadOpinfo
	}

	return (
		<>
			<div className={'info-row'}>
				<div className={'info-column info-box flex flex-col gap-10'}>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Stav')}</div>
						</div>
						<div className={'info-column info-value highlighted'}>
							<div>{get(prilezitost, 'stav.nazov', '-')}</div>
						</div>
					</div>
					{/* TODO typ chyba */}
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Typ príležitosti')}</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{get(prilezitost, 'typ', '-')}</div>
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Zdroj príležitosti')}</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{get(prilezitost, 'zdroj.nazov', '-')}</div>
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Kampaň')}</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{get(prilezitost, 'kampan', '-')}</div>
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Cieľová skupina')}</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{get(prilezitost, 'cielovaSkupina', '-')}</div>
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Agent')}</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{get(prilezitost, 'riesitel', '-')}</div>
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Priradený kanál')}</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{get(prilezitost, 'priradenyKanal.nazov', '-')}</div>
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Priradené oddelenie')}</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{get(prilezitost, 'priradeneOddelenie.nazov', '-')}</div>
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Lead ID')}</div>
						</div>
						<div className={'info-column info-value'}>
							{get(prilezitost, 'leadId') ? (
								<a
									href={`/api/v0/nela/lead-detail/${get(prilezitost, 'leadId')}?accessToken=${getAccessToken()}`}
									target='_blank'
									rel='noopener noreferrer'
								>
									{get(prilezitost, 'leadId', '-')}
								</a>
							) : (
								<div>{get(prilezitost, 'leadId', '-')}</div>
							)}
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.LA tiket ID')}</div>
						</div>
						<div className={'info-column info-value'}>
							{get(prilezitost, 'laTiketDTO.laTiketId') && get(prilezitost, 'laTiketDTO.laTiketURL') ? (
								<a href={get(prilezitost, 'laTiketDTO.laTiketURL')} target='_blank' rel='noopener noreferrer'>
									{get(prilezitost, 'laTiketDTO.laTiketId')}
								</a>
							) : (
								<div>{get(prilezitost, 'laTiketDTO.laTiketId', '-')}</div>
							)}
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Detail LA konverzácie')}</div>
						</div>
						<div className={'info-column info-value'}>
							{get(prilezitost, 'laTiketDTO.laTiketId') ? (
								<a
									className='file'
									onClick={() => openLiveAgentTicketPreview(get(prilezitost, 'laTiketDTO.laTiketId'))}
									data-type='general'
									style={{ cursor: 'pointer' }}
								>
									<strong>{get(prilezitost, 'laTiketDTO.laTiketId')}</strong>
								</a>
							) : (
								<div>{get(prilezitost, 'laTiketDTO.laTiketId', '-')}</div>
							)}
						</div>
					</div>
				</div>
				<div className={'info-column flex flex-col justify-between'}>
					<div className={'info-box flex flex-col gap-10'}>
						<div className={'info-row'}>
							<div className={'info-column'}>
								<div>{t('components:Prilezitosti.Vytvorená od')}</div>
							</div>
							<div className={'info-column info-value'}>
								<div>{formatDate(get(prilezitost, 'vytvorenaOd'), '-', 'DD.MM.YYYY')}</div>
							</div>
						</div>
						<div className={'info-row'}>
							<div className={'info-column'}>
								<div>{t('components:Prilezitosti.Uzatvorená od')}</div>
							</div>
							<div className={'info-column info-value'}>
								<div>{formatDate(get(prilezitost, 'uzatvorenaOd'), '-', 'DD.MM.YYYY')}</div>
							</div>
						</div>
						<div className={'info-row'}>
							<div className={'info-column'}>
								<div>{t('components:Prilezitosti.Súvisiace zmluvy')}</div>
							</div>
							<div className={'info-column info-value flex flex-wrap gap-5'}>
								{isEmpty(get(prilezitost, 'vztahy')) && '-'}
								{get(prilezitost, 'vztahy', []).map((vztah) => (
									<Link to={setRouteParams(ZMLUVY_ZOZNAM, prilezitost.op.cislo)}>{vztah.zmluvnyVztahCislo}</Link>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			{!isEmpty(get(prilezitost, 'doplnujuceInfo')) && (
				<>
					<div className={'info-box-title mt-20 mb-10'}>{t('components:Prilezitosti.Doplňujúce informácie')}</div>
					<div className={'info-box flex flex-col gap-10'}>
						{Object.keys(get(prilezitost, 'doplnujuceInfo', {})).map((key) => (
							<div key={key} className={'info-row'}>
								<div className={'info-column'}>
									<div>{key}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{get(prilezitost, `doplnujuceInfo.${key}`, '-')}</div>
								</div>
							</div>
						))}
					</div>
				</>
			)}
			<div className={'info-box-title mt-20 mb-10'}>{t('components:Prilezitosti.Obchodný partner')}</div>
			<div className={'info-row'}>
				<div className={'info-column info-box flex flex-col gap-10'}>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Typ osoby')}</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{isFO ? t('components:Prilezitosti.fyzická') : t('components:Prilezitosti.právnicka')}</div>
						</div>
					</div>
					{isFO ? (
						<>
							<div className={'info-row'}>
								<div className={'info-column'}>
									<div>{t('components:Prilezitosti.Titul pred')}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{formatDegrees(get(prilezitost, 'opLead.tituly'), true) || '-'}</div>
								</div>
							</div>
							<div className={'info-row'}>
								<div className={'info-column'}>
									<div>{t('components:Prilezitosti.Meno')}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{get(prilezitost, 'opLead.meno', '-')}</div>
								</div>
							</div>
							<div className={'info-row'}>
								<div className={'info-column'}>
									<div>{t('components:Prilezitosti.Priezvisko')}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{get(prilezitost, 'opLead.priezvisko', '-')}</div>
								</div>
							</div>
							<div className={'info-row'}>
								<div className={'info-column'}>
									<div>{t('components:Prilezitosti.Titul za')}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{formatDegrees(get(prilezitost, 'opLead.tituly'), true) || '-'}</div>
								</div>
							</div>
							<div className={'info-row'}>
								<div className={'info-column'}>
									<div>{t('components:Prilezitosti.Dátum narodenia')}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{formatDate(get(prilezitost, 'opLead.narodenieDatum'), '-', 'DD.MM.YYYY')}</div>
								</div>
							</div>
						</>
					) : (
						<>
							<div className={'info-row'}>
								<div className={'info-column'}>
									<div>{t('components:Prilezitosti.Obchodný názov')}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{get(prilezitost, 'opLead.obchodneMeno', '-')}</div>
								</div>
							</div>
							<div className={'info-row'}>
								<div className={'info-column'}>
									<div>{t('components:Prilezitosti.IČO')}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{get(prilezitost, 'opLead.ICO', '-')}</div>
								</div>
							</div>
							<div className={'info-row'}>
								<div className={'info-column'}>
									<div>{t('components:Prilezitosti.DIČ')}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{get(prilezitost, 'opLead.DIC', '-')}</div>
								</div>
							</div>
							<div className={'info-row'}>
								<div className={'info-column'}>
									<div>{t('components:Prilezitosti.IČ DPH')}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{get(prilezitost, 'opLead.ICDPH', '-')}</div>
								</div>
							</div>
						</>
					)}
				</div>
				<div className={'info-column info-box flex flex-col gap-10'}>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Osoba  oprávnená  na podpis')}</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{get(prilezitost, 'opLead.osobaOprNaPodpis', '-')}</div>
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div className={'flex gap-10 items-center'}>
								<div>{t('components:Prilezitosti.Email')}</div>
								{!isSameContactInfo(get(prilezitost, 'op.kontaktnyEmail', '-'), get(prilezitost, 'opLead.kontaktnyEmail', '-')) && (
									<WarningIcon />
								)}
							</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{get(prilezitost, 'opLead.kontaktnyEmail', '-')}</div>
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div className={'flex gap-10 items-center'}>
								<div>{t('components:Prilezitosti.Telefón')}</div>
								{!isSameContactInfo(get(prilezitost, 'op.kontaktnyTelefon'), get(prilezitost, 'opLead.kontaktnyTelefon')) && <WarningIcon />}
							</div>
						</div>
						<div className={'info-column info-value'}>
							<div>{get(prilezitost, 'opLead.kontaktnyTelefon', '-')}</div>
						</div>
					</div>

					<div className={'info-row'}>
						<div className={'info-column'}>
							<div className={'flex gap-10 items-center'}>
								<div>{isFO ? t('components:Prilezitosti.Trvalá adresa') : t('components:Prilezitosti.Adresa sídla')}</div>
								{!isSameContactInfo(
									formatAddress(get(prilezitost, 'op.adresaZakaznika')),
									formatAddress(get(prilezitost, 'opLead.adresaZakaznika'))
								) && <WarningIcon />}
							</div>
						</div>
						<div className={'info-column info-value'}>
							<div>
								{isEmpty(get(prilezitost, 'opLead.adresaZakaznika')) ? '-' : formatAddress(get(prilezitost, 'opLead.adresaZakaznika', '-'))}
							</div>
						</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>
							<div>{t('components:Prilezitosti.Korešpondenčná adresa')}</div>
						</div>
						<div className={'info-column info-value'}>
							<div>
								{isEmpty(get(prilezitost, 'opLead.adresaKorespondencna'))
									? '-'
									: formatAddress(get(prilezitost, 'opLead.adresaKorespondencna'))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={'info-box-note mt-10'}>
				<span className={'title'}>{t('components:Prilezitosti.Poznámka od klienta')}</span>
				<span>{get(prilezitost, 'poznamka', '-')}</span>
			</div>
			<div className={'info-box-note mt-10'}>
				<span className={'title'}>{t('components:Prilezitosti.Poznámka interná')}</span>
				<span>{get(prilezitost, 'poznamkaInterna', '-')}</span>
			</div>
			<div className={'info-box-title mt-20 mb-10'}>{t('components:Prilezitosti.Produkt')}</div>
			<>
				{get(prilezitost, 'prilezitostProdukty', []).map((produkt) => (
					<div className={'info-box flex flex-col gap-10'}>
						<div className={'info-row'}>
							<div className={'info-column'}>{t('components:Prilezitosti.Komodita typ')}</div>
							<div className={'info-column info-value'}>{get(produkt, 'komoditaTypNazov', '-')}</div>
						</div>
						<div className={'info-row'}>
							<div className={'info-column'}>{t('components:Prilezitosti.Rodina produktov')}</div>
							<div className={'info-column info-value'}>{get(produkt, 'produktovaRodinaNazov', '-')}</div>
						</div>
						<div className={'info-row'}>
							<div className={'info-column'}>{t('components:Prilezitosti.Produkt')}</div>
							<div className={'info-column info-value'}>{get(produkt, 'produktNazov', '-')}</div>
						</div>
					</div>
				))}
			</>
		</>
	)
}

VseobecneTabContent.propTypes = {
	prilezitost: PropTypes.object.isRequired
}

export default VseobecneTabContent
