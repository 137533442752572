import React from 'react'

import PropTypes from 'prop-types'
import { KOMODITA_TYP } from '../../utils/enums'

import { ReactComponent as ElektrinaIcon } from '../../resources/img/icons/elektrina.svg'
import { ReactComponent as PlynIcon } from '../../resources/img/icons/plyn.svg'
import { ReactComponent as NekomoditaIcon } from '../../resources/img/icons/nekomodita.svg'

function KomoditaIcon(props) {
	const { komoditaTyp } = props

	const getIcon = (typ) => {
		switch (typ) {
			case `${KOMODITA_TYP.ELEKTRINA}`:
				return <ElektrinaIcon />
			case `${KOMODITA_TYP.PLYN}`:
				return <PlynIcon />
			case `${KOMODITA_TYP.NEKOMODITA}`:
				return <NekomoditaIcon />
			default:
				null
		}
	}

	return getIcon(komoditaTyp)
}

KomoditaIcon.propTypes = {
	komoditaTyp: PropTypes.oneOf([KOMODITA_TYP.PLYN, KOMODITA_TYP.ELEKTRINA, KOMODITA_TYP.NEKOMODITA])
}

export default KomoditaIcon
