import React from 'react'
import { compose, bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, map, isEmpty, head, find, includes /* , startsWith */ } from 'lodash'
import { NumericFormat } from 'react-number-format'
import { Tooltip } from 'react-tippy'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import onClickOutside from 'react-onclickoutside'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// components
import VyberTypuPlatcuPlanuSplatok from '../Modals/VyberTypuPlatcuPlanuSplatok'
import PlatbaDetail from '../Modals/PlatbaDetail'
import PlatobnyPrikazDetail from '../Modals/PlatobnyPrikazDetail'

// utils
import { formatDate } from '../../utils/date'
import {
	POHLADAVKA_TYP,
	POHLADAVKA_SPLATNOST_STATUS,
	POHLADAVKA_VYROVNANIE_STATUS,
	TYP_PLATCU_PLANU_SPLATOK,
	BUSINESS_CHANNELS,
	POHLADAVKA_ZDROJ_DAT
} from '../../utils/enums'
import { getTypPohladavkyTooltip, getStateIcon, getInfoIcon, getItemState } from '../../utils/pohladavky'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'
import { history } from '../../utils/history'
import {
	setRouteParams,
	UCTOVNE_DOKLADY_DETAIL,
	PLANY_SPLATOK_VYTVORIT,
	PREPLATKY_ZOZNAM,
	BLOKOVANIE_UPOMINANIA_ZOZNAM,
	POHLADAVKY_DETAIL,
	FAKTURY_DETAIL,
	UPOMIENKY_ZOZNAM
} from '../../utils/routes'
import { getIsMop } from '../../utils/obchodnyPartner'

class StavUctuTableRow extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		selectedPohladavka: PropTypes.string,
		pohladavka: PropTypes.shape(),
		withActiveLink: PropTypes.bool,
		obchodnyPartner: PropTypes.shape().isRequired,
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		otvoreneFaktury: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		this.state = {
			visible: false
		}
	}

	toggle = () => {
		this.setState({
			visible: !this.state.visible
		})
	}

	handleClickOutside() {
		this.setState({
			visible: false
		})
	}

	getFakturaCislo = (pohladavka) => {
		return get(pohladavka, 'zdrojDat.id') == POHLADAVKA_ZDROJ_DAT.FLORES ? get(pohladavka, 'cislo') : get(pohladavka, 'variabilnySymbol')
	}

	render() {
		const { interakcia, auth, selectedPohladavka, pohladavka, withActiveLink, obchodnyPartnerActions, obchodnyPartner, otvoreneFaktury, t } = this.props
		const { visible, vyberTypuPlatcuPlanuSplatok, platbaDetail, platobnyPrikaz } = this.state

		const state = getItemState(pohladavka)

		let typPohladavky
		switch (get(pohladavka, 'typ.id')) {
			case POHLADAVKA_TYP.PREPLATOK_Z_FAKTURACIE.value:
			case POHLADAVKA_TYP.AKONTO.value:
			case POHLADAVKA_TYP.ZALOHA_ZNOVUPRIPOJENIE.value:
			case POHLADAVKA_TYP.UDRZBA_UCTU.value:
			case POHLADAVKA_TYP.STANDARD_KVALITY.value:
				typPohladavky = (
					<Tooltip html={getTypPohladavkyTooltip(pohladavka, t)} position='top' trigger='mouseenter' theme='light'>
						{get(pohladavka, 'typ.nazov')}
					</Tooltip>
				)
				break
			default:
				typPohladavky = get(pohladavka, 'typ.nazov')
		}

		const suvisiaceDoklady = map(get(pohladavka, 'suvisiaceDoklady'), (value, index) => {
			const suvisiaciDokladItem = (
				<>
					{/* NOTE: CP-1939 */}
					{/* <div className={cx({ highlightedAlert: startsWith(get(value, 'cislo'), '6') })}> */}
					<div>
						<span className={cx({ highlighted: get(pohladavka, 'highlighted') && get(pohladavka, 'highlightedNumber') == get(value, 'cislo') })}>
							{get(value, 'cislo')}
						</span>
						{get(value, 'suma') != null && get(value, 'suma') != undefined && (
							<NumericFormat
								accounting={true}
								thousandSeparator={' '}
								decimalSeparator={','}
								decimalScale={2}
								fixedDecimalScale={true}
								displayType='text'
								suffix='&nbsp;€'
								value={get(value, 'suma')}
								renderText={(formattedValue) => <span style={{ fontSize: '12px' }}> {`(${formattedValue})`}</span>}
							/>
						)}
					</div>
				</>
			)

			return (
				<div
					key={index}
					data-cislo={get(value, 'cislo')}
					onMouseEnter={() => obchodnyPartnerActions.highlightPohladavky(get(value, 'cislo'))}
					onMouseLeave={() => obchodnyPartnerActions.highlightPohladavky()}
				>
					{suvisiaciDokladItem}
				</div>
			)
		})

		const splatnostDatum = formatDate(get(pohladavka, 'splatnostDatum'), '', 'DD.MM.YYYY')
		let splatnostDatumString = splatnostDatum
		const sumaOtvorena = (
			<NumericFormat
				thousandSeparator={' '}
				decimalSeparator={','}
				decimalScale={2}
				fixedDecimalScale={true}
				displayType='text'
				suffix=' €'
				value={get(pohladavka, 'sumaOtvorena')}
			/>
		)
		let sumaOtvorenaString = sumaOtvorena

		if (
			get(pohladavka, 'sumaOtvorena') != 0 &&
			get(pohladavka, 'typ.id') != POHLADAVKA_TYP.PRIJATA_PLATBA.value &&
			get(pohladavka, 'typ.id') != POHLADAVKA_TYP.ODOSLANA_PLATBA.value
		) {
			if (state == POHLADAVKA_SPLATNOST_STATUS.SPLATNA_PRED) {
				splatnostDatumString = <strong data-text-color='blue'>{splatnostDatum}</strong>
				sumaOtvorenaString = <strong data-text-color='blue'>{sumaOtvorena}</strong>
			} else if (state == POHLADAVKA_SPLATNOST_STATUS.SPLATNA_PO) {
				splatnostDatumString = <strong data-text-color='red'>{splatnostDatum}</strong>
				sumaOtvorenaString = <strong data-text-color='red'>{sumaOtvorena}</strong>
			}
		}

		let dataColor
		switch (get(pohladavka, 'typ.id')) {
			case POHLADAVKA_TYP.ODOSLANA_PLATBA.value:
				dataColor = 'red'
				break
			case POHLADAVKA_TYP.PRIJATA_PLATBA.value:
				dataColor = 'green'
				break
		}

		const zuCisla = map(get(pohladavka, 'zuCisla'), (value, index) => (
			<div key={index}>
				{value}
				{index < get(pohladavka, 'zuCisla.length') && <br />}
			</div>
		))

		const isHighlightedNumber = get(pohladavka, 'highlighted') && get(pohladavka, 'highlightedNumber') == get(pohladavka, 'cislo')
		const number = withActiveLink ? (
			<Link
				className={cx({ highlighted: isHighlightedNumber })}
				to={setRouteParams(POHLADAVKY_DETAIL, get(interakcia, 'opCislo'), get(pohladavka, 'cislo'))}
			>
				{get(pohladavka, 'cislo')}
			</Link>
		) : (
			<span className={cx({ highlighted: isHighlightedNumber })}>{get(pohladavka, 'cislo')}</span>
		)

		const backUrl = btoa(`${window.location.pathname}${window.location.search}`)

		const actionBtns = []

		if (get(pohladavka, 'typ.id') == POHLADAVKA_TYP.FAKTURA_OPRAVNA.value) {
			if (get(pohladavka, 'dokumentId')) {
				actionBtns.push(
					<Permissions
						allowed={[PERMISSIONS.VIEW_POHLADAVKY]}
						render={(hasPerm, actions) => {
							return (
								<li>
									<Link
										to={`${setRouteParams(UCTOVNE_DOKLADY_DETAIL, get(interakcia, 'opCislo'), get(pohladavka, 'id'))}?backUrl=${backUrl}`}
										onClick={(e) => {
											if (!hasPerm) {
												e.preventDefault()
												actions.openForbiddenModal()
											}
										}}
									>
										{t('translation:Common.Zobraziť opravnú faktúru')}
									</Link>
								</li>
							)
						}}
						key='action-btn-dokument'
					/>
				)
				actionBtns.push(
					<Permissions
						allowed={[PERMISSIONS.VIEW_FAKTURY]}
						render={(hasPerm, actions) => {
							return (
								<li>
									<Link
										to={`${setRouteParams(
											FAKTURY_DETAIL,
											get(interakcia, 'opCislo'),
											this.getFakturaCislo(pohladavka)
										)}?backUrl=${backUrl}`}
										onClick={(e) => {
											if (!hasPerm) {
												e.preventDefault()
												actions.openForbiddenModal()
											}
										}}
									>
										{t('translation:StavUctu.Zobraziť pôvodnú faktúru')}
									</Link>
								</li>
							)
						}}
						key='action-btn-faktura'
					/>
				)
			} else {
				actionBtns.push(
					<Permissions
						allowed={[PERMISSIONS.VIEW_FAKTURY]}
						render={(hasPerm, actions) => {
							return (
								<li>
									<Link
										to={`${setRouteParams(
											FAKTURY_DETAIL,
											get(interakcia, 'opCislo'),
											this.getFakturaCislo(pohladavka)
										)}?backUrl=${backUrl}`}
										onClick={(e) => {
											if (!hasPerm) {
												e.preventDefault()
												actions.openForbiddenModal()
											}
										}}
									>
										{t('translation:StavUctu.Zobraziť faktúru')}
									</Link>
								</li>
							)
						}}
						key='action-btn-faktura'
					/>
				)
			}
		}

		if (
			(get(pohladavka, 'zdrojDat.id') == POHLADAVKA_ZDROJ_DAT.SAP_ISU && get(pohladavka, 'typ.id') == POHLADAVKA_TYP.FAKTURA_VYUCTOVACIA.value) ||
			get(pohladavka, 'typ.id') == POHLADAVKA_TYP.FAKTURA_NEKOMODITA.value ||
			get(pohladavka, 'typ.id') == POHLADAVKA_TYP.FAKTURA_ZALOHOVA.value ||
			(get(pohladavka, 'typ.id') == POHLADAVKA_TYP.ZALOHA.value && getIsMop(get(obchodnyPartner, 'data.skupinaOpravneni')))
		) {
			actionBtns.push(
				<Permissions
					allowed={[PERMISSIONS.VIEW_FAKTURY]}
					render={(hasPerm, actions) => {
						return (
							<li>
								<Link
									to={`${setRouteParams(FAKTURY_DETAIL, get(interakcia, 'opCislo'), this.getFakturaCislo(pohladavka))}?backUrl=${backUrl}`}
									onClick={(e) => {
										if (!hasPerm) {
											e.preventDefault()
											actions.openForbiddenModal()
										}
									}}
								>
									{t('translation:StavUctu.Zobraziť faktúru')}
								</Link>
							</li>
						)
					}}
					key='action-btn-faktura'
				/>
			)
		}

		if (get(pohladavka, 'typ.id') != POHLADAVKA_TYP.FAKTURA_OPRAVNA.value && get(pohladavka, 'dokumentId')) {
			actionBtns.push(
				<Permissions
					allowed={[PERMISSIONS.VIEW_POHLADAVKY]}
					render={(hasPerm, actions) => {
						return (
							<li>
								<Link
									to={`${setRouteParams(UCTOVNE_DOKLADY_DETAIL, get(interakcia, 'opCislo'), get(pohladavka, 'id'))}?backUrl=${backUrl}`}
									onClick={(e) => {
										if (!hasPerm) {
											e.preventDefault()
											actions.openForbiddenModal()
										}
									}}
								>
									{t('translation:Common.Zobraziť dokument')}
								</Link>
							</li>
						)
					}}
					key='action-btn-dokument'
				/>
			)
		}

		if (!isEmpty(get(pohladavka, 'upomienky'))) {
			actionBtns.push(
				<Permissions
					allowed={[PERMISSIONS.VIEW_UPOMIENKY]}
					render={(hasPerm, actions) => {
						return (
							<li>
								<Link
									to={`${setRouteParams(UPOMIENKY_ZOZNAM, get(interakcia, 'opCislo'))}?pohladavkaCislo=${get(
										pohladavka,
										'cislo'
									)}&backUrl=${backUrl}`}
									onClick={(e) => {
										if (!hasPerm) {
											e.preventDefault()
											actions.openForbiddenModal()
										}
									}}
								>
									{t('translation:StavUctu.Zobraziť upomienky')}
								</Link>
							</li>
						)
					}}
					key='action-btn-upomienka'
				/>
			)
		}

		if (
			get(pohladavka, 'variabilnySymbol') &&
			find(get(otvoreneFaktury, 'data', []), (faktura) => {
				return get(faktura, 'cislo') == get(pohladavka, 'variabilnySymbol') && get(pohladavka, 'sumaOtvorena') > 0 && get(faktura, 'sumaBlokovana') <= 0
			})
		) {
			actionBtns.push(
				<Permissions
					allowed={[PERMISSIONS.UKON_PLAN_SPLATOK]}
					render={(hasPerm, actions) => {
						return (
							<li>
								<a
									onClick={(e) => {
										e.preventDefault()
										if (!hasPerm) {
											actions.openForbiddenModal()
										} else if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
											history.push(
												`${setRouteParams(PLANY_SPLATOK_VYTVORIT, get(interakcia, 'opCislo'))}?platcaTyp=${
													TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA
												}&zuCislo=${head(get(pohladavka, 'zuCisla', []))}&fakturaCislo=${get(
													pohladavka,
													'variabilnySymbol'
												)}&backUrl=${backUrl}`
											)
										} else {
											this.setState({
												vyberTypuPlatcuPlanuSplatok: {
													showModal: true
												}
											})
										}
									}}
								>
									{t('translation:PlanySplatok.Vytvoriť plán splátok')}
								</a>
							</li>
						)
					}}
					key='action-btn-splatky'
				/>
			)
		}

		if (
			!isEmpty(get(pohladavka, 'zuCisla', [])) &&
			get(pohladavka, 'zdrojDat.id') == POHLADAVKA_ZDROJ_DAT.SAP_ISU &&
			get(pohladavka, 'typ.id') != POHLADAVKA_TYP.PRIJATA_PLATBA.value &&
			get(pohladavka, 'typ.id') != POHLADAVKA_TYP.ODOSLANA_PLATBA.value &&
			get(pohladavka, 'vyrovnanieStatus.id') == POHLADAVKA_VYROVNANIE_STATUS.OTVORENA &&
			get(pohladavka, 'sumaOtvorena') < 0
		) {
			actionBtns.push(
				<Permissions
					allowed={[PERMISSIONS.VIEW_POHLADAVKY]}
					render={(hasPerm, actions) => {
						return (
							<li>
								<Link
									to={`${setRouteParams(PREPLATKY_ZOZNAM, get(interakcia, 'opCislo'))}?zmluvnyUcetCislo=${head(
										get(pohladavka, 'zuCisla', [])
									)}&variabilnySymbol=${get(pohladavka, 'variabilnySymbol', '')}&pohladavkaID=${get(pohladavka, 'cislo', '')}_${get(
										pohladavka,
										'typ.id',
										''
									)}&backUrl=${backUrl}`}
									onClick={(e) => {
										if (!hasPerm) {
											e.preventDefault()
											actions.openForbiddenModal()
										}
									}}
								>
									{t('translation:Preplatky.Vrátenie preplatkov')}
								</Link>
							</li>
						)
					}}
					key='action-btn-preplatky'
				/>
			)
		}

		if (
			!isEmpty(get(pohladavka, 'zuCisla', [])) &&
			get(pohladavka, 'zdrojDat.id') == POHLADAVKA_ZDROJ_DAT.SAP_ISU &&
			get(pohladavka, 'typ.id') != POHLADAVKA_TYP.PRIJATA_PLATBA.value &&
			get(pohladavka, 'typ.id') != POHLADAVKA_TYP.ODOSLANA_PLATBA.value &&
			get(pohladavka, 'suma') > 0
		) {
			actionBtns.push(
				<Permissions
					allowed={[PERMISSIONS.VIEW_POHLADAVKY]}
					render={(hasPerm, actions) => {
						return (
							<li>
								<Link
									to={`${setRouteParams(BLOKOVANIE_UPOMINANIA_ZOZNAM, get(interakcia, 'opCislo'))}?zmluvnyUcetCislo=${head(
										get(pohladavka, 'zuCisla', [])
									)}&variabilnySymbol=${get(pohladavka, 'variabilnySymbol', '')}&pohladavkaID=${get(pohladavka, 'cislo', '')}_${get(
										pohladavka,
										'typ.id',
										''
									)}&backUrl=${backUrl}`}
									onClick={(e) => {
										if (!hasPerm) {
											e.preventDefault()
											actions.openForbiddenModal()
										}
									}}
								>
									{t('translation:BlokovanieUpominania.Blokovania')}
								</Link>
							</li>
						)
					}}
					key='action-btn-blokovania'
				/>
			)
		}

		if (get(pohladavka, 'typ.id') == POHLADAVKA_TYP.PRIJATA_PLATBA.value || get(pohladavka, 'typ.id') == POHLADAVKA_TYP.ODOSLANA_PLATBA.value) {
			actionBtns.push(
				<Permissions
					allowed={[PERMISSIONS.VIEW_POHLADAVKY]}
					render={(hasPerm, actions) => {
						return (
							<li>
								<a
									onClick={(e) => {
										if (!hasPerm) {
											e.preventDefault()
											actions.openForbiddenModal()
										}
										this.setState({
											platbaDetail: {
												showModal: true
											}
										})
									}}
								>
									{t('translation:Platba.Detail platby')}
								</a>
							</li>
						)
					}}
					key='action-btn-platba-detail'
				/>
			)
		}

		if (
			get(pohladavka, 'platobnyPrikaz') &&
			get(pohladavka, 'zdrojDat.id') == POHLADAVKA_ZDROJ_DAT.SAP_ISU &&
			get(pohladavka, 'typ.id') != POHLADAVKA_TYP.PRIJATA_PLATBA.value &&
			get(pohladavka, 'typ.id') != POHLADAVKA_TYP.ODOSLANA_PLATBA.value
		) {
			actionBtns.push(
				<Permissions
					allowed={[PERMISSIONS.VIEW_POHLADAVKY]}
					render={(hasPerm, actions) => {
						return (
							<li>
								<a
									onClick={(e) => {
										if (!hasPerm) {
											e.preventDefault()
											actions.openForbiddenModal()
										}
										this.setState({
											platobnyPrikaz: {
												showModal: true
											}
										})
									}}
								>
									{t('translation:PlatobnePrikazy.Detail platobného príkazu')}
								</a>
							</li>
						)
					}}
					key='action-btn-platobny-prikaz-detail'
				/>
			)
		}

		let modal
		if (get(vyberTypuPlatcuPlanuSplatok, 'showModal')) {
			modal = (
				<VyberTypuPlatcuPlanuSplatok
					modalTitle={t('translation:PlanySplatok.Zvoľte typ platcu pre vytvorenie plánu splátok')}
					onCloseButton={() =>
						this.setState({
							vyberTypuPlatcuPlanuSplatok: {
								showModal: false
							}
						})
					}
					onSubmit={(context) => {
						let query = `platcaTyp=${get(context, 'selectedPlatcaTyp')}&zuCislo=${head(get(pohladavka, 'zuCisla', []))}&fakturaCislo=${get(
							pohladavka,
							'variabilnySymbol'
						)}&backUrl=${backUrl}`
						if (get(context, 'selectedPlatcaTyp') == TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC) {
							query += `&splnomocnenec=${get(context, 'selectedSplnomocnenec')}`
						}
						history.push(`${setRouteParams(PLANY_SPLATOK_VYTVORIT, get(interakcia, 'opCislo'))}?${query}`)
					}}
				/>
			)
		}
		if (get(platbaDetail, 'showModal')) {
			modal = (
				<PlatbaDetail
					modalTitle={t('translation:Platby.Platba {platbaCislo}', {
						platbaCislo: get(pohladavka, 'cislo')
					})}
					onCloseButton={() =>
						this.setState({
							platbaDetail: {
								showModal: false
							}
						})
					}
					platbaCislo={get(pohladavka, 'zdrojDat.id') == POHLADAVKA_ZDROJ_DAT.FLORES ? get(pohladavka, 'id') : get(pohladavka, 'cislo')}
				/>
			)
		}
		if (get(platobnyPrikaz, 'showModal')) {
			modal = (
				<PlatobnyPrikazDetail
					modalTitle={t('translation:PlatobnePrikazy.Platobný príkaz pre pohľadávku {pohladavkaId}', {
						pohladavkaId: get(pohladavka, 'id')
					})}
					onCloseButton={() =>
						this.setState({
							platobnyPrikaz: {
								showModal: false
							}
						})
					}
					pohladavkaId={get(pohladavka, 'id')}
				/>
			)
		}

		return (
			<tr
				id={`${get(pohladavka, 'cislo')}_${get(pohladavka, 'typ.id', '')}`}
				data-pohladavka-typ={get(pohladavka, 'typ.id', '')}
				data-color={dataColor}
				className={cx(
					{
						selected: includes(selectedPohladavka, get(pohladavka, 'cislo')),
						highlighted: get(pohladavka, 'highlighted')
					},
					get(pohladavka, 'cislo'),
					`${get(pohladavka, 'cislo')}_${get(pohladavka, 'typ.id', '')}`
				)}
			>
				<td>
					<span>{typPohladavky}</span>
				</td>
				<td
					data-cislo={get(pohladavka, 'cislo')}
					onMouseEnter={() => obchodnyPartnerActions.highlightPohladavky(get(pohladavka, 'cislo'))}
					onMouseLeave={() => obchodnyPartnerActions.highlightPohladavky()}
				>
					{number}
				</td>
				<td>
					<span>{get(pohladavka, 'variabilnySymbol', '-')}</span>
				</td>
				<td>{zuCisla}</td>
				<td>
					<span>{formatDate(get(pohladavka, 'dokladDatum'), '', 'DD.MM.YYYY')}</span>
				</td>
				<td>
					<span>{splatnostDatumString}</span>
				</td>
				<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
					<NumericFormat
						thousandSeparator={' '}
						decimalSeparator={','}
						decimalScale={2}
						fixedDecimalScale={true}
						displayType='text'
						suffix=' €'
						value={get(pohladavka, 'suma')}
					/>
				</td>
				<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{sumaOtvorenaString}</td>
				<td>{suvisiaceDoklady}</td>
				<td style={{ textAlign: 'center' }}>{getInfoIcon(pohladavka, t)}</td>
				<td style={{ textAlign: 'center' }}>{getStateIcon(state, t)}</td>
				<td style={{ position: 'relative' }}>
					{!isEmpty(actionBtns) && (
						<>
							<div className='box-header-settings' onClick={this.toggle} />
							<div className='box-header-dropdown'>
								<div className={cx('dropdown', { shown: visible })}>
									<ul>{actionBtns}</ul>
								</div>
							</div>
						</>
					)}
				</td>
				{modal}
			</tr>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail')
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(onClickOutside(StavUctuTableRow))
