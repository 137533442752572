import PropTypes from 'prop-types'
import { useState } from 'react'
import cx from 'classnames'

function Tabs(props) {
	const { onChange, items, defaultActiveKey } = props
	const [activeKey, setActiveKey] = useState(defaultActiveKey || items?.[0]?.key)

	return (
		<div>
			<ul className={'tabs-list'}>
				{items.map((item) => (
					<li key={item.key}>
						<a
							className={cx('tab', { active: activeKey === item.key })}
							onClick={() => {
								if (onChange) {
									onChange(item.key)
								}
								setActiveKey(item.key)
							}}
						>
							{item.label}
						</a>
					</li>
				))}
			</ul>
			<div className={cx('tab-content', { 'with-radius': items.findIndex((item) => item.key === activeKey) !== 0 })}>
				{items.find((item) => item.key === activeKey).children}
			</div>
		</div>
	)
}

Tabs.propTypes = {
	onChange: PropTypes.func.isRequired,
	items: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string, children: PropTypes.element })).isRequired,
	defaultActiveKey: PropTypes.string
}

export default Tabs
