import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { get, upperFirst } from 'lodash'

// atoms
import OptionsPickerField from '../../../atoms/OptionsPickerField'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class VstupDoUkonuField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		ciselniky: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired,
		vstupy: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				nazov: PropTypes.string.isRequired
			})
		)
	}

	constructor(props) {
		super(props)
	}

	getOptions(optionsData) {
		return optionsData?.map((option) => {
			return {
				id: option?.id,
				originalValue: option,
				label: option?.nazov
			}
		})
	}

	parseValue(value) {
		return value.originalValue
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Vstup do úkonu je povinný')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, t, value, vstupy } = this.props

		if (editMode === EDIT_MODE.DETAIL) return null

		const options = this.getOptions(vstupy)

		const valueBefore = null
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) {
			valueAfter = (
				<Field
					name={field}
					selectedOptionId={value?.id}
					component={OptionsPickerField}
					options={options}
					parse={this.parseValue}
					validate={this.validate}
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM) {
			valueAfter = upperFirst(get(value, 'nazov'))
		}

		return (
			<table className='content-table padded bordered' cellSpacing='0'>
				<tbody>
					<tr>
						<td>
							<strong>{t('translation:Common.Vstup do úkonu')}</strong>
						</td>
						{columnsCount === COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
						<td>{valueAfter}</td>
					</tr>
				</tbody>
			</table>
		)
	}
}

export default compose(withTranslation('components'))(VstupDoUkonuField)
