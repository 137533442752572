import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// actions
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash'
import * as PrilezitostiAction from '../../actions/PrilezitostiActions'
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'
import ElementEmptyContent from '../ElementEmptyContent'
import { PRILEZITOSTI_STAV } from '../../utils/enums'
import Tag from '../Prilezitosti/Tag'
import { formatDate } from '../../utils/date'
import KomoditaIcon from '../Prilezitosti/KomoditaIcon'
import { history } from '../../utils/history'
import { PRILEZITOSTI_DETAIL, setRouteParams } from '../../utils/routes'

function PrilezitostiWidget() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const interakcia = useSelector((state) => state.interakcie.detail)
	const prilezitosti = useSelector((state) => state.prilezitosti.opPrilezitosti)

	const opCislo = interakcia.data?.opCislo

	useEffect(() => {
		if (opCislo) {
			dispatch(
				PrilezitostiAction.loadOpPrilezitosti(
					{
						page: prilezitosti.page,
						perPage: prilezitosti.pageSize,
						stav: PRILEZITOSTI_STAV.NOVA
					},
					opCislo,
					interakcia?.data?.riesitelIdentita?.oddelenie?.kod
				)
			)
		}
	}, [opCislo, interakcia?.data?.riesitelIdentita?.oddelenie?.kod])

	const commonContentContainer = (content) => {
		return <div className='box-content'>{content}</div>
	}

	if (prilezitosti.isLoading) {
		return commonContentContainer(<ElementLoading />)
	}

	if (prilezitosti.isFailure) {
		return commonContentContainer(<ElementFailure text={t('Prilezitosti.Widget.Príležitosťi sa nepodarilo načitať')} />)
	}

	if (isEmpty(prilezitosti.data)) {
		return commonContentContainer(<ElementEmptyContent text={t('Prilezitosti.Widget.Pre obchodného partnera neevidujeme žiadne aktívne príležitosťi')} />)
	}

	return commonContentContainer(
		<div className={'lead-widget-item-list'}>
			{prilezitosti.data.map((prilezitost) => (
				<div
					className={'lead-widget-item pointer'}
					onClick={() => history.replace(setRouteParams(PRILEZITOSTI_DETAIL, opCislo, get(prilezitost, 'id')))}
				>
					<div className={'lead-widget-item-flex-box'}>
						<div className={'left'}>
							<Tag title={prilezitost.stav.nazov} classes={{ active: prilezitost.stav.id === PRILEZITOSTI_STAV.NOVA }} />
							<p className={'secondary'}>{formatDate(get(prilezitost, 'vytvorenaOd', null))}</p>
						</div>
						<div className={'right'}>
							<p>{get(prilezitost, 'zdroj.nazov', '---')}</p>
							<p className={'secondary'}>{`ID ${get(prilezitost, 'id', '---')}`}</p>
						</div>
					</div>
					<div className={'lead-widget-item-flex-box'}>
						<div className={'products-list-box'}>
							{get(prilezitost, 'prilezitostProdukty', []).map((prilezitostProdukt) => {
								return (
									<div className={'product-box'}>
										<div>
											<p>{get(prilezitostProdukt, 'produktNazov', '---')}</p>
										</div>
										<div>
											<p className={'secondary'}>{get(prilezitostProdukt, 'produktovaRodinaNazov', '---')}</p>
										</div>
										<div className={'commodity-box'}>
											<KomoditaIcon komoditaTyp={get(prilezitostProdukt, 'komoditaTyp', null)} />
											{get(prilezitostProdukt, 'komoditaTypNazov', null)}
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			))}
		</div>
	)
}

export default PrilezitostiWidget
