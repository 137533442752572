import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../../../atoms/Button'

function FilterButton(props) {
	const { onFilterBtnClick, numberOfActiveFilters } = props
	const { t } = useTranslation()

	return (
		<Button onClick={() => onFilterBtnClick(true)}>
			{t('components:Prilezitosti.Filter')}
			<div className={cx('filter-btn-counter', { hidden: numberOfActiveFilters === 0 })}>{numberOfActiveFilters}</div>
		</Button>
	)
}

FilterButton.propTypes = {
	onFilterBtnClick: PropTypes.func.isRequired,
	numberOfActiveFilters: PropTypes.number.isRequired
}

export default FilterButton
