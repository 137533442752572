/* eslint-disable no-console */
import axios from 'axios'
import { get, split } from 'lodash'
import config from './config'
import { getAccessToken, isLoggedIn } from './auth'

import { CANCEL_REQUEST } from './enums'

// TODO: CARE-XXX Change this config
const host = config.baseUrl

export { host }

function buildHeaders() {
	const headers = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Access-Control-Allow-Credentials': true,
		'Cache-Control': 'no-cache, no-store',
		Pragma: 'no-cache'
	}

	if (isLoggedIn()) {
		headers.Authorization = `Bearer ${getAccessToken()}`
	}
	return headers
}

/**
 * @return fullfilURL and queryParams  response
 * @param {string} urlTemplate url
 * @param params
 */
const fullFillURL = (urlTemplate, params) => {
	const pathParams = []
	const queryParams = { ...(params || {}) }
	const fullfilURL = split(urlTemplate, '/')
		.map((blok) => {
			if (/{[^}]*\}/.test(blok)) {
				const param = blok.replace('{', '').replace('}', '')
				pathParams.push(param)
				delete queryParams[param]
				return get(params, param)
			}
			return blok
		})
		.join('/')
	return {
		fullfilURL,
		queryParams
	}
}

export const cancelGetTokens = {}

/**
 * @return Promise response
 * Performs get request to url and returns callback with result
 * @param {string} url api endoint
 * @param params
 * @param {function} callback
 * @param {string} accept header
 * @param options
 * @param {boolean} allowCancelToken allow cancel token
 */
export function getReq(url, params, callback, accept, options, allowCancelToken = false) {
	const { fullfilURL } = fullFillURL(url, params)

	let token = {}
	if (allowCancelToken) {
		const cancelTokenStorageKey = fullfilURL
		if (typeof cancelGetTokens[cancelTokenStorageKey] !== typeof undefined) {
			cancelGetTokens[cancelTokenStorageKey].cancel(CANCEL_REQUEST)
		}
		// Save the cancel token for the current request
		cancelGetTokens[cancelTokenStorageKey] = axios.CancelToken.source()
		token = {
			cancelToken: cancelGetTokens[cancelTokenStorageKey].token
		}
	}

	const config = {
		headers: buildHeaders(),
		...token
	}

	delete config['Content-Type']

	if (accept) {
		config.headers.Accept = accept
	}

	if (params) {
		config.params = params
	}

	return axios
		.get(host + url, { ...config, ...options })
		.then((res) => {
			if (!res) {
				return res
			}
			callback && callback(null, res.data)
			return res.data
		})
		.catch((err) => {
			callback && callback(err, null)
			throw err
		})
}

/**
 * @return Promise response
 * Performs post request to url and returns callback with result
 * @param {string} url api endoint
 * @param params
 * @param {Object} data
 * @param {function} callback
 */
export function postReq(url, params, data, callback) {
	const config = {
		headers: buildHeaders()
	}
	if (params) {
		config.params = params
	}

	return axios
		.post(host + url, data || {}, config)
		.then((res) => {
			if (!res) {
				return res
			}
			callback && callback(null, res.data)
			return res.data
		})
		.catch((err) => {
			callback && callback(err, null)
			throw err
		})
}

/**
 *
 * Performs put request to url and returns callback with result
 * @param {string} url api endoint
 * @param params
 * @param {Object} data
 * @param {function} callback
 */
export function putReq(url, params, data, callback) {
	const config = {
		headers: buildHeaders()
	}

	if (params) {
		config.params = params
	}

	return axios
		.put(host + url, data || {}, config)
		.then((res) => {
			if (!res) {
				return res
			}
			callback && callback(null, res.data)
			return res.data
		})
		.catch((err) => {
			callback && callback(err, null)
			throw err
		})
}

/**
 *
 * Performs delete request to url and returns with result
 * @param {string} url api endoint
 * @param params
 */
export const deleteReq = (url, params) => {
	const config = {
		headers: buildHeaders()
	}

	if (params) {
		config.params = params
	}

	return axios
		.delete(host + url, config)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			throw err
		})
}
