import i18next from 'i18next'

import { PRILEZITOSTI_STAV } from '../../../utils/enums'

const validate = (values) => {
	const errors = {}

	if (values?.stav?.value === PRILEZITOSTI_STAV.UZAVRETA && !values?.suvisiaceZmluvy) {
		errors.suvisiaceZmluvy = i18next.t('translation:Prilezitosti.Pri zmene stavu na „Uzavretá“ je potrebné doplniť súvisiace zmluvy')
	}

	return errors
}

export default validate
