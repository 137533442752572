import cx from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { postReq } from '../../utils/request'

function SchvaleniePRIZOPButton(props) {
	const { ukonId, isEnabled, onClick } = props
	const { t } = useTranslation()
	const [isLoading, setIsLoading] = useState(false)

	const handleClick = async () => {
		try {
			setIsLoading(true)
			await postReq(`/api/v2/ukony/${ukonId}/pokracovat`, null, {})
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		} finally {
			setIsLoading(false)
			onClick()
		}
	}

	if (!isEnabled) {
		return
	}

	return (
		<button
			className={cx('button', 'pull-right', { disabled: isLoading })}
			data-color='blue'
			style={{ marginLeft: '20px' }}
			onClick={() => {
				if (!isLoading) {
					handleClick()
				}
			}}
		>
			{t('translation:Common.Schválenie PRI/ZOP')}
		</button>
	)
}

SchvaleniePRIZOPButton.propTypes = {
	ukonId: PropTypes.string.isRequired,
	isEnabled: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired
}

export default SchvaleniePRIZOPButton
