import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Button from '../../../atoms/Button'

import PageSizes from './PageSizes'

function Footer(props) {
	const { numberOfItems, totalItems, selectedPageSize, onSelectPageSize, loadMore, isLastPage } = props
	const { t } = useTranslation()

	return (
		<div className={'flex-box-center lead-table-footer'}>
			<div>
				{t('components:Prilezitosti.Počet zobrazených záznamov')}
				<br />
				<strong>{`${numberOfItems}/${totalItems}`}</strong>
			</div>
			<Button type={'line'} label={t('components:Prilezitosti.Zobraziť ďalšie')} onClick={loadMore} disabled={isLastPage} />
			<PageSizes onSelectPageSize={onSelectPageSize} selectedPageSize={selectedPageSize} />
		</div>
	)
}

Footer.propTypes = {
	numberOfItems: PropTypes.number.isRequired,
	totalItems: PropTypes.number.isRequired,
	selectedPageSize: PropTypes.number.isRequired,
	onSelectPageSize: PropTypes.func.isRequired,
	loadMore: PropTypes.func.isRequired,
	isLastPage: PropTypes.bool.isRequired
}

export default Footer
