import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import { compose } from 'redux'

// atoms
import Button from '../../atoms/Button'

// components
import Tag from '../../components/Prilezitosti/Tag'
import KomoditaIcon from '../../components/Prilezitosti/KomoditaIcon'
import FilterTags from '../../components/Prilezitosti/Filters/FilterTags'
import FilterModal from '../../components/Prilezitosti/Filters/FilterModal'
import FilterButton from '../../components/Prilezitosti/Filters/FilterButton'
import ElementLoading from '../../components/ElementLoading'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// actions
import * as PrilezitostiAction from '../../actions/PrilezitostiActions'

// utils
import { history } from '../../utils/history'
import { formatDate } from '../../utils/date'
import { getAccessToken } from '../../utils/auth'
import { PRILEZITOSTI_STAV } from '../../utils/enums'
import {
	canEditLead,
	canSeizeLead,
	countActiveFilters,
	getFilterTagOptions,
	praseFiltersToQueryParams,
	removeFilterFromFilters
} from '../../utils/prilezitosti'
import { PRILEZITOSTI_DETAIL, PRILEZITOSTI_UPRAVIT, setRouteParams } from '../../utils/routes'
import Permissions, { PERMISSIONS, withPermissions } from '../../utils/permissionsHoc'

// icons
import { ReactComponent as SearchIcon } from '../../resources/img/icons/new-search.svg'
import { ReactComponent as EditIcon } from '../../resources/img/icons/new-pencil.svg'
import { putReq } from '../../utils/request'

function PrilezitostiPage(props) {
	const { computedMatch } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const leadFilterValues = useSelector((state) => state?.ciselniky?.data?.prilezitosti)
	const opFilters = useSelector((state) => state?.prilezitosti?.opFilters)
	const prilezitosti = useSelector((state) => state?.prilezitosti?.opPrilezitosti)
	const auth = useSelector((state) => state?.auth)
	const interakcia = useSelector((state) => state?.interakcie?.detail?.data)

	const [numberOfActiveFilters, setNumberOfActiveFilters] = useState(0)
	const [isOpenFilterModal, setIsOpenFilterModal] = useState(false)
	const [filterTags, setFilterTags] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const opCislo = computedMatch?.params?.cisloOP

	useEffect(() => {
		dispatch(
			PrilezitostiAction.loadOpPrilezitosti(
				{
					...praseFiltersToQueryParams(opFilters),
					page: prilezitosti.page,
					perPage: prilezitosti.pageSize
				},
				opCislo,
				interakcia?.riesitelIdentita?.oddelenie?.kod
			)
		)
	}, [opFilters, opCislo, interakcia?.riesitelIdentita?.oddelenie?.kod])

	useEffect(() => {
		setFilterTags(getFilterTagOptions(opFilters))
		setNumberOfActiveFilters(countActiveFilters(opFilters))
	}, [opFilters])

	const removeFilter = (removedFilter) => {
		const updatedFilters = removeFilterFromFilters(opFilters, removedFilter)
		dispatch(PrilezitostiAction.setOpFilters(updatedFilters))
	}

	const assign = async (prilezitost) => {
		try {
			setIsLoading(true)
			const updateBody = { ...prilezitost.data, riesitel: auth.user.email }
			await putReq(`/api/v0/prilezitosti/${prilezitost?.id}`, null, updateBody)
			dispatch(
				PrilezitostiAction.loadOpPrilezitosti(
					{
						...praseFiltersToQueryParams(opFilters),
						page: prilezitosti.page,
						perPage: prilezitosti.pageSize
					},
					opCislo,
					interakcia?.riesitelIdentita?.oddelenie?.kod
				)
			)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		} finally {
			setIsLoading(false)
		}
	}

	if (prilezitosti.isLoading || isLoading) {
		return <ElementLoading />
	}

	return (
		<>
			<div className='content-header clearfix'>
				<div className='row'>
					<div className='col-9'>
						<div className={'lead-left-box'}>
							<FilterTags tags={filterTags} removeFilter={removeFilter} />
						</div>
					</div>
					<div className='col-3 flex items-center justify-end'>
						<div className={'lead-right-actions-box'}>
							<FilterButton onFilterBtnClick={() => setIsOpenFilterModal(true)} numberOfActiveFilters={numberOfActiveFilters} />
							<Button
								type={'secondary'}
								label={t('translation:Prilezitosti.Vytvoriť lead')}
								onClick={() => window.open(`/api/v0/nela/new-lead/${opCislo}?accessToken=${getAccessToken()}`)}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={'lead-list'}>
				{isEmpty(prilezitosti.data) && (
					<ElementEmptyContent
						text={t(
							'translation:Prilezitosti.Neevidujeme žiadne príležitosti pre obchodného partnera alebo žiadna príležitosť nezodpovedá zadanému filtru'
						)}
					/>
				)}
				{prilezitosti.data?.map((prilezitost) => (
					<div className={'lead-list-item'}>
						<div className={'item-flex-box'}>
							<div className={'left'}>
								<Tag title={prilezitost.stav.nazov} classes={{ active: prilezitost.stav.id === PRILEZITOSTI_STAV.NOVA }} />
								<p className={'secondary'}>{formatDate(get(prilezitost, 'vytvorenaOd', null))}</p>
							</div>
							<div className={'right'}>
								<p>{get(prilezitost, 'zdroj.nazov', '---')}</p>
								<p className={'secondary'}>{`ID ${get(prilezitost, 'id', '---')}`}</p>
							</div>
						</div>
						<div className={'item-flex-box'}>
							<div className={'left'}>
								<div className={'products-list-box'}>
									{get(prilezitost, 'prilezitostProdukty', []).map((prilezitostProdukt) => {
										return (
											<div className={'product-item-box'}>
												<div>
													<p>{get(prilezitostProdukt, 'produktNazov', '---')}</p>
												</div>
												<div>
													<p className={'secondary'}>{get(prilezitostProdukt, 'produktovaRodinaNazov', '---')}</p>
												</div>
												<div className={'commodity-box'}>
													<KomoditaIcon komoditaTyp={get(prilezitostProdukt, 'komoditaTyp', null)} />
													{get(prilezitostProdukt, 'komoditaTypNazov', null)}
												</div>
											</div>
										)
									})}
								</div>
							</div>
							<div className={'right'}>{get(prilezitost, 'riesitel', '---')}</div>
						</div>
						<div className={'item-flex-box'}>
							<div className={'left'}>
								<p>{get(prilezitost, 'kampan', '---')}</p>
								<p className={'secondary'}>{get(prilezitost, 'priradenyKanal.nazov', '---')}</p>
							</div>
							<div className={'flex items-center gap-10'}>
								<Permissions
									allowed={[PERMISSIONS.EDIT_LEADS_OWN, PERMISSIONS.EDIT_LEADS_BASE, PERMISSIONS.EDIT_LEADS_EXTENDED]}
									render={(hasPerm, actions) => (
										<Button
											classes={'lead-list-item-btn'}
											type={'line'}
											label={t('translation:Prilezitosti.Prevziať príležitosť')}
											onClick={() => {
												if (hasPerm && canSeizeLead(interakcia?.riesitelIdentita?.oddelenie?.kod, prilezitost, auth)) {
													assign(prilezitost)
												} else {
													actions.openForbiddenModal()
												}
											}}
										/>
									)}
								/>
								<Permissions
									allowed={[PERMISSIONS.VIEW_LEADS]}
									render={(hasPerm, actions) => (
										<Tooltip html={t('translation:ZmluvneUcty.Detail príležitosti')} position='bottom' trigger='mouseenter' theme='light'>
											<Button
												classes={'small-action-icon-btn'}
												type={'secondary'}
												size={'small'}
												prefixIcon={<SearchIcon width={24} height={24} />}
												onClick={() => {
													if (hasPerm) {
														history.replace(setRouteParams(PRILEZITOSTI_DETAIL, opCislo, get(prilezitost, 'id')))
													} else {
														actions.openForbiddenModal()
													}
												}}
											/>
										</Tooltip>
									)}
								/>
								<Permissions
									allowed={[PERMISSIONS.EDIT_LEADS_OWN, PERMISSIONS.EDIT_LEADS_BASE, PERMISSIONS.EDIT_LEADS_EXTENDED]}
									render={(hasPerm, actions) => (
										<Tooltip html={t('translation:ZmluvneUcty.Upraviť príležitosť')} position='bottom' trigger='mouseenter' theme='light'>
											<Button
												classes={'small-action-icon-btn'}
												type={'secondary'}
												size={'small'}
												prefixIcon={<EditIcon width={24} height={24} />}
												onClick={() => {
													if (hasPerm && canEditLead(interakcia, auth, prilezitost)) {
														history.replace(setRouteParams(PRILEZITOSTI_UPRAVIT, opCislo, get(prilezitost, 'id')))
													} else {
														actions.openForbiddenModal()
													}
												}}
											/>
										</Tooltip>
									)}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
			<FilterModal
				isOpen={isOpenFilterModal}
				onCloseButton={() => setIsOpenFilterModal(false)}
				onSubmit={(columns) => {
					dispatch(PrilezitostiAction.setOpFilters(columns))
					setIsOpenFilterModal(false)
				}}
				resetFilters={() => {
					dispatch(PrilezitostiAction.resetOpFilters())
					setIsOpenFilterModal(false)
				}}
				leadFilterValues={leadFilterValues}
				initValues={opFilters}
			/>
		</>
	)
}

PrilezitostiPage.protoType = {
	computedMatch: PropTypes.shape({
		params: PropTypes.shape({
			cisloOP: PropTypes.any.isRequired
		}).isRequired
	}).isRequired
}

export default compose(withPermissions([PERMISSIONS.VIEW_LEADS]))(PrilezitostiPage)
