import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { upperFirst } from 'lodash'

// atoms
import OptionsPickerField from '../../../atoms/OptionsPickerField'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class DovodUkonceniaField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		dovody: PropTypes.arrayOf(PropTypes.string),
		povinny: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape()
	}

	constructor(props) {
		super(props)
	}

	getOptions(options) {
		return (
			options?.map((option) => {
				return {
					id: option?.id,
					originalValue: option,
					label: upperFirst(option?.nazov)
				}
			}) || []
		)
	}

	parseValue(value) {
		return value.originalValue
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { povinny, t } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Dôvod ukončenia zmluvy je povinný')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, value, t, moznosti } = this.props

		const dovodyOptions = this.getOptions(moznosti)

		const valueBefore = null
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			valueAfter = (
				<Field
					name={field}
					selectedOptionId={value?.id}
					component={OptionsPickerField}
					options={dovodyOptions}
					parse={this.parseValue}
					validate={this.validate}
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = upperFirst(value?.nazov)
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Dôvod ukončenia zmluvy')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>{valueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(DovodUkonceniaField)
