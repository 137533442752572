import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { get, isArray, isEmpty, replace, upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'

import cx from 'classnames'
import Button from '../../../atoms/Button'

const BUTTON_KEYS = {
	AKTUALNE: 'AKTUALNE',
	CIELOVE: 'CIELOVE'
}

function ProduktTabContent(props) {
	const { prilezitost } = props
	const { t } = useTranslation()
	const [parsedDataNastaveniaKeyValue, setParsedDataNastaveniaKeyValue] = useState(null)
	const [parsedDataNastavenia, setParsedDataNastavenia] = useState(null)

	const [selectedButton, setSelectedButton] = useState(BUTTON_KEYS.AKTUALNE)
	const [selectedSettings, setSelectedSetting] = useState(null)
	const [orderedNastaveniaAktualnyDodavatel, setOrderedNastaveniaAktualnyDodavatel] = useState(null)
	const [orderedNastaveniaCielove, setOrderedNastaveniaCielove] = useState(null)

	useEffect(() => {
		if (!isEmpty(get(prilezitost, 'nastaveniaProduktuKeyValue'))) {
			setParsedDataNastaveniaKeyValue(parseSettings(get(prilezitost, 'nastaveniaProduktuKeyValue')))
		} else if (!isEmpty(get(prilezitost, 'nastaveniaProduktu'))) {
			const parsedData = parseSettings(get(prilezitost, 'nastaveniaProduktu'))
			const commonProps = Object.keys(get(parsedData, 'nastavenia.nastaveniaAktualnyDodavatel', {})).filter((key) =>
				get(parsedData, 'nastavenia.nastaveniaCielove', {}).hasOwnProperty(key)
			)
			const orderedDefaultSettings = reorderObjectProperties(get(parsedData, 'nastavenia.nastaveniaAktualnyDodavatel', {}), commonProps)
			setOrderedNastaveniaAktualnyDodavatel(orderedDefaultSettings)
			setOrderedNastaveniaCielove(reorderObjectProperties(get(parsedData, 'nastavenia.nastaveniaCielove', {}), commonProps))
			setSelectedSetting(orderedDefaultSettings)
			setParsedDataNastavenia(parsedData)
		}
	}, [])

	const parseSettings = (data) => {
		try {
			return JSON.parse(data)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('Invalid value for nastaveniaProduktu')
		}
	}

	const reorderObjectProperties = (obj, commonProps) => {
		const orderedObj = {}

		commonProps.forEach((key) => {
			orderedObj[key] = obj[key]
		})

		Object.keys(obj).forEach((key) => {
			if (!commonProps.includes(key)) {
				orderedObj[key] = obj[key]
			}
		})

		return orderedObj
	}

	const formatLabel = (propertyName) => {
		return upperFirst(replace(propertyName, /([A-Z])/g, ' $1').trim())
	}

	const formatValue = (propertyValue) => {
		if (typeof propertyValue === 'boolean') {
			return propertyValue ? t('components:Prilezitosti.áno') : t('components:Prilezitosti.nie')
		}
		return propertyValue
	}

	const createContent = (dataObj, key) => {
		if (isArray(dataObj[key])) {
			return (
				<>
					<div className={'info-row'}>
						<div className={'info-column font-weight-600'}>{formatLabel(key)}</div>
					</div>
					{dataObj[key].map((object) => Object.keys(object).map((objKey) => createContent(object, objKey)))}
				</>
			)
		}
		if (typeof dataObj[key] === 'object' && !isEmpty(dataObj[key]) && key !== 'nastavenia') {
			return (
				<>
					<div className={'info-row'}>
						<div className={'info-column font-weight-600'}>{formatLabel(key)}</div>
					</div>
					{Object.keys(dataObj[key]).map((objKey) => createContent(dataObj[key], objKey))}
				</>
			)
		}
		if (typeof dataObj[key] !== 'object') {
			return (
				<div className={'info-row'}>
					<div className={'info-column'}>{formatLabel(key)}</div>
					<div className={'info-column info-value'}>{formatValue(dataObj[key])}</div>
				</div>
			)
		}
	}

	const createSettingsContent = (dataObj, key) => {
		if (!isEmpty(dataObj[key]))
			return (
				<>
					<div className={'info-box-title mt-20 mb-10'}>{formatLabel(key)}</div>
					<div className={'info-box flex flex-col gap-10'}>
						{Object.keys(dataObj[key]).map((settingKey) => createContent(dataObj[key], settingKey))}
					</div>
				</>
			)
	}

	return (
		<div>
			{get(prilezitost, 'prilezitostProdukty', []).map((produkt) => (
				<div className={'info-box flex flex-col gap-10'}>
					<div className={'info-row'}>
						<div className={'info-column'}>{t('components:Prilezitosti.Komodita typ')}</div>
						<div className={'info-column info-value'}>{get(produkt, 'komoditaTypNazov', '-')}</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>{t('components:Prilezitosti.Rodina produktov')}</div>
						<div className={'info-column info-value'}>{get(produkt, 'produktovaRodinaNazov', '-')}</div>
					</div>
					<div className={'info-row'}>
						<div className={'info-column'}>{t('components:Prilezitosti.Produkt')}</div>
						<div className={'info-column info-value'}>{get(produkt, 'produktNazov', '-')}</div>
					</div>
				</div>
			))}

			{parsedDataNastaveniaKeyValue && (
				<>
					<div className={'info-box-title mt-20 mb-10'}>{t('components:Prilezitosti.Nastavenia')}</div>
					<div className={'info-box flex flex-col gap-10'}>
						{Object.keys(parsedDataNastaveniaKeyValue).map((key) => (
							<div key={key} className={'info-row'}>
								<div className={'info-column'}>
									<div>{formatLabel(key)}</div>
								</div>
								<div className={'info-column info-value'}>
									<div>{formatValue(get(parsedDataNastaveniaKeyValue, key, '-'))}</div>
								</div>
							</div>
						))}
					</div>
				</>
			)}

			{parsedDataNastavenia && (
				<>
					<div className={'info-box-title mt-20 mb-10'}>{t('components:Prilezitosti.Nastavenia')}</div>
					<div className={'info-box flex flex-col gap-10'}>
						{Object.keys(parsedDataNastavenia).map((key) => createContent(parsedDataNastavenia, key))}
					</div>
					{(!isEmpty(orderedNastaveniaAktualnyDodavatel) || !isEmpty(orderedNastaveniaCielove)) && (
						<div className={'flex gap-10 mt-10 mb-10 settings-btns-wrapper'}>
							{!isEmpty(orderedNastaveniaAktualnyDodavatel) && (
								<Button
									classes={cx('w-full setting-btn', { selected: selectedButton === BUTTON_KEYS.AKTUALNE })}
									onClick={() => {
										setSelectedButton(BUTTON_KEYS.AKTUALNE)
										setSelectedSetting(orderedNastaveniaAktualnyDodavatel)
									}}
									label={t('components:Prilezitosti.Nastavenie aktuálneho dodávateľa')}
								/>
							)}

							{!isEmpty(orderedNastaveniaCielove) && (
								<Button
									classes={cx('w-full setting-btn', { selected: selectedButton === BUTTON_KEYS.CIELOVE })}
									onClick={() => {
										setSelectedButton(BUTTON_KEYS.CIELOVE)
										setSelectedSetting(orderedNastaveniaCielove)
									}}
									label={t('components:Prilezitosti.Cieľové nastavenie')}
								/>
							)}
						</div>
					)}

					<div>{Object.keys(selectedSettings).map((key) => createSettingsContent(selectedSettings, key))}</div>
				</>
			)}
		</div>
	)
}

ProduktTabContent.propTypes = {
	prilezitost: PropTypes.object.isRequired
}

export default ProduktTabContent
