import React from 'react'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import cx from 'classnames'

import 'react-datepicker/dist/react-datepicker.css'
import { head } from 'lodash'
import { useTranslation } from 'react-i18next'

function DateRangePickerField(props) {
	const {
		input: { value, onChange },
		meta: { touched, error, warning },
		label,
		disabled,
		showTimeSelect,
		maxDate,
		minDate
	} = props
	const { t } = useTranslation()

	const dateFormat = showTimeSelect ? 'dd.MM.yyyy, HH:mm' : 'dd.MM.yyyy'

	let startDate = null
	let endDate = null

	if (head(value) && dayjs(head(value)).isValid()) {
		startDate = dayjs(head(value)).toDate()
	}

	if (value?.[1] && dayjs(value?.[1]).isValid()) {
		endDate = dayjs(value?.[1]).toDate()
	}

	const onChangeCustom = (dates) => {
		const [start, end] = dates
		if (!start && !end) {
			onChange(null)
		} else {
			onChange([start, end])
		}
	}

	return (
		<div className={cx('input-wrapper date-range-picker', { 'has-error': touched && error })}>
			{label && <label>{label}</label>}
			<DatePicker
				name={name}
				selectsRange={true}
				startDate={startDate}
				endDate={endDate}
				onChange={onChangeCustom}
				isClearable={true}
				disabled={disabled}
				maxDate={maxDate || dayjs().endOf('day').toDate()}
				minDate={minDate}
				locale='sk'
				dateFormat={dateFormat}
				showTimeSelect={showTimeSelect}
			/>
			<div className='text-danger'>{touched ? error : ''}</div>
			<div className='text-warn'>{touched ? warning : ''}</div>
		</div>
	)
}

DateRangePickerField.propsTypes = {
	input: PropTypes.shape({
		name: PropTypes.string.isRequired,
		value: PropTypes.any,
		onChange: PropTypes.func.isRequired
	}).isRequired,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string
	}).isRequired,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	manualEdit: PropTypes.bool,
	showTimeSelect: PropTypes.bool,
	timeIntervals: PropTypes.number
}

export default DateRangePickerField
