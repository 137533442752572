import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get, head, isEmpty } from 'lodash'
import cx from 'classnames'
import { Tooltip } from 'react-tippy'

// atoms
import Button from '../../../atoms/Button'
import StickyTableHeader from '../../../atoms/StickyTableHeader'

// components
import Tag from '../Tag'
import Header from './Header'
import Footer from './Footer'
import KomoditaIcon from '../KomoditaIcon'
import ElementLoading from '../../ElementLoading'

// actions
import * as PrilezitostiAction from '../../../actions/PrilezitostiActions'
import * as InterakcieActions from '../../../actions/Interakcie/ZaciatokInterakcie'
import * as SettingsAction from '../../../actions/SettingsActions'

// utils
import { formatDate } from '../../../utils/date'
import { KOMODITA_TYP, PRILEZITOSTI_KEYS } from '../../../utils/enums'
import { canEditLead, canSeizeLead, getColumns, praseFiltersToQueryParams } from '../../../utils/prilezitosti'
import { PRILEZITOSTI_DETAIL, PRILEZITOSTI_UPRAVIT, setRouteParams } from '../../../utils/routes'
import { history } from '../../../utils/history'
import { putReq } from '../../../utils/request'
import Permissions, { PERMISSIONS } from '../../../utils/permissionsHoc'

// icons
import { ReactComponent as SearchIcon } from '../../../resources/img/icons/new-search.svg'
import { ReactComponent as EditIcon } from '../../../resources/img/icons/new-pencil.svg'
import { ReactComponent as UserIcon } from '../../../resources/img/icons/new-user.svg'
import { ReactComponent as EmptyStateIcon } from '../../../resources/img/icons/empty.svg'

function ZoznamPrilezitosti() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const prilezitosti = useSelector((state) => state?.prilezitosti?.prilezitosti)
	const settings = useSelector((state) => state?.settings?.leadSettings)
	const filters = useSelector((state) => state?.prilezitosti?.filters)
	const auth = useSelector((state) => state?.auth)
	const prilezitostiIdentita = useSelector((state) => state?.prilezitosti?.identita)
	const oddelenieKod = prilezitostiIdentita.data?.oddelenieKod

	const isFirstRender = useRef(true)

	const [columns, setColumns] = useState([])
	const [draggedColumnIndex, setDraggedColumnIndex] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const isLastPage = !(prilezitosti.pageSize * (prilezitosti.page + 1) <= prilezitosti.total)

	useEffect(() => {
		return () => {
			// NOTE: remove search text from store
			dispatch(PrilezitostiAction.setFilters({ ...filters, [PRILEZITOSTI_KEYS.FULL_TEXT]: undefined }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	useEffect(() => {
		dispatch(PrilezitostiAction.getRiesitelIndetita(auth.user.id))
	}, [])

	useEffect(() => {
		if (!oddelenieKod) {
			return
		}
		if (isFirstRender) {
			dispatch(
				PrilezitostiAction.loadPrilezitosti(
					{
						page: 0,
						perPage: prilezitosti.pageSize,
						...praseFiltersToQueryParams(filters)
					},
					oddelenieKod
				)
			)
			isFirstRender.current = false
		} else {
			dispatch(
				PrilezitostiAction.loadPrilezitosti(
					{
						...praseFiltersToQueryParams(filters)
					},
					oddelenieKod
				)
			)
		}
	}, [dispatch, filters, oddelenieKod])

	useEffect(() => {
		const configColumns = getColumns(t)
		// NOTE: filter columns from config based on columns settings (keeping order)
		const filteredColumns =
			settings.columns?.reduce((parsedColumns, key) => {
				const found = configColumns.find((item) => item.name === key)
				if (found) parsedColumns.push(found)
				return parsedColumns
			}, []) || []
		setColumns([...filteredColumns])
	}, [settings?.columns])

	const assign = async (prilezitost) => {
		try {
			setIsLoading(true)
			const updateBody = { ...prilezitost, riesitel: auth.user.email }
			await putReq(`/api/v0/prilezitosti/${prilezitost.id}`, null, updateBody)

			dispatch(
				PrilezitostiAction.loadPrilezitosti(
					{
						page: 0,
						perPage: prilezitosti.pageSize,
						...praseFiltersToQueryParams(filters)
					},
					oddelenieKod
				)
			)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		} finally {
			setIsLoading(false)
		}
	}

	const loadMore = () => {
		if (!isLastPage) {
			dispatch(PrilezitostiAction.loadPrilezitosti({ page: prilezitosti.page + 1, perPage: prilezitosti.pageSize }, oddelenieKod))
		}
	}

	const onSelectPageSize = (selectedPageSize) => {
		if (selectedPageSize !== prilezitosti.pageSize) {
			dispatch(PrilezitostiAction.loadPrilezitosti({ ...praseFiltersToQueryParams(filters), page: 0, perPage: selectedPageSize }, oddelenieKod))
		}
	}

	// NOTE: get value from lead data based on column key from settings
	const getValue = (prilezitost, columnKey) => {
		const value = get(prilezitost, columnKey, '---')
		if (columnKey === PRILEZITOSTI_KEYS.OBCHODNY_NAZOV && value !== '---') {
			return <strong>{value}</strong>
		}
		return value
	}

	const getValues = (prilezitost, columnKey) => {
		const keys = columnKey?.split('.')
		return get(prilezitost, head(keys), [])?.map((value) => {
			return (
				<div className={'lead-table-value'}>
					{PRILEZITOSTI_KEYS.KOMODITA === columnKey && <KomoditaIcon komoditaTyp={value?.komoditaTyp} />}
					<p
						className={cx({
							strong:
								PRILEZITOSTI_KEYS.KOMODITA === columnKey &&
								(value?.komoditaTyp === `${KOMODITA_TYP.PLYN}` || value?.komoditaTyp === `${KOMODITA_TYP.NEKOMODITA}`)
						})}
					>
						{get(value, keys[1], '---')}
					</p>
				</div>
			)
		})
	}

	const renderActionColumnContent = (prilezitost) => {
		return (
			<>
				<Permissions
					allowed={[PERMISSIONS.EDIT_LEADS_OWN, PERMISSIONS.EDIT_LEADS_EXTENDED, PERMISSIONS.EDIT_LEADS_BASE]}
					render={(hasPerm, actions) => (
						<Tooltip html={t('translation:Prilezitosti.Prevziať príležitosť')} position='top' trigger='mouseenter' theme='light'>
							<Button
								classes={'table-action-button'}
								prefixIcon={<UserIcon />}
								onClick={async () => {
									if (hasPerm && canSeizeLead(oddelenieKod, prilezitost, auth)) {
										assign(prilezitost)
									} else {
										actions.openForbiddenModal()
									}
								}}
							/>
						</Tooltip>
					)}
				/>
				<Tooltip html={t('translation:Prilezitosti.Detail príležitosti')} position='top' trigger='mouseenter' theme='light'>
					<Button
						classes={'table-action-button'}
						prefixIcon={<SearchIcon />}
						onClick={async () => {
							await dispatch(InterakcieActions.zaciatokInterakcie(get(prilezitost, 'opLead.cislo')))
							history.replace(setRouteParams(PRILEZITOSTI_DETAIL, get(prilezitost, 'opLead.cislo'), get(prilezitost, 'id')))
						}}
					/>
				</Tooltip>
				<Permissions
					allowed={[PERMISSIONS.EDIT_LEADS_OWN, PERMISSIONS.EDIT_LEADS_BASE, PERMISSIONS.EDIT_LEADS_EXTENDED]}
					render={(hasPerm, actions) => (
						<Tooltip html={t('translation:Prilezitosti.Úprava príležitosti')} position='top' trigger='mouseenter' theme='light'>
							<Button
								classes={'table-action-button'}
								prefixIcon={<EditIcon />}
								onClick={async () => {
									if (
										hasPerm &&
										canEditLead(
											{
												riesitel: auth?.user?.email,
												riesitelIdentita: {
													oddelenie: {
														kod: oddelenieKod
													}
												}
											},
											auth,
											prilezitost
										)
									) {
										await dispatch(InterakcieActions.zaciatokInterakcie(get(prilezitost, 'opLead.cislo')))
										history.replace(setRouteParams(PRILEZITOSTI_UPRAVIT, get(prilezitost, 'opLead.cislo'), get(prilezitost, 'id')))
									} else {
										actions.openForbiddenModal()
									}
								}}
							/>
						</Tooltip>
					)}
				/>
			</>
		)
	}

	// NOTE: render row columns content base od column key from settings
	const renderColumnContent = (prilezitost, columnKey) => {
		const value = getValue(prilezitost, columnKey)

		switch (columnKey) {
			case PRILEZITOSTI_KEYS.STAV:
				return <Tag title={value} classes={{ active: value === 'nová' }} />
			case PRILEZITOSTI_KEYS.PRODUKT:
				return getValues(prilezitost, columnKey)
			case PRILEZITOSTI_KEYS.PRODUKTOVA_RODINA:
				return getValues(prilezitost, columnKey)
			case PRILEZITOSTI_KEYS.KOMODITA:
				return getValues(prilezitost, columnKey)
			case PRILEZITOSTI_KEYS.VYTVORENY_OD:
				return formatDate(get(prilezitost, columnKey, null))

			default:
				return value
		}
	}

	const sortingColumn = (stringFormat) => {
		dispatch(
			PrilezitostiAction.setFilters({
				...filters,
				[PRILEZITOSTI_KEYS.SORT]: stringFormat
			})
		)
		return { [PRILEZITOSTI_KEYS.SORT]: stringFormat }
	}

	const moveElementAndAdd = (array, indexOne, indexTwo) => {
		const arrayCopy = [...array]
		if (indexOne < 0 || indexOne >= arrayCopy.length || indexTwo < 0 || indexTwo >= arrayCopy.length) {
			return
		}

		const elementToMove = arrayCopy[indexOne]
		arrayCopy.splice(indexOne, 1)

		arrayCopy.splice(indexTwo, 0, elementToMove)

		return [...arrayCopy]
	}

	const onColumnDrop = (dropIndex) => {
		if (draggedColumnIndex) {
			dispatch(SettingsAction.updateLeadColumnsSetting(moveElementAndAdd(settings.columns, draggedColumnIndex, dropIndex)))
			setDraggedColumnIndex(null)
		}
	}

	return (
		<div className={'main-lead-box'}>
			{prilezitosti.isLoading || isLoading || prilezitostiIdentita.isLoading ? (
				<ElementLoading />
			) : (
				<>
					<Header
						searchSlug={get(filters, PRILEZITOSTI_KEYS.FULL_TEXT, null)}
						onChangeSlug={(searchText) => {
							dispatch(PrilezitostiAction.setFilters({ ...filters, [PRILEZITOSTI_KEYS.FULL_TEXT]: searchText }))
						}}
						columns={getColumns(t)}
					/>
					{isEmpty(prilezitosti.data) && !prilezitosti.isFailure ? (
						<div className={'table-empty-state'}>
							<div className={'empty-box'}>
								<>
									<EmptyStateIcon width={80} height={80} />
									<p>
										{t(
											'translation:Prilezitosti.Neevidujeme žiadne príležitosti pre vás alebo žiadna príležitosť nezodpovedá zadanému filtru'
										)}
									</p>
								</>
							</div>
						</div>
					) : (
						<div className='lead responsive-table-wrapper' id='scroll-pohladavky-container'>
							<table className={cx('content-table with-action-btns responsive-table padded bordered')}>
								<StickyTableHeader
									triedenie={get(filters, PRILEZITOSTI_KEYS.SORT, null)}
									columns={columns}
									sortingColumn={sortingColumn}
									onColumnDrop={onColumnDrop}
									onColumnDragStart={(index) => setDraggedColumnIndex(index)}
									grabbingColumnIndex={draggedColumnIndex}
									isColumnDraggable
									noBorder
								/>
								<tbody>
									{prilezitosti.data?.map((prilezitost) => (
										<tr className={'relative'}>
											<>
												{settings.columns?.map((columnKey) => (
													<td>{renderColumnContent(prilezitost, columnKey)}</td>
												))}
												<td className={'action-btns-col flex items-center gap-10'}>{renderActionColumnContent(prilezitost)}</td>
											</>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
					<Footer
						totalItems={prilezitosti.total}
						numberOfItems={prilezitosti.data?.length}
						selectedPageSize={prilezitosti.pageSize}
						onSelectPageSize={onSelectPageSize}
						loadMore={loadMore}
						isLastPage={isLastPage}
					/>
				</>
			)}
		</div>
	)
}

export default ZoznamPrilezitosti
