export const PRILEZITOSTI_LOAD_START = 'PRILEZITOSTI_LOAD_START'
export const PRILEZITOSTI_LOAD_DONE = 'PRILEZITOSTI_LOAD_DONE'
export const PRILEZITOSTI_LOAD_FAIL = 'PRILEZITOSTI_LOAD_FAIL'

export const PRILEZITOSTI_SET_FILTERS = 'PRILEZITOSTI_SET_FILTERS'
export const PRILEZITOSTI_RESET_FILTERS = 'PRILEZITOSTI_RESET_FILTERS'

export const OP_PRILEZITOSTI_LOAD_START = 'OP_PRILEZITOSTI_LOAD_START'
export const OP_PRILEZITOSTI_LOAD_DONE = 'OP_PRILEZITOSTI_LOAD_DONE'
export const OP_PRILEZITOSTI_LOAD_FAIL = 'OP_PRILEZITOSTI_LOAD_FAIL'

export const PRILEZITOSTI_SET_OP_FILTERS = 'PRILEZITOSTI_SET_OP_FILTERS'
export const PRILEZITOSTI_RESET_OP_FILTERS = 'PRILEZITOSTI_RESET_OP_FILTERS'

export const PRILEZITOST_DETAIL_LOAD_START = 'PRILEZITOST_ID_LOAD_START'
export const PRILEZITOST_DETAIL_LOAD_DONE = 'PRILEZITOST_ID_LOAD_DONE'
export const PRILEZITOST_DETAIL_LOAD_FAIL = 'PRILEZITOST_ID_LOAD_FAIL'

export const OP_PRILEZITOSTI_MOZNSOTI_LOAD_START = 'OP_PRILEZITOSTI_MOZNOSTI_LOAD_START'
export const OP_PRILEZITOSTI_MOZNOSTI_LOAD_DONE = 'OP_PRILEZITOSTI_MOZNOSTI_LOAD_DONE'
export const OP_PRILEZITOSTI_MOZNOSTI_LOAD_FAIL = 'OP_PRILEZITOSTI_MOZNOSTI_LOAD_FAIL'

export const PRILEZITOSTI_IDENTITA_LOAD_START = 'PRILEZITOSTI_IDENTITA_LOAD_START'
export const PRILEZITOSTI_IDENTITA_LOAD_DONE = 'PRILEZITOSTI_IDENTITA_LOAD_DONE'
export const PRILEZITOSTI_IDENTITA_LOAD_FAIL = 'PRILEZITOSTI_IDENTITA_LOAD_FAIL'
