import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, map, omit } from 'lodash'

// components
import UndefinedField from '../../components/GenericUkon/fields/UndefinedField'

// config
import { getGenericUkonConfig, COLUMNS_COUNT, EDIT_MODE } from '../GenericUkon/genericUkonConfig'

function UkonGeneric(props) {
	const { attributes, ukonData, ukonDataPred, formatDocuments, ukon } = props

	const genericUkonConfig = getGenericUkonConfig(ukon?.typ)
	const fieldsConfig = genericUkonConfig.fields

	// NOTE: fixing issue with values CP-3381
	const data = { ...ukonData, ...omit(ukon, 'atributy') }

	const fields = map(attributes, (attribute, index) => {
		const config = fieldsConfig[get(attribute, 'typ')]
		const fieldComponent = get(config, 'fieldComponent') || UndefinedField
		const field = get(attribute, 'cesta', '')
		return React.createElement(fieldComponent, {
			key: `generic-form-field-${index}`,
			field,
			editMode: EDIT_MODE.DETAIL,
			columnsCount: !isEmpty(ukonDataPred) ? COLUMNS_COUNT.THREE : COLUMNS_COUNT.TWO,
			value: get(data, field),
			originalValue: get(ukonDataPred, field.replaceAll('data.', '')),
			formValues: ukonData,
			originalValues: ukonDataPred,
			...attribute,
			...(config || {})
		})
	})

	return (
		<tbody>
			<tr>
				<td colSpan={!isEmpty(ukonDataPred) ? 3 : 2} style={{ padding: '0px', border: 'none' }}>
					{fields}
				</td>
			</tr>
			<tr>
				<td colSpan={!isEmpty(ukonDataPred) ? 3 : 2} style={{ padding: '0px' }}>
					{formatDocuments}
				</td>
			</tr>
		</tbody>
	)
}
UkonGeneric.propTypes = {
	attributes: PropTypes.array,
	ukon: PropTypes.shape(),
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonGeneric
