import React, { useCallback } from 'react'
import { debounce, get, isEmpty, isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { Field, reduxForm, getFormSyncErrors } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Tooltip } from 'react-tippy'

import Button from '../../../atoms/Button'
import { AsyncSelectField, TextareaField } from '../../../atoms'
import SelectField from '../../../atoms/SelectField'
import OptionsPickerField from '../../../atoms/OptionsPickerField'
import DokumentyField from '../../GenericUkon/fields/DokumentyField'

import validate from './validateUpravaPrilezitostiForm'

import { EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

import { scrollToFirstError } from '../../../utils/genericUkon'
import { getNameOrCompany, renderZmluvneVztahy } from '../../../utils/prilezitosti'
import { FORMS, PRILEZITOSTI_STAV, PROTECTION_INTERVAL_FOR_SEARCHING } from '../../../utils/enums'

function UpravaPrilezitostiForm(props) {
	const {
		handleSubmit,
		title,
		onBackClick,
		onCancelClick,
		onContinueClick,
		originalValues,
		stavOptions,
		agentOptions,
		opOptions,
		loadOpOptions,
		zuOptions,
		loadZuOptions,
		formValues,
		isDisabled,
		isFormValid
	} = props
	const { t } = useTranslation()

	const formErrors = useSelector((state) => getFormSyncErrors(FORMS.PRILEZITOSTI_UPRAVA)(state))

	const debouncedLoadOpOptions = useCallback(debounce(loadOpOptions, PROTECTION_INTERVAL_FOR_SEARCHING), [loadOpOptions])
	const debouncedLoadZuOptions = useCallback(debounce(loadZuOptions, PROTECTION_INTERVAL_FOR_SEARCHING), [loadZuOptions])
	const isSuvisiaceZmluvyFiledDisabled = formValues?.stav?.id === PRILEZITOSTI_STAV.UZAVRETA

	return (
		<form className='consent-page' onSubmit={handleSubmit}>
			<div className='content-header clearfix'>
				<div className='pull-right'>
					<div className='lead-edit-atcion-btn-box'>
						<Button type={'cancel'} label={t('translation:Common.Zrušiť')} onClick={onCancelClick} />
						<Tooltip
							key='plan-splatok'
							html={
								isDisabled ? (
									<p>{t('translation:Prilezitosti.V tomto stave nie je možné upravovať príležitosť')}</p>
								) : (
									Object.keys(formErrors).map((key) => <p>{formErrors[key]}</p>)
								)
							}
							position='left'
							trigger='mouseenter'
							theme='light'
							disabled={isEmpty(formErrors) && !isDisabled}
						>
							<Button
								classes={'continue-btn'}
								label={t('translation:Common.Pokračovať')}
								onClick={(e) => {
									if (isFormValid && !isDisabled) {
										onContinueClick()
									} else {
										e.preventDefault()
									}
								}}
								disabled={isDisabled}
							/>
						</Tooltip>
					</div>
				</div>
				<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
					{t('translation:Common.Späť')}
				</button>
				<div className='header-title pull-left'>{title}</div>
			</div>
			<div className='content-wrapper'>
				<div className='box'>
					<div className='box-content'>
						<table className='content-table padded bordered' cellSpacing='0'>
							<thead>
								<tr>
									<th />
									<th>{t('translation:Common.Aktuálne hodnoty')}</th>
									<th>{t('translation:Common.Nové hodnoty')}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<strong>{t('translation:Prilezitosti.Stav')}</strong>
									</td>
									<td>{get(originalValues, 'stav.nazov', '---')}</td>
									<td>
										<Field
											name='stav'
											component={OptionsPickerField}
											options={stavOptions}
											compare={(option, value) => {
												return option.value === value.value
											}}
											disabled={isDisabled}
											classNamePrefix='react-select'
										/>
									</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Prilezitosti.Obchodný partner')}</strong>
									</td>
									<td>
										<p className={'overflow-wrap'}>
											{getNameOrCompany(
												originalValues?.opLead?.meno,
												originalValues?.opLead?.priezvisko,
												originalValues?.opLead?.obchodneMeno
											)}
										</p>
									</td>
									<td>
										<Field
											name='leadOp'
											component={AsyncSelectField}
											selectOptions={opOptions}
											loadOptions={debouncedLoadOpOptions}
											classNamePrefix='react-select'
											disabled={isDisabled}
											onlyValue={false}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Prilezitosti.Agent')}</strong>
									</td>
									<td>
										<p className={'overflow-wrap'}>{get(originalValues, 'riesitel', '---')}</p>
									</td>
									<td>
										<Field
											name={'riesitel'}
											component={SelectField}
											options={agentOptions}
											classNamePrefix='react-select'
											isSearchable
											disabled={isDisabled}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Prilezitosti.Poznámka')}</strong>
									</td>
									<td>{get(originalValues, 'poznamkaInterna', '---')}</td>
									<td>
										<Field name='poznamkaInterna' component={TextareaField} disabled={isDisabled} />
									</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Prilezitosti.Súvisiace zmluvy')}</strong>
									</td>
									<td>{renderZmluvneVztahy(originalValues?.vztahy)}</td>
									<td>
										<Field
											name='suvisiaceZmluvy'
											component={AsyncSelectField}
											selectOptions={zuOptions}
											loadOptions={debouncedLoadZuOptions}
											classNamePrefix='react-select'
											disabled={isSuvisiaceZmluvyFiledDisabled || isDisabled}
											onlyValue={false}
											isMulti
										/>
									</td>
								</tr>
							</tbody>
						</table>
						<Field field='dokumenty' component={DokumentyField} editMode={EDIT_MODE.CREATE} disabled={isDisabled} />
					</div>
				</div>
			</div>
		</form>
	)
}

UpravaPrilezitostiForm.propTypes = {
	title: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onBackClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
	onContinueClick: PropTypes.func.isRequired,
	stavOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	agentOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	opOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	zuOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	originalValues: PropTypes.shape().isRequired,
	loadOpOptions: PropTypes.func.isRequired,
	loadZuOptions: PropTypes.func.isRequired,
	formValues: PropTypes.any.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	isFormValid: PropTypes.bool.isRequired
}

export default reduxForm({
	form: FORMS.PRILEZITOSTI_UPRAVA,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	onSubmitFail: (errors) => scrollToFirstError(errors),
	touchOnChange: true,
	validate
})(UpravaPrilezitostiForm)
