import StavUctu from './StavUctu'
import Prilezitosti from './Prilezitosti'
import Faktury from './Faktury'
import RychleUkony from './RychleUkony'
import Produkty from './Produkty'
import OdberneMiesta from './OdberneMiesta'
import Splnomocnenia from './Splnomocnenia'
import RozpisyZaloh from './RozpisyZaloh'
import SplatkoveKalendare from './SplatkoveKalendare'
import Digitalizacia from './Digitalizacia'
import SpravaSuhlasov from './SpravaSuhlasov'

export default {
	stavUctu: StavUctu,
	prilezitosti: Prilezitosti,
	faktury: Faktury,
	rychleUkony: RychleUkony,
	produkty: Produkty,
	odberneMiesta: OdberneMiesta,
	splnomocnenia: Splnomocnenia,
	rozpisyZaloh: RozpisyZaloh,
	splatkoveKalendare: SplatkoveKalendare,
	digitalizacia: Digitalizacia,
	spravaSuhlasov: SpravaSuhlasov
}
