import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

function PageSizes(props) {
	const { selectedPageSize, onSelectPageSize, options = [10, 20, 50, 100] } = props

	return (
		<div className={'page-sizes-box'}>
			{options.map((option) => (
				<button className={cx({ selected: option === selectedPageSize })} type='button' onClick={() => onSelectPageSize(option)}>
					{`${option}`}
				</button>
			))}
		</div>
	)
}

PageSizes.propTypes = {
	selectedPageSize: PropTypes.number.isRequired,
	onSelectPageSize: PropTypes.func.isRequired,
	options: PropTypes.array
}

export default PageSizes
