import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

// atoms
import { DatePickerField } from '../../../atoms'

// utils
import { UNKNOWN_DATE } from '../../../utils/enums'
import { formatDate } from '../../../utils/date'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class DatumViazanostiField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape()
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('components:GenericFields.Dátum viazanosti je povinný')
		}
	}

	render() {
		const { field, originalValue, editMode, columnsCount = COLUMNS_COUNT.THREE, t, value } = this.props

		const isUnknownValue = dayjs(value).isSame(UNKNOWN_DATE, 'day')
		const isUnknownOriginalValue = dayjs(originalValue).isSame(UNKNOWN_DATE, 'day')

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Dátum viazanosti')}</strong>
							</td>
							<>
								{columnsCount === COLUMNS_COUNT.THREE && (
									<td>
										{isUnknownOriginalValue
											? t('components:GenericFields.Bez viazanosti')
											: formatDate(isUnknownOriginalValue, null, 'DD.MM.YYYY')}
									</td>
								)}
								<td>{isUnknownValue ? t('components:GenericFields.Bez viazanosti') : formatDate(value, null, 'DD.MM.YYYY')}</td>
							</>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(DatumViazanostiField)
