import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { isEmpty, isEqual } from 'lodash'
import Button from '../../../atoms/Button'

import PrilezitostiModal from '../PrilezitostiModal'
import OptionsPickerField from '../../../atoms/OptionsPickerField'

function SettingsModal(props) {
	const { t } = useTranslation()
	const { isOpen, onCloseButton, onSubmit, initSelectedColumns = [], columns, resetSettings } = props

	const [selectedColumns, setSelectedColumns] = useState()
	const [options, setOptions] = useState([])

	useEffect(() => {
		if (!isEmpty(columns)) {
			const parsedColumns = columns.map((column) => {
				return { label: column.title, value: column.name, id: column.name }
			})
			setOptions(parsedColumns)
		}
	}, [columns])

	useEffect(() => {
		if (!isEqual(selectedColumns, initSelectedColumns)) {
			setSelectedColumns(initSelectedColumns)
		}
	}, [initSelectedColumns])

	const onColumnSettingChange = (option) => {
		setSelectedColumns(option)
	}

	return (
		<PrilezitostiModal
			isOpen={isOpen}
			size={'m'}
			onCloseButton={onCloseButton}
			handleOutsideClick={onCloseButton}
			title={<h3>{t('components:Prilezitosti.Nastavenia zobrazenia')}</h3>}
			body={
				<div>
					{t('translation:Common.Stĺpce')}
					<OptionsPickerField
						input={{
							onChange: onColumnSettingChange,
							value: selectedColumns
						}}
						options={options}
						onlyValue
						multi
					/>
				</div>
			}
			footer={
				<div className='flex-box-center'>
					<Button classes={'dark'} type={'line'} label={t('translation:Common.Obnoviť nastavenia')} onClick={resetSettings} />
					<div className='btn-box-modal-footer'>
						<Button
							type={'secondary'}
							label={t('translation:Common.Zavrieť')}
							onClick={() => {
								setSelectedColumns(initSelectedColumns)
								onCloseButton()
							}}
						/>
						<Button label={t('translation:Common.Uložiť nastavenia')} onClick={() => onSubmit(selectedColumns)} />
					</div>
				</div>
			}
		/>
	)
}

SettingsModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCloseButton: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	initSelectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		})
	).isRequired,
	resetSettings: PropTypes.func.isRequired
}

export default SettingsModal
