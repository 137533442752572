import { change, Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import OptionsPickerField from '../../../atoms/OptionsPickerField'
import DateRangePickerField from '../../../atoms/DateRangePickerField'
import SelectField from '../../../atoms/SelectField'

import { FORMS, PRILEZITOSTI_FILTER_OPTIONS } from '../../../utils/enums'

function FilterForm(props) {
	const { onSubmit, selectedFilter } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const formValues = useSelector((state) => state?.form?.[FORMS.PRILEZITOSTI_FILTER]?.values)
	const priradenieValue = formValues?.[PRILEZITOSTI_FILTER_OPTIONS.PRIRADENIE]

	const optionsPickerFields = [
		PRILEZITOSTI_FILTER_OPTIONS.STAV,
		PRILEZITOSTI_FILTER_OPTIONS.ZDROJ,
		PRILEZITOSTI_FILTER_OPTIONS.KOMODITA_TYP,
		PRILEZITOSTI_FILTER_OPTIONS.KAMPAN,
		PRILEZITOSTI_FILTER_OPTIONS.KANAL,
		PRILEZITOSTI_FILTER_OPTIONS.CIELOVA_SKUPINA
	]

	const selectWithSearchFields = [PRILEZITOSTI_FILTER_OPTIONS.PRODUKT, PRILEZITOSTI_FILTER_OPTIONS.PRODUKTOVA_RODINA]

	const getOptions = () => {
		return (
			selectedFilter?.options?.map((option) => {
				return {
					label: option?.nazov,
					value: option?.identifikator ?? option?.nazov
				}
			}) || []
		)
	}

	const priradenieOptions = [
		{
			label: t('components:Prilezitosti.Bez priradeného agenta'),
			value: '-'
		},
		{
			label: t('components:Prilezitosti.S priradeným agentom'),
			value: ''
		}
	]

	useEffect(() => {
		if (priradenieValue?.value === '-') {
			dispatch(change(FORMS.PRILEZITOSTI_FILTER, PRILEZITOSTI_FILTER_OPTIONS.AGENT, '-'))
		}
	}, [priradenieValue])

	return (
		<form onSubmit={onSubmit}>
			<div className={'form-filter'}>
				{optionsPickerFields.includes(selectedFilter?.id) && (
					<>
						<p className={'form-filter-label'}>{selectedFilter?.label}</p>
						<Field
							name={selectedFilter?.id}
							component={OptionsPickerField}
							options={getOptions()}
							compare={(option, selectedOptions) =>
								selectedOptions && selectedOptions?.find((selectedOption) => selectedOption.value === option.value)
							}
							multi
						/>
					</>
				)}
				{selectWithSearchFields.includes(selectedFilter?.id) && (
					<>
						<p className={'form-filter-label'}>{selectedFilter?.label}</p>
						<div style={{ padding: '12px 0' }}>
							<Field name={selectedFilter?.id} component={SelectField} options={getOptions()} isMulti={true} />
						</div>
					</>
				)}
				{PRILEZITOSTI_FILTER_OPTIONS.AGENT === selectedFilter?.id && (
					<>
						<p className={'form-filter-label'}>{t('components:Prilezitosti.Priradenie')}</p>
						<Field
							name={PRILEZITOSTI_FILTER_OPTIONS.PRIRADENIE}
							component={OptionsPickerField}
							options={priradenieOptions}
							compare={(option, selectedOption) => selectedOption.value === option.value}
						/>
						<p className={'form-filter-label'}>{selectedFilter?.label}</p>
						<div style={{ padding: '12px 0' }}>
							<Field
								name={selectedFilter?.id}
								component={SelectField}
								options={getOptions()}
								isMulti={true}
								isDisabled={priradenieValue?.value === '-'}
							/>
						</div>
					</>
				)}
				{PRILEZITOSTI_FILTER_OPTIONS.DATUM_VYTOVRENIA === selectedFilter?.id && (
					<>
						<p className={'form-filter-label'}>{selectedFilter?.label}</p>
						<div style={{ padding: '12px 0' }}>
							<Field name={selectedFilter?.id} component={DateRangePickerField} />
						</div>
					</>
				)}
			</div>
		</form>
	)
}

FilterForm.propsType = {
	onSubmit: PropTypes.func.isRequired,
	selectedFilter: PropTypes.string.isRequired
}

export default reduxForm({
	form: FORMS.PRILEZITOSTI_FILTER,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true
})(FilterForm)
