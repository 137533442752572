import { get, toUpper } from 'lodash'

import { ENVIRONMENT } from '../utils/enums'
import { getReq } from '../utils/request'

import { ENV_LOAD_START, ENV_LOAD_DONE, ENV_LOAD_FAIL, PRILEZITOSTI_UPDATE_COLUMNS_SETTING, PRILEZITOSTI_RESET_COLUMNS_SETTING } from '../types/settings'

export const loadEnvironment = () => {
	return async (dispatch) => {
		dispatch({
			type: ENV_LOAD_START
		})

		try {
			const settings = await getReq('/api/v0/system/prostredie')
			dispatch({
				type: ENV_LOAD_DONE,
				payload: {
					env: toUpper(get(settings, 'response.content', ENVIRONMENT.TEST))
				}
			})
		} catch (e) {
			dispatch({
				type: ENV_LOAD_FAIL
			})
		}
	}
}

export const updateLeadColumnsSetting = (columns) => {
	return (dispatch) => {
		dispatch({
			type: PRILEZITOSTI_UPDATE_COLUMNS_SETTING,
			payload: {
				columns: [...columns]
			}
		})
	}
}

export const resetLeadColumnsSetting = () => {
	return (dispatch) => {
		dispatch({
			type: PRILEZITOSTI_RESET_COLUMNS_SETTING
		})
	}
}
