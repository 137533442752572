import { get } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CloseIcon } from '../../../resources/img/icons/new-close.svg'

function FilterTags(props) {
	const { tags, removeFilter } = props

	return (
		<div className={'lead-filter-tag-box'}>
			{tags?.map((tag) => (
				<div className={'filter-tag'}>
					<span className='filter-text'>{get(tag, 'label')}</span>
					<div className='remove' onClick={() => removeFilter(tag)}>
						<CloseIcon width={8} height={8} />
					</div>
				</div>
			))}
		</div>
	)
}

FilterTags.propType = {
	tags: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			key: PropTypes.string.isRequired
		})
	).isRequired,
	removeFilter: PropTypes.func.isRequired
}

export default FilterTags
