import React from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'
import PropTypes from 'prop-types'

const modalRoot = document.getElementById('modal-root')

class Modal extends React.Component {
	static propTypes = {
		shown: PropTypes.bool,
		size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
		handleOutsideClick: PropTypes.func
	}

	constructor(props) {
		super(props)
		this.el = document.createElement('div')
	}

	componentDidMount() {
		modalRoot.appendChild(this.el)
		this.el.addEventListener('click', (e) => this.handleOutsideClick(e), false)
	}

	componentWillUnmount() {
		modalRoot.removeChild(this.el)
		this.el.removeEventListener('click', (e) => this.handleOutsideClick(e), false)
	}

	handleOutsideClick(e) {
		const { handleOutsideClick } = this.props

		if (this.el.childNodes[0] === e.target && handleOutsideClick) {
			handleOutsideClick()
		}
	}

	render() {
		const { shown, size } = this.props

		const element = (
			<div className={cx('modal', 'dismissible', { shown: !!shown })} data-size={size || 'm'}>
				<div className='modal-wrapper'>{this.props.children}</div>
			</div>
		)

		return ReactDOM.createPortal(element, this.el)
	}
}

export default Modal
