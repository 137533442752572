import React from 'react'
import { map, get, split, forEach, filter, orderBy, isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import cx from 'classnames'

// utils
import { LONG_PRESS_DURATION, DIRECTION } from '../utils/enums'

class StickyTableHeader extends React.Component {
	constructor(props) {
		super(props)

		this.timeoutId = null
	}

	state = {
		dragEnabled: false,
		columns: []
	}

	componentDidMount() {
		this.normalizeColumns(this.props.triedenie)
	}

	componentDidUpdate(prevProps) {
		const { triedenie, columns } = this.props
		if (prevProps.triedenie !== triedenie || !isEqual(prevProps.columns, columns)) {
			this.normalizeColumns(triedenie)
		}
	}

	normalizeColumns = (triedenie) => {
		const { columns } = this.props
		const triedenieCols = split(triedenie, ',')
		const normalizeCols = map(columns, (col) => {
			let column = col
			forEach(triedenieCols, (triedenieCol, index) => {
				const directionName = split(triedenieCol, ':')
				if (get(directionName, '[0]') === col.name) {
					column = {
						...col,
						order: index + 1,
						direction: get(directionName, '[1]')
					}
				}
			})
			return column
		})
		this.setState({ columns: normalizeCols })
	}

	toogleDirection = (direction) => {
		switch (direction) {
			case DIRECTION.DESC: {
				return DIRECTION.ASC
			}
			case DIRECTION.ASC: {
				return undefined
			}
			default:
				return DIRECTION.DESC
		}
	}

	onChangeDirection = (changedColumn) => {
		const { sortingColumn } = this.props
		const { columns } = this.state

		const newDirection = this.toogleDirection(changedColumn.direction)
		const correction = newDirection ? 1 : -1
		const newColumns = map(columns, (column) => {
			if (column.name == changedColumn.name) {
				return {
					...column,
					order: newDirection ? 1 : undefined,
					direction: newDirection
				}
			}

			return {
				...column,
				order: column.order ? column.order + correction : undefined
			}
		})
		this.setState(
			{
				columns: newColumns
			},
			() => {
				if (!sortingColumn) {
					return
				}
				const stringFormat = map(
					orderBy(
						filter(this.state.columns, (column) => {
							return column.direction && column.name && column.order
						}),
						['order'],
						['asc']
					),
					(column) => `${column.name}:${column.direction}`
				).join(',')
				const normalized = sortingColumn(stringFormat)
				if (normalized != stringFormat) {
					this.normalizeColumns(normalized)
				}
			}
		)
	}

	handleMouseDown = () => {
		this.timeoutId = setTimeout(() => {
			this.setState({ dragEnabled: true })
		}, LONG_PRESS_DURATION)
	}

	handleMouseUp = () => {
		clearTimeout(this.timeoutId)
	}

	onDragStart = (event, index) => {
		if (!this.state.dragEnabled) {
			event.preventDefault()
		} else {
			const { onColumnDragStart } = this.props
			event.dataTransfer.effectAllowed = 'move'
			onColumnDragStart(index)
		}
	}

	onDrop = (event, index) => {
		if (!this.state.dragEnabled) {
			event.preventDefault()
		} else {
			const { onColumnDrop } = this.props
			event.preventDefault()
			event.stopPropagation()
			onColumnDrop(index)
			this.setState((prevState) => {
				return {
					...prevState,
					dragEnabled: false
				}
			})
		}
	}

	onDragEnd = () => {
		if (this.state.dragEnabled) {
			this.setState((prevState) => {
				return {
					...prevState,
					dragEnabled: false
				}
			})
		}
	}

	allowDrop = (event) => {
		event.preventDefault()
	}

	render() {
		const { isColumnDraggable, grabbingColumnIndex } = this.props
		const { dragEnabled, columns } = this.state

		const columnsItems = map(columns, (column, index) => {
			if (column.notActive) {
				return (
					<th
						onMouseDown={() => isColumnDraggable && this.handleMouseDown()}
						onMouseUp={() => isColumnDraggable && this.handleMouseUp()}
						onMouseLeave={() => isColumnDraggable && this.handleMouseUp()}
						className={cx('disabled', { draggable: isColumnDraggable && dragEnabled }, { grabbing: grabbingColumnIndex === index })}
						draggable={isColumnDraggable}
						onDragStart={(event) => isColumnDraggable && this.onDragStart(event, index)}
						onDragOver={(event) => isColumnDraggable && this.allowDrop(event)}
						onDrop={(event) => isColumnDraggable && this.onDrop(event, index)}
						onDragEnd={() => isColumnDraggable && this.onDragEnd()}
						data-index={index}
						key={index}
						style={{
							width: column.width,
							textAlign: column.textAlign || 'center',
							padding: column.padding
						}}
					>
						{column.title && <div onDrop={(event) => isColumnDraggable && this.onDrop(event, index)}>{column.title}</div>}
					</th>
				)
			}
			if (column.tooltip) {
				return (
					<th
						onMouseDown={() => isColumnDraggable && this.handleMouseDown()}
						onMouseUp={() => isColumnDraggable && this.handleMouseUp()}
						onMouseLeave={() => isColumnDraggable && this.handleMouseUp()}
						draggable={isColumnDraggable}
						onDragStart={(event) => isColumnDraggable && this.onDragStart(event)}
						onDragOver={(event) => isColumnDraggable && this.allowDrop(event)}
						onDrop={(event) => isColumnDraggable && this.onDrop(event, index)}
						onDragEnd={() => isColumnDraggable && this.onDragEnd()}
						data-index={index}
						className={cx(column.direction, { draggable: isColumnDraggable && dragEnabled }, { grabbing: grabbingColumnIndex === index })}
						key={index}
						style={{ width: column.width }}
						onClick={() => this.onChangeDirection(column)}
					>
						<Tooltip title={column.tooltip} position='top-start' trigger='mouseenter' theme='light'>
							<div onDrop={(event) => isColumnDraggable && this.onDrop(event, index)} style={{ display: 'flex', gap: '10px' }}>
								<div style={{ flexShrink: 0 }} className='sort' />
								<div>{column.title}</div>
							</div>
						</Tooltip>
					</th>
				)
			}
			return (
				<th
					onMouseDown={() => isColumnDraggable && this.handleMouseDown()}
					onMouseUp={() => isColumnDraggable && this.handleMouseUp()}
					onMouseLeave={() => isColumnDraggable && this.handleMouseUp()}
					draggable={isColumnDraggable}
					onDragStart={(event) => isColumnDraggable && this.onDragStart(event, index)}
					onDrop={(event) => isColumnDraggable && this.onDrop(event, index)}
					onDragOver={(event) => isColumnDraggable && this.allowDrop(event)}
					onDragEnd={() => isColumnDraggable && this.onDragEnd()}
					data-index={index}
					className={cx(column.direction, { draggable: isColumnDraggable && dragEnabled }, { grabbing: grabbingColumnIndex === index })}
					key={index}
					style={{ width: column.width }}
					onClick={() => this.onChangeDirection(column)}
				>
					<div onDrop={(event) => isColumnDraggable && this.onDrop(event, index)} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
						<div style={{ flexShrink: 0 }} className='sort' />
						<div>{column.title}</div>
					</div>
				</th>
			)
		})

		return (
			<thead
				className={cx('sticky-header sortable', { noBorder: this.props.noBorder })}
				onDragOver={(event) => isColumnDraggable && this.allowDrop(event)}
			>
				<tr>{columnsItems}</tr>
			</thead>
		)
	}
}

StickyTableHeader.propTypes = {
	sortingColumn: PropTypes.func,
	columns: PropTypes.shape({
		name: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired
	}).isRequired,
	triedenie: PropTypes.string.isRequired,
	style: PropTypes.shape(),
	noBorder: PropTypes.bool,
	isColumnDraggable: PropTypes.bool,
	onColumnDrop: PropTypes.func,
	onColumnDragStart: PropTypes.func,
	grabbingColumnIndex: PropTypes.number
}

export default StickyTableHeader
