import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { get, head } from 'lodash'
import { Tooltip } from 'react-tippy'
import { getZmluvnyVztahStateColor, getZmluvnyVztahStateText } from '../../../utils/zmluvneVztahy'
import { DATE_FORMAT, ZMLUVNY_VZTAH_TECHNICKY_STAV } from '../../../utils/enums'
import AlertIcon from '../../../resources/img/icons/alert.svg'
import { formatDate } from '../../../utils/date'

function ZmluvnyVztahField(props) {
	const { value } = props
	const { t } = useTranslation()

	return (
		<div className='inner-box'>
			<table className='content-table padded bordered' cellSpacing='0'>
				<tbody>
					<tr>
						<td>
							<strong>{t('components:GenericFields.Zmluvný vzťah')}</strong>
						</td>
						<td>
							<div>
								{`${t('components:GenericFields.Názov')}: `}
								<strong>{get(head(value?.produkty), 'rodina.nazov', '-')}</strong>
							</div>
							<div>
								{`${t('components:GenericFields.Produktová rodina')}: `}
								<strong>
									<span>{get(head(value?.produkty), 'nazov') || get(head(value?.produkty), 'kod') || '-'}</span>
								</strong>
							</div>
							<div>
								{`${t('components:GenericFields.Číslo zmluvy')}: `}
								<strong>{get(value, 'cislo', '-')}</strong>
							</div>
							<div>
								{`${t('components:GenericFields.Dátum účinnosti')}: `}
								<strong>{formatDate(get(value, 'ucinnyOd'), '-', DATE_FORMAT.RENDER_DATE)}</strong>
								<Tooltip html={getZmluvnyVztahStateText(value, t)} position='right' trigger='mouseenter' theme='light'>
									<span
										className='label circle'
										data-color={getZmluvnyVztahStateColor(value)}
										style={{
											lineHeight: '21px',
											marginBottom: '2px',
											marginLeft: '5px',
											backgroundImage:
												get(head(get(value, 'zmeny', [])), 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.CHYBNE && `url(${AlertIcon})`
										}}
									/>
								</Tooltip>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}

ZmluvnyVztahField.propTypes = {
	value: PropTypes.shape().isRequired
}
export default ZmluvnyVztahField
