import PropTypes from 'prop-types'
import cx from 'classnames'

function Tag(props) {
	const { title, classes } = props

	return <div className={cx('tag', classes)}>{title?.toLocaleUpperCase()}</div>
}

Tag.propTypes = {
	title: PropTypes.string.isRequired,
	classes: PropTypes.any
}

export default Tag
