import PropTypes from 'prop-types'
import React from 'react'
import { get } from 'lodash'

// icons
import cx from 'classnames'
import { ReactComponent as LoginIcon } from '../../../resources/img/icons/login.svg'

// components
import Tag from '../Tag'

// utils
import { formatDate } from '../../../utils/date'
import { PRILEZITOSTI_STAV } from '../../../utils/enums'

function HistoriaTabContent(props) {
	const { logs } = props

	return (
		<div className={'relative'}>
			<div className={cx('logs-wrapper', { single: logs.length < 2 })}>
				<ul className={'logs-list'}>
					{logs.map((log) => (
						<li key={log.key} className={'log-item'}>
							<div className={'info-box flex justify-between'}>
								<div className={''}>
									<span className={'timestamp date'}>{formatDate(get(log, 'ts'), '-', 'DD.MM.YYYY')}</span>
									<span className={'timestamp time'}>{formatDate(get(log, 'ts'), null, ', HH:mm')}</span>
									<span className={'message ml-10'}>{log.msg}</span>
								</div>
								<div className={'flex items-center gap-5'}>
									<Tag
										title={get(log, 'povodnyStav.nazov', '-')}
										classes={{
											active: log.povodnyStav.id === PRILEZITOSTI_STAV.NOVA,
											'with-border': log.povodnyStav.id !== PRILEZITOSTI_STAV.NOVA
										}}
									/>
									<LoginIcon />
									<Tag
										title={get(log, 'novyStav.nazov', '-')}
										classes={{
											active: log.novyStav.id === PRILEZITOSTI_STAV.NOVA,
											'with-border': log.novyStav.id !== PRILEZITOSTI_STAV.NOVA
										}}
									/>
								</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

HistoriaTabContent.propTypes = {
	logs: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default HistoriaTabContent
