import React from 'react'
import PropTypes from 'prop-types'
import { Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import { has, forEach, get, isEmpty, map, endsWith } from 'lodash'
import ReactTimeout from 'react-timeout'
import dayjs from 'dayjs'

import Route from './Route'
import AuthRequiredRoute from './AuthRequiredRoute'
import NotFoundPage from '../../containers/NotFoundPage'
import ForbiddenPage from '../../containers/ForbiddenPage'
import LoginPage from '../../containers/LoginPage'
import LoginErrorPage from '../../containers/LoginErrorPage'
import SearchPage from '../../containers/SearchPage'
import DashboardPage from '../../containers/DashboardPage'

import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'

// Zmluvne ucty
import ZmluvneUctuPage from '../../containers/ZmluvneUcty/ZmluvneUctyPage'
import ZmluvnyUcetDetailPage from '../../containers/ZmluvneUcty/ZmluvnyUcetDetailPage'
import ZmluvnyUcetEditPage from '../../containers/ZmluvneUcty/ZmluvnyUcetEditPage'

// Upomienky
import UpomienkyPage from '../../containers/Upomienky/UpomienkyPage'
import UpomienkaDetailPage from '../../containers/Upomienky/UpomienkaDetailPage'

// Uctovne doklady
import UctovnyDokladDetailPage from '../../containers/UctovneDoklady/UctovnyDokladDetailPage'

// Vratenie preplatkov
import VrateniePreplatkovZoznamPage from '../../containers/VrateniePreplatkov/VrateniePreplatkovZoznamPage'
import VrateniePreplatkovCreatePage from '../../containers/VrateniePreplatkov/VrateniePreplatkovCreatePage'

// Blokovanie upominania
import BlokovanieUpominaniaZoznamPage from '../../containers/BlokovanieUpominania/BlokovanieUpominaniaZoznamPage'
import BlokovanieUpominaniaCreatePage from '../../containers/BlokovanieUpominania/BlokovanieUpominaniaCreatePage'

// Faktury
import FakturyPage from '../../containers/Faktury/FakturyPage'
import FakturaDetailPage from '../../containers/Faktury/FakturaDetailPage'

// Stav uctu
import StavUctuPage from '../../containers/StavUctu/StavUctuPage'
import StavUctuDetailPage from '../../containers/StavUctu/StavUctuDetailPage'

// Obchodny partner imports
import ObchodnyPartnerEditPage from '../../containers/ObchodnyPartner/ObchodnyPartnerEditPage'
import ObchodnyPartnerPage from '../../containers/ObchodnyPartner/ObchodnyPartnerPage'

// BankoveUcty
import BankovyUcetDetailPage from '../../containers/ObchodnyPartner/BankovyUcetDetailPage'
import BankoveUctyPage from '../../containers/ObchodnyPartner/BankoveUctyPage'

// Rozpisy zaloh imports
import RozpisyZalohDetailPage from '../../containers/RozpisyZaloh/RozpisZalohDetailPage'
import RozpisyZalohPage from '../../containers/RozpisyZaloh/RozpisyZalohPage'
import RozpisyZalohHistoryPage from '../../containers/RozpisyZaloh/RozpisZalohHistory'
import RozpisZalohEditPage from '../../containers/RozpisyZaloh/RozpisZalohEditPage'

// Plany Splatok imports
import PlanySplatokDetailPage from '../../containers/PlanySplatok/PlanSplatokDetailPage'
import PlanySplatokPage from '../../containers/PlanySplatok/PlanySplatokPage'
import PlanSplatokCreatePage from '../../containers/PlanySplatok/PlanSplatokCreatePage'

// Splnomocnenia imports
import SplnomocneniaPage from '../../containers/Splnomocnenia/SplnomocneniaPage'
import VztahDetailPage from '../../containers/Splnomocnenia/VztahDetailPage'
import SplnomocneniaCreatePage from '../../containers/Splnomocnenia/SplnomocneniaCreatePage'
import SplnomocneniaCancelPage from '../../containers/Splnomocnenia/SplnomocneniaCancelPage'

// Ukony imports
import UkonDetailPage from '../../containers/Ukony/UkonDetailPage/UkonDetailPage'

// Anonymny vseobecny ukon
import AnonymnyVseobecnyUkonPage from '../../containers/AnonymnyVseobecnyUkon/AnonymnyVseobecnyUkonPage'

// Zmluvy
import ZmluvneVztahyPage from '../../containers/ZmluvneVztahy/ZmluvneVztahyPage'

// Zalozenie OP ukon
import ObchodnyPartnerZalozeniePage from '../../containers/ObchodnyPartner/ObchodnyPartnerZalozeniePage'

import LoginCompleteHandler from '../LoginCompleteHandler'
import UkoncenieInterakcie from '../../containers/UkoncenieInterakcie'

// Genericky ukon
import GenericUkonPage from '../../containers/GenericUkon/GenericUkonPage'

// Suhlasy
import ZoznamSuhlasovPage from '../../containers/SpravaSuhlasov/ZoznamSuhlasovPage'
import HistoriaSuhlasuPage from '../../containers/SpravaSuhlasov/HistoriaSuhlasuPage'

// actions
import * as AuthActions from '../../actions/AuthActions'
import * as StatusActions from '../../actions/StatusActions'
import * as DataActions from '../../actions/DataActions'
import InterakcieActions from '../../actions/Interakcie'
import { resetStorage } from '../../actions/ResetStorageActions'
import * as SettingsActions from '../../actions/SettingsActions'
import * as ExternalIntegrationsActions from '../../actions/ExternalIntegrationsActions'

// utils
import { ERROR, CRASH_ERROR_TYPES, PAGES } from '../../utils/enums'
import { setErrorMsgToLocalStorage } from '../../utils/errorHandler'
import * as RoutesDef from '../../utils/routes'
import config from '../../utils/config'

import StatusToastMessage from '../StatusToastMessage'
import MainLayout from '../../containers/Layout/MainLayout'
import SimpleLayout from '../../containers/Layout/SimpleLayout'

import DefaultModal from '../Modals/DefaultModal'
import ZmenaSuhlasuPage from '../../containers/SpravaSuhlasov/ZmenaSuhlasuPage'
import PrilezitostiPage from '../../containers/Prilezitosti/PrilezitostiPage'
import PrilezitostiDetailPage from '../../containers/Prilezitosti/PrilezitostiDetailPage'
import PrilezitostiUpravaPage from '../../containers/Prilezitosti/PrilezitostiUpravaPage'

const DEFAULT_ERROR_MSG = 'Ups niečo sa pokazilo.'

// handler for global un handled errors
window.onerror = (message, source, lineno, colno, error) => {
	// save log into localstorage for later release on server
	setErrorMsgToLocalStorage(CRASH_ERROR_TYPES.APPLICATION, { msg: error.stack })
}

// axios global response handler
const responseHandler = (statusActions) => (response) => {
	if (has(response, 'data.response.status') && response.data.response.status.length > 0) {
		forEach(response.data.response.status, (status) => statusActions.statusPush(status))
	}
	return response
}

// axios global response error handler
const reponseErrorHandler = (statusActions, authActions) => (error) => {
	if (has(error, 'status') && error.status === 401) {
		authActions.logoutUser()
	}
	if (has(error, 'response') && error && error.response) {
		const stack = {
			url: error.response.request.responseURL,
			status: error.response.status,
			data: error.response.data,
			header: error.response.headers,
			msg: error.response.statusText
		}
		// save log into localstorage for later release on server
		setErrorMsgToLocalStorage(CRASH_ERROR_TYPES.NETWORK, stack)
	}
	if (has(error, 'response.status') && error && error.response && !isDisabledStatus(get(error, 'config.url'))) {
		// on 401 http error code do not show status tool tip bar only log out user
		switch (error.response.status) {
			case 401:
				authActions.logoutUser()
				break
			case 409:
				if (!isEmpty(get(error, 'response.data.response.status'))) {
					const popis = map(error.response.data.response.status, (status) => get(status, 'popis', ''))
					statusActions.statusPush({
						popis,
						typ: ERROR,
						kod: 409
					})
				} else {
					const status = {
						typ: ERROR,
						kod: error.response.status,
						popis: DEFAULT_ERROR_MSG
					}
					statusActions.statusPush(status)
				}
				break
			default: {
				if (!isEmpty(get(error, 'response.data.response.status'))) {
					forEach(error.response.data.response.status, (status) => statusActions.statusPush(status))
				} else {
					const status = {
						typ: ERROR,
						kod: error.response.status,
						popis: DEFAULT_ERROR_MSG
					}
					statusActions.statusPush(status)
				}
			}
		}
	}

	// Do something with response error
	return Promise.reject(error)
}

const isDisabledStatus = (url) => {
	return endsWith(url, '/pin/check') || endsWith(url, '/pin/generate')
}

class Routes extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired,
		settings: PropTypes.shape().isRequired,
		authActions: PropTypes.shape().isRequired,
		statusActions: PropTypes.shape().isRequired,
		settingsActions: PropTypes.shape().isRequired,
		history: PropTypes.shape({
			location: PropTypes.shape({
				pathname: PropTypes.string.isRequired
			}).isRequired
		}).isRequired,
		interakcieActions: PropTypes.shape({
			zaciatokInterakcie: PropTypes.func.isRequired
		}).isRequired,
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				opCislo: PropTypes.string
			})
		}).isRequired,
		dataActions: PropTypes.shape({
			dataChanged: PropTypes.func.isRequired
		}).isRequired,
		setInterval: PropTypes.func.isRequired
	}

	static getDerivedStateFromProps(props) {
		const { history } = props

		if (history.location.search.includes('clear=true')) {
			// TODO: Overit ci query clear je este potrebny, pretoze ak otvorim novy tab, tak su data resetnute defaultne
			props.dispatch(resetStorage())

			if (history.location.search.includes('keepRoute=true')) {
				history.replace(history.location.pathname)
			} else {
				// CP-3082 - Ak pridu ine parametre ako clear=true, tak ich nechceme odstranovat
				let urlWithoutSearchQuery = RoutesDef.removeQueryParam(window.location.search, 'clear')
				urlWithoutSearchQuery = urlWithoutSearchQuery?.length > 0 ? `?${urlWithoutSearchQuery}` : ''

				history.replace(RoutesDef.INDEX + urlWithoutSearchQuery)
			}
		}
		return null
	}

	constructor(props) {
		super(props)

		this.state = {
			inactivityAlertLast: null
		}

		const { authActions, statusActions } = props
		// axios middleware for handling responses
		axios.interceptors.response.use(responseHandler(statusActions), reponseErrorHandler(statusActions, authActions))
	}

	componentDidMount() {
		const { settings, settingsActions, externalIntegrationsActions } = this.props

		this.props.setInterval(this.fetchInactivityAlert, 30000)

		if (!get(settings, 'isLoaded') && (!get(settings, 'isLoading') || get(settings, 'isFailure'))) {
			settingsActions.loadEnvironment()
		}

		externalIntegrationsActions.loadExternalSessionFromUrl()
	}

	componentDidUpdate() {
		const { externalIntegrationsActions } = this.props
		externalIntegrationsActions.loadExternalSessionFromUrl()
	}

	fetchInactivityAlert = () => {
		const interakcia = get(this.props, 'interakcia.data')
		let vytvorenaOd = get(this.props, 'interakcia.data.vytvorenaOd')

		if (interakcia && interakcia.id && vytvorenaOd) {
			const { inactivityAlertLast } = this.state

			vytvorenaOd = dayjs(vytvorenaOd)
			const interakciaTrvanie = Math.abs(vytvorenaOd.diff(dayjs(), 'minute', true))

			let zobrazit = false
			if (inactivityAlertLast) {
				if (Math.abs(inactivityAlertLast.diff(dayjs(), 'minute', true)) > config.inactiveInteractionAlertTime) {
					zobrazit = true
				}
			} else {
				zobrazit = true
			}

			if (interakciaTrvanie > config.inactiveInteractionAlertTime && zobrazit) {
				this.setState(
					{
						inactivityAlertLast: dayjs()
					},
					() => {
						// eslint-disable-next-line no-alert
						alert(
							'Upozornenie: Interakcia s týmto OP trvá už dlhšiu dobu. V prípade, ak už ste interakciu s OP ukončili, prosím ukončite ju aj systémovo v CARE aplikácii.'
						)
					}
				)
			}
		}
	}

	commonContentContainer = (content) => {
		return (
			<div id='body' style={{ height: '100%' }}>
				{content}
			</div>
		)
	}

	render() {
		const { t, settings, tReady } = this.props

		let modal = null
		const forbiddenModalVisible = get(this.props, 'data.forbiddenModal')
		if (forbiddenModalVisible) {
			modal = (
				<DefaultModal
					modalTitle='Upozornenie'
					modalContent='Na vykonanie akcie nemáte potrebné oprávnenia.'
					leftButton={{
						onClick: () => this.props.dataActions.dataChanged('forbiddenModal', false),
						text: 'Ok',
						color: 'red',
						outline: false
					}}
					visible
				/>
			)
		}

		if (!tReady) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (get(settings, 'isLoading')) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (get(settings, 'isFailure') || !get(settings, 'isLoaded')) {
			return this.commonContentContainer(<ElementFailure text={t('translation:Common.Nepodarilo sa načítať nastavenia aplikácie')} />)
		}

		return this.commonContentContainer(
			<>
				<StatusToastMessage />
				<Switch>
					<Route exact path={RoutesDef.PRIHLASENIE} component={LoginPage} layout={SimpleLayout} />
					<Route exact path={RoutesDef.PRIHLASENIE_CHYBA} component={LoginErrorPage} layout={SimpleLayout} />
					<Route exact path={RoutesDef.DOKONCENIE_PRIHLASENIA} component={LoginCompleteHandler} layout={SimpleLayout} />

					<AuthRequiredRoute exact withoutPartner path={RoutesDef.INDEX} component={SearchPage} layout={SimpleLayout} />
					<AuthRequiredRoute exact needPartner path={RoutesDef.PREHLAD} component={DashboardPage} layout={MainLayout} page={PAGES.DASHBOARD} />

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.OP_DETAIL}
						component={ObchodnyPartnerPage}
						layout={MainLayout}
						page={PAGES.OBCHODNY_PARTNER}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.OP_UPRAVIT}
						component={ObchodnyPartnerEditPage}
						layout={MainLayout}
						page={PAGES.OBCHODNY_PARTNER}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.POHLADAVKY_ZOZNAM}
						component={StavUctuPage}
						layout={MainLayout}
						page={PAGES.STAV_UCTU}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.POHLADAVKY_DETAIL}
						component={StavUctuDetailPage}
						layout={MainLayout}
						page={PAGES.STAV_UCTU}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.ZOZNAM_SUHLASOV}
						component={ZoznamSuhlasovPage}
						layout={MainLayout}
						page={PAGES.SPRAVA_SUHLASOV}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.ZMENA_SUHLASU}
						component={ZmenaSuhlasuPage}
						layout={MainLayout}
						page={PAGES.SPRAVA_SUHLASOV}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.HISTORIA_SUHLASU}
						component={HistoriaSuhlasuPage}
						layout={MainLayout}
						page={PAGES.SPRAVA_SUHLASOV}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.ZMLUVNE_UCTY_ZOZNAM}
						component={ZmluvneUctuPage}
						layout={MainLayout}
						page={PAGES.ZMLUVNE_UCTY}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.ZMLUVNE_UCTY_DETAIL}
						component={ZmluvnyUcetDetailPage}
						layout={MainLayout}
						page={PAGES.ZMLUVNE_UCTY}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.ZMLUVNE_UCTY_UPRAVIT}
						component={ZmluvnyUcetEditPage}
						layout={MainLayout}
						page={PAGES.ZMLUVNE_UCTY}
					/>

					<AuthRequiredRoute exact needPartner path={RoutesDef.ZMLUVY_ZOZNAM} component={ZmluvneVztahyPage} layout={MainLayout} page={PAGES.ZMLUVY} />

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.BANKOVE_UCTY_ZOZNAM}
						component={BankoveUctyPage}
						layout={MainLayout}
						page={PAGES.OBCHODNY_PARTNER}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.BANKOVE_UCTY_DETAIL}
						component={BankovyUcetDetailPage}
						layout={MainLayout}
						page={PAGES.OBCHODNY_PARTNER}
					/>

					<AuthRequiredRoute exact needPartner path={RoutesDef.FAKTURY_ZOZNAM} component={FakturyPage} layout={MainLayout} page={PAGES.FAKTURY} />
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.FAKTURY_DETAIL}
						component={FakturaDetailPage}
						layout={MainLayout}
						page={PAGES.FAKTURY}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.UPOMIENKY_ZOZNAM}
						component={UpomienkyPage}
						layout={MainLayout}
						page={PAGES.UPOMIENKY}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.UPOMIENKY_DETAIL}
						component={UpomienkaDetailPage}
						layout={MainLayout}
						page={PAGES.UPOMIENKY}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.UCTOVNE_DOKLADY_DETAIL}
						component={UctovnyDokladDetailPage}
						layout={MainLayout}
						page={PAGES.STAV_UCTU}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.PREPLATKY_ZOZNAM}
						component={VrateniePreplatkovZoznamPage}
						layout={MainLayout}
						page={PAGES.STAV_UCTU}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.PREPLATKY_VYTVORIT}
						component={VrateniePreplatkovCreatePage}
						layout={MainLayout}
						page={PAGES.STAV_UCTU}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.BLOKOVANIE_UPOMINANIA_ZOZNAM}
						component={BlokovanieUpominaniaZoznamPage}
						layout={MainLayout}
						page={PAGES.STAV_UCTU}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.BLOKOVANIE_UPOMINANIA_VYTVORIT}
						component={BlokovanieUpominaniaCreatePage}
						layout={MainLayout}
						page={PAGES.STAV_UCTU}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.ROZPISY_ZALOH_ZOZNAM}
						component={RozpisyZalohPage}
						layout={MainLayout}
						page={PAGES.ROZPISY_ZALOH}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.ROZPISY_ZALOH_DETAIL}
						component={RozpisyZalohDetailPage}
						layout={MainLayout}
						page={PAGES.ROZPISY_ZALOH}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.ROZPISY_ZALOH_HISTORIA}
						component={RozpisyZalohHistoryPage}
						layout={MainLayout}
						page={PAGES.ROZPISY_ZALOH}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.ROZPISY_ZALOH_UPRAVIT}
						component={RozpisZalohEditPage}
						layout={MainLayout}
						page={PAGES.ROZPISY_ZALOH}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.PLANY_SPLATOK_ZOZNAM}
						component={PlanySplatokPage}
						layout={MainLayout}
						page={PAGES.PLANY_SPLATOK}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.PLANY_SPLATOK_DETAIL}
						component={PlanySplatokDetailPage}
						layout={MainLayout}
						page={PAGES.PLANY_SPLATOK}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.PLANY_SPLATOK_VYTVORIT}
						component={PlanSplatokCreatePage}
						layout={MainLayout}
						page={PAGES.PLANY_SPLATOK}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.VZTAHY_ZOZNAM}
						component={SplnomocneniaPage}
						layout={MainLayout}
						page={PAGES.SPLNOMOCNENE_OSOBY}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.VZTAHY_DETAIL}
						component={VztahDetailPage}
						layout={MainLayout}
						page={PAGES.SPLNOMOCNENE_OSOBY}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.VZTAHY_VYTVORIT}
						component={SplnomocneniaCreatePage}
						layout={MainLayout}
						page={PAGES.SPLNOMOCNENE_OSOBY}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.VZTAHY_ZRUSIT}
						component={SplnomocneniaCancelPage}
						layout={MainLayout}
						page={PAGES.SPLNOMOCNENE_OSOBY}
					/>

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.GENERIC_UKON}
						component={GenericUkonPage}
						layout={MainLayout}
						page={PAGES.GENERIC_UKON}
					/>

					<AuthRequiredRoute exact needPartner path={RoutesDef.UKON_DETAIL} component={UkonDetailPage} layout={MainLayout} />
					<AuthRequiredRoute exact needPartner path={RoutesDef.DOKONCENIE_INTERAKCIE} component={UkoncenieInterakcie} layout={MainLayout} />

					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.PRILEZITOSTI_UPRAVIT}
						component={PrilezitostiUpravaPage}
						layout={MainLayout}
						page={PAGES.PRILEZITOSTI}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.PRILEZITOSTI_DETAIL}
						component={PrilezitostiDetailPage}
						layout={MainLayout}
						page={PAGES.PRILEZITOSTI}
					/>
					<AuthRequiredRoute
						exact
						needPartner
						path={RoutesDef.PRILEZITOSTI}
						component={PrilezitostiPage}
						layout={MainLayout}
						page={PAGES.PRILEZITOSTI}
					/>

					<AuthRequiredRoute
						exact
						withoutPartner
						path={RoutesDef.ANONYMNY_VSEOBECNY_UKON}
						component={AnonymnyVseobecnyUkonPage}
						layout={SimpleLayout}
					/>
					<AuthRequiredRoute exact withoutPartner path={RoutesDef.ZALOZENIE_OP} component={ObchodnyPartnerZalozeniePage} layout={SimpleLayout} />

					<Route exact path={RoutesDef.FORBIDDEN} component={ForbiddenPage} layout={SimpleLayout} />

					<Route component={NotFoundPage} layout={SimpleLayout} />
				</Switch>
				{modal}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	data: state.data,
	settings: get(state, 'settings.settings')
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	interakcieActions: bindActionCreators(InterakcieActions, dispatch),
	authActions: bindActionCreators(AuthActions, dispatch),
	statusActions: bindActionCreators(StatusActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	settingsActions: bindActionCreators(SettingsActions, dispatch),
	externalIntegrationsActions: bindActionCreators(ExternalIntegrationsActions, dispatch)
})

export default compose(withTranslation('components'), withRouter, connect(mapStateToProps, mapDispatchToProps))(ReactTimeout(Routes))
