import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

// atoms
import { Tooltip } from 'react-tippy'
import Button from '../../../atoms/Button'

// components
import FilterModal from '../Filters/FilterModal'
import FilterTags from '../Filters/FilterTags'
import FilterButton from '../Filters/FilterButton'
import SettingsModal from './SettingsModal'

// actions
import * as PrilezitostiAction from '../../../actions/PrilezitostiActions'
import * as SettingsAction from '../../../actions/SettingsActions'

// utils
import { getAccessToken } from '../../../utils/auth'
import { PROTECTION_INTERVAL_FOR_SEARCHING } from '../../../utils/enums'
import Permissions, { PERMISSIONS } from '../../../utils/permissionsHoc'
import { countActiveFilters, getFilterTagOptions, removeFilterFromFilters } from '../../../utils/prilezitosti'

// icons
import { ReactComponent as SearchIcon } from '../../../resources/img/icons/new-search.svg'
import { ReactComponent as SettingIcon } from '../../../resources/img/icons/setting.svg'

function Header(props) {
	const { searchSlug, onChangeSlug, columns } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const settings = useSelector((state) => state?.settings?.leadSettings)
	const filters = useSelector((state) => state?.prilezitosti?.filters)
	const leadFilterValues = useSelector((state) => state?.ciselniky?.data?.prilezitosti)

	const [isOpenFilterModal, setIsOpenFilterModal] = useState(false)
	const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false)
	const [filterTags, setFilterTags] = useState([])
	const [searchText, setSearchText] = useState(searchSlug)
	const [numberOfActiveFilters, setNumberOfActiveFilters] = useState(0)

	useEffect(() => {
		setFilterTags(getFilterTagOptions(filters))
		setNumberOfActiveFilters(countActiveFilters(filters))
	}, [filters])

	const removeFilter = (removedFilter) => {
		const updatedFilters = removeFilterFromFilters(filters, removedFilter)
		dispatch(PrilezitostiAction.setFilters(updatedFilters))
	}

	const debouncedChangeHandler = useCallback(
		debounce((value) => {
			onChangeSlug(value)
		}, PROTECTION_INTERVAL_FOR_SEARCHING),
		[onChangeSlug] // make sure to re-create the debounced function only if `onChangeSlug` changes
	)

	return (
		<>
			<div className={'lead-header-box'}>
				<h3 className='clearfix'>
					<span className='pull-left'>{t('components:Prilezitosti.Príležitosti')}</span>
				</h3>
				<Permissions allowed={[PERMISSIONS.VIEW_LEADS]}>
					<div className='search-wrapper lead-search-wrapper'>
						<Tooltip
							html={t(
								'translation:Prilezitosti.Hľadať podľa mena, priezviska, obchodného mena, IČO, DIČ, IČ DPH, kontaktného telefónu, kontaktného e-mailu, adresy (trvalej, korešpondenčnej, sídla), riešiteľa, ID príležitosti, ID leadu, ID LA tiketu'
							)}
							position='bottom'
							trigger='mouseenter'
							theme='light'
						>
							<input
								className={'lead-search-input'}
								type='text'
								autoComplete='false'
								value={searchText}
								onChange={(e) => {
									const value = e?.target?.value || null
									setSearchText(value)
									debouncedChangeHandler(value)
								}}
								placeholder={t('components:Prilezitosti.Hľadať podľa mena, čísla OP, ID')}
							/>
							<div className='search-icon'>
								<SearchIcon />
							</div>
						</Tooltip>
					</div>
				</Permissions>
				<div className='flex-box-center gap-10'>
					<Permissions
						allowed={[PERMISSIONS.VIEW_LEADS]}
						render={(hasPerm, actions) => (
							<Button
								classes={'lead-create-btn'}
								type={'secondary'}
								label={t('translation:Prilezitosti.Vytvoriť lead')}
								onClick={(e) => {
									e.preventDefault()
									if (hasPerm) {
										window.open(`/api/v0/nela/new-lead?accessToken=${getAccessToken()}`)
									} else {
										actions.openForbiddenModal()
									}
								}}
							/>
						)}
					/>
					<Button type={'secondary'} prefixIcon={<SettingIcon />} onClick={() => setIsOpenSettingsModal(true)} />
					<FilterButton onFilterBtnClick={() => setIsOpenFilterModal(true)} numberOfActiveFilters={numberOfActiveFilters} />
				</div>
			</div>
			<div style={{ paddingBottom: 15 }}>
				<FilterTags tags={filterTags} removeFilter={removeFilter} />
			</div>
			<SettingsModal
				isOpen={isOpenSettingsModal}
				onCloseButton={() => setIsOpenSettingsModal(false)}
				onSubmit={(columns) => {
					dispatch(SettingsAction.updateLeadColumnsSetting(columns))
					setIsOpenSettingsModal(false)
				}}
				columns={columns}
				initSelectedColumns={settings?.columns}
				resetSettings={() => {
					dispatch(SettingsAction.resetLeadColumnsSetting())
					setIsOpenSettingsModal(false)
				}}
			/>
			<FilterModal
				isOpen={isOpenFilterModal}
				onCloseButton={() => setIsOpenFilterModal(false)}
				onSubmit={(columns) => {
					dispatch(PrilezitostiAction.setFilters(columns))
					setIsOpenFilterModal(false)
				}}
				resetFilters={() => {
					dispatch(PrilezitostiAction.resetFilters())
					setIsOpenFilterModal(false)
				}}
				leadFilterValues={leadFilterValues}
				initValues={filters}
			/>
		</>
	)
}

Header.propTypes = {
	searchSlug: PropTypes.func.isRequired,
	onChangeSlug: PropTypes.func.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		})
	).isRequired
}

export default Header
