import { get, concat, size, orderBy, indexOf, forEach, toArray, map, isEmpty, join, keyBy, uniq, filter, head } from 'lodash'

import {
	ZMLUVNE_VZTAHY_LOAD_START,
	ZMLUVNE_VZTAHY_LOAD_PART_DONE,
	ZMLUVNE_VZTAHY_LOAD_DONE,
	ZMLUVNE_VZTAHY_LOAD_FAIL,
	ZMLUVNE_VZTAHY_CLEAR,
	ZMLUVNY_VZTAH_LOAD_START,
	ZMLUVNY_VZTAH_LOAD_DONE,
	ZMLUVNY_VZTAH_LOAD_FAIL,
	ZMLUVNY_VZTAH_DETAIL_CLEAR,
	ZMLUVNY_VZTAH_LIST_CLEAR,
	ZMLUVNY_VZTAH_DOCUMENTS_LOAD_START,
	ZMLUVNY_VZTAH_DOCUMENTS_LOAD_DONE,
	ZMLUVNY_VZTAH_DOCUMENTS_LOAD_FAIL,
	ZMLUVNY_VZTAH_DOCUMENTS_LIST_CLEAR,
	ZMLUVNY_VZTAH_LOAD_DETAIL_START,
	ZMLUVNY_VZTAH_LOAD_DETAIL_DONE,
	ZMLUVNY_VZTAH_LOAD_DETAIL_FAIL
} from '../types/zmluvneVztahyTypes'
import { ZMLUVNY_VZTAH_TYP } from '../utils/enums'
import { getReq } from '../utils/request'
import { isZmluvnyVztahAktivny } from '../utils/zmluvneVztahy'

const zmluvneVztahyOrder = [
	ZMLUVNY_VZTAH_TYP.DODATOK,
	ZMLUVNY_VZTAH_TYP.PREDAJ,
	ZMLUVNY_VZTAH_TYP.SLUZBA_NCB,
	ZMLUVNY_VZTAH_TYP.SLUZBA_KOMODITA,
	ZMLUVNY_VZTAH_TYP.KOMODITA
]

const orderZmluvneVztahy = (zmluvnyVztah) => {
	return indexOf(zmluvneVztahyOrder, get(zmluvnyVztah, 'typ.id'))
}

const initStatePartMiestoSpotreby = {
	data: {
		all: [],
		aktivne: [],
		neaktivne: []
	},
	isLoading: false,
	isFailure: false
}

const initStatePartZmluvnyVztah = {
	data: null,
	isLoading: false,
	isFailure: false
}

const initStatePartDocuments = {
	data: [],
	isLoading: false,
	isFailure: false
}

async function loadZmluvneVztahyByParts(opCislo, msCislo, dispatch, pageSize = 100, queryParams = {}, getStore) {
	const loadPage = async (page) => {
		const store = getStore()
		const query = {
			...queryParams,
			msCislo,
			pageSize,
			page
		}
		try {
			const zmluvneVztahyResponse = await getReq(`/api/v2/op/${opCislo}/zmluvne-vztahy`, query)
			const zmluvneVztahyData = get(zmluvneVztahyResponse, 'response.content', [])

			const zmluvneVztahy = page == 1 ? zmluvneVztahyData : concat(get(store, `zmluvneVztahy.miestaSpotreby.${msCislo}.data.all`, []), zmluvneVztahyData)

			dispatch({
				type: ZMLUVNE_VZTAHY_LOAD_PART_DONE,
				payload: {
					miestoSpotreby: {
						[msCislo]: {
							...initStatePartMiestoSpotreby,
							data: {
								...initStatePartMiestoSpotreby.data,
								all: zmluvneVztahy
							},
							isLoading: false
						}
					}
				}
			})

			if (size(get(zmluvneVztahyData, 'response.content', [])) >= pageSize) {
				await loadPage(page + 1)
			} else {
				const uniqueZmluvnyVztahCislo = map(zmluvneVztahy, 'cislo')

				const tempZmluvneUcty = {}
				const tempZmluvneVztahy = {}

				forEach(zmluvneVztahy, (zmluvnyVztah) => {
					const parentCislo = get(zmluvnyVztah, 'zdrojovyZmluvnyVztahCislo')
					// NOTE: Dochadza k pripadom, kedy zmluva ma nastavenu nadradenu zmluvu, ale tam nam nepride v zozname.
					// Takuto zmluvu chceme vypisat ako nadradenu a tak overime, ci sa jej parent nachadza v zozname.
					// V opacnom pripade by sa nevypisala vobec.
					if (parentCislo && isZmluvnyVztahAktivny(zmluvnyVztah) && indexOf(uniqueZmluvnyVztahCislo, parentCislo) >= 0) {
						// Podradena zmluva - tieto zmluvy zobrazujeme ako vnorene
						// Zmluvu povazujeme za vnorenu ak ma nastavenu rodicovsku zmluvu a zaroven musi byt aktivna,
						// ak nieje aktivna, zaradime ju medzi nadradene a v dalsom kroku neaktivne zmluvy
						if (!tempZmluvneVztahy[parentCislo]) {
							tempZmluvneVztahy[parentCislo] = {
								zmluvneVztahy: [zmluvnyVztah]
							}
						} else {
							tempZmluvneVztahy[parentCislo].zmluvneVztahy.push(zmluvnyVztah)
						}
					} else {
						// Nadradena zmluva - tieto zmluvy sa zobrazuju ako hlavne, bez vnorenia (offsetu)
						const cislo = get(zmluvnyVztah, 'cislo')
						if (!tempZmluvneVztahy[cislo]) {
							tempZmluvneVztahy[cislo] = {
								...zmluvnyVztah,
								zmluvneVztahy: []
							}
						} else {
							tempZmluvneVztahy[cislo] = {
								...zmluvnyVztah,
								...tempZmluvneVztahy[cislo]
							}
						}
					}
				})

				forEach(zmluvneVztahy, (zmluvnyVztah) => {
					const parentCislo = get(zmluvnyVztah, 'zdrojovyZmluvnyVztahCislo')
					if (!parentCislo || !isZmluvnyVztahAktivny(zmluvnyVztah) || indexOf(uniqueZmluvnyVztahCislo, parentCislo) < 0) {
						// Ked spracovavam nadradenu zmluvu, zaradim ju zaroven aj pod zmluvny ucet podla toho, ci je aktivna alebo neaktivna
						// Toto vykonavame iba v pripade, ze spracovavame zmluvne vztahy s vazbou na miesto spotreby (msCislo != 0)
						const cislo = get(zmluvnyVztah, 'cislo')
						let zmluvnyUcetCislo = get(zmluvnyVztah, 'zmluvnyUcetCislo') || null
						if (!zmluvnyUcetCislo && parentCislo) {
							const parent = tempZmluvneVztahy[parentCislo]
							zmluvnyUcetCislo = get(parent, 'zmluvnyUcetCislo') || null
						}
						if (tempZmluvneUcty[zmluvnyUcetCislo]) {
							if (isZmluvnyVztahAktivny(zmluvnyVztah)) {
								tempZmluvneUcty[zmluvnyUcetCislo].aktivne.push(tempZmluvneVztahy[cislo])
							} else {
								tempZmluvneUcty[zmluvnyUcetCislo].neaktivne.push(tempZmluvneVztahy[cislo])
							}
						} else if (isZmluvnyVztahAktivny(zmluvnyVztah)) {
							tempZmluvneUcty[zmluvnyUcetCislo] = {
								cislo: zmluvnyUcetCislo,
								aktivne: [tempZmluvneVztahy[cislo]],
								neaktivne: []
							}
						} else {
							tempZmluvneUcty[zmluvnyUcetCislo] = {
								cislo: zmluvnyUcetCislo,
								aktivne: [],
								neaktivne: [tempZmluvneVztahy[cislo]]
							}
						}
					}
				})

				// Utriedim vsetky podradene zmluvy
				forEach(tempZmluvneVztahy, (zmluvnyVztah) => {
					zmluvnyVztah.zmluvneVztahy = orderBy(zmluvnyVztah.zmluvneVztahy, [orderZmluvneVztahy], ['desc'])
				})

				const zmluvneVztahyArray = toArray(tempZmluvneVztahy)

				// Utriedim vsetky nadradene zmluvy vo vsetkych zmluvnych uctoch a rozdelim zmluvne ucty medzi aktivne a neaktivne
				// Zmluvny ucet povazujeme za aktivny v pripade, ze obsahuje aspon 1 aktivnu zmluvu
				const aktivneZmluvneUcty = []
				const neaktivneZmluvneUcty = []
				forEach(tempZmluvneUcty, (zmluvnyUcet) => {
					zmluvnyUcet.aktivne = orderBy(zmluvnyUcet.aktivne, [orderZmluvneVztahy], ['desc'])
					zmluvnyUcet.neaktivne = orderBy(zmluvnyUcet.neaktivne, [orderZmluvneVztahy], ['desc'])
					if (size(zmluvnyUcet.aktivne) > 0) {
						aktivneZmluvneUcty.push(zmluvnyUcet)
					} else {
						neaktivneZmluvneUcty.push(zmluvnyUcet)
					}
				})

				// Utriedim samotne zmluvne ucty podla cisla
				const aktivneZmluvneUctySorted = orderBy(aktivneZmluvneUcty, ['cislo'], ['asc'])
				const neaktivneZmluvneUctySorted = orderBy(neaktivneZmluvneUcty, ['cislo'], ['asc'])

				dispatch({
					type: ZMLUVNE_VZTAHY_LOAD_DONE,
					payload: {
						miestoSpotreby: {
							[msCislo]: {
								...initStatePartMiestoSpotreby,
								data: {
									all: zmluvneVztahyArray,
									aktivne: aktivneZmluvneUctySorted,
									neaktivne: neaktivneZmluvneUctySorted
								},
								isLoading: false
							}
						}
					}
				})
				return {
					all: zmluvneVztahyArray,
					aktivne: aktivneZmluvneUctySorted,
					neaktivne: neaktivneZmluvneUctySorted
				}
			}
		} catch (e) {
			dispatch({
				type: ZMLUVNE_VZTAHY_LOAD_FAIL,
				payload: {
					miestoSpotreby: {
						[msCislo]: {
							...initStatePartMiestoSpotreby,
							isFailure: true
						}
					}
				}
			})
		}
	}
	return await loadPage(1)
}

export const loadAllZmluvneVztahy = (msCislo, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: ZMLUVNE_VZTAHY_LOAD_START,
			payload: {
				miestoSpotreby: {
					[msCislo]: {
						...initStatePartMiestoSpotreby,
						isLoading: true
					}
				}
			}
		})

		return await loadZmluvneVztahyByParts(get(interakcia, 'opCislo'), msCislo, dispatch, 100, queryParams, getStore)
	}
}

export const loadZmluvnyVztah = (zvCislo, withUkony, withRelatedOP, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: ZMLUVNY_VZTAH_LOAD_START,
			payload: {
				zmluvnyVztahList: {
					[zvCislo]: {
						...initStatePartZmluvnyVztah,
						isLoading: true
					}
				}
			}
		})

		const query = {
			...queryParams
		}
		try {
			const zmluvnyVztahDetailData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/zmluvne-vztahy/${zvCislo}`, query)
			const zmluvnyVztahDetail = get(zmluvnyVztahDetailData, 'response.content', null)

			let ukony = []
			if (withUkony) {
				const ukonyData = await getReq('/api/v2/ukony', {
					objektTyp: 'ZmluvnyVztahDTO',
					objektId: zvCislo,
					sort: 'vytvorenyOd:DESC'
				})
				ukony = get(ukonyData, 'response.content', [])
			}

			let predajca = null
			let riesitel = null
			let nadriadeny = null

			if (withRelatedOP) {
				const predajcaOpCislo = get(zmluvnyVztahDetail, 'predajcaOpCislo', null)
				const riesitelOpCislo = get(zmluvnyVztahDetail, 'riesitelOpCislo', null)
				const nadriadenyOpCislo = get(zmluvnyVztahDetail, 'nadriadenyOpCislo', null)

				const opCisloSearch = uniq(filter([predajcaOpCislo, riesitelOpCislo, nadriadenyOpCislo], (opCislo) => opCislo != null))

				if (!isEmpty(opCisloSearch)) {
					const searchOpResults = await getReq('/api/v2/op', {
						opCislo: join(opCisloSearch, ',')
					})
					const searchOpResultsData = get(searchOpResults, 'response.content', [])
					const searchOpResultsByKeys = keyBy(searchOpResultsData, 'cislo')

					if (predajcaOpCislo && searchOpResultsByKeys[predajcaOpCislo]) {
						predajca = searchOpResultsByKeys[predajcaOpCislo]
					}

					if (riesitelOpCislo && searchOpResultsByKeys[riesitelOpCislo]) {
						riesitel = searchOpResultsByKeys[riesitelOpCislo]
					}

					if (nadriadenyOpCislo && searchOpResultsByKeys[nadriadenyOpCislo]) {
						nadriadeny = searchOpResultsByKeys[nadriadenyOpCislo]
					}
				}
			}

			const zmluvnyVztah = {
				...zmluvnyVztahDetail,
				ukony,
				predajca,
				riesitel,
				nadriadeny
			}

			dispatch({
				type: ZMLUVNY_VZTAH_LOAD_DONE,
				payload: {
					zmluvnyVztah,
					zmluvnyVztahList: {
						[zvCislo]: {
							...initStatePartZmluvnyVztah,
							data: zmluvnyVztah,
							isLoading: false
						}
					}
				}
			})

			return zmluvnyVztah
		} catch (e) {
			dispatch({
				type: ZMLUVNY_VZTAH_LOAD_FAIL,
				payload: {
					zmluvnyVztahList: {
						[zvCislo]: {
							...initStatePartZmluvnyVztah,
							isFailure: true
						}
					}
				}
			})
		}
	}
}

export const loadZmluvnyVztahDetail = (zvCislo, withUkony, withRelatedOP, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: ZMLUVNY_VZTAH_LOAD_DETAIL_START
		})

		const query = {
			...queryParams
		}
		try {
			const zmluvnyVztahDetailData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/zmluvne-vztahy/${zvCislo}`, query)
			const zmluvnyVztahDetail = get(zmluvnyVztahDetailData, 'response.content', null)

			dispatch({
				type: ZMLUVNY_VZTAH_LOAD_DETAIL_DONE,
				payload: {
					zmluvnyVztahDetail
				}
			})

			return zmluvnyVztahDetail
		} catch (e) {
			dispatch({
				type: ZMLUVNY_VZTAH_LOAD_DETAIL_FAIL
			})
		}
	}
}

export const loadZmluvnyVztahMimoOP = (withRelatedOP, queryParams = {}) => {
	return async (dispatch) => {
		dispatch({
			type: ZMLUVNY_VZTAH_LOAD_START
		})

		const query = {
			...queryParams
		}
		try {
			const zmluvneVztahyResponse = await getReq('/api/v2/zv', query)
			const zmluvneVztahy = get(zmluvneVztahyResponse, 'response.content', [])

			const zmluvnyVztahDetail = head(orderBy(zmluvneVztahy, [isZmluvnyVztahAktivny, orderZmluvneVztahy], ['desc', 'desc']))

			let op = null
			if (withRelatedOP) {
				const opCislo = get(zmluvnyVztahDetail, 'opCislo', null)

				if (!isEmpty(opCislo)) {
					const searchOpResults = await getReq('/api/v2/op', { opCislo })
					const searchOpResultsData = get(searchOpResults, 'response.content', [])
					op = head(searchOpResultsData)
				}
			}

			const zmluvnyVztah = {
				...zmluvnyVztahDetail,
				op
			}

			dispatch({
				type: ZMLUVNY_VZTAH_LOAD_DONE,
				payload: {
					zmluvnyVztah
				}
			})

			return { ...zmluvnyVztah, zmluvneVztahy: zmluvneVztahyResponse }
		} catch (e) {
			dispatch({
				type: ZMLUVNY_VZTAH_LOAD_FAIL
			})
		}
	}
}

export const loadDocuments = (zvCislo, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: ZMLUVNY_VZTAH_DOCUMENTS_LOAD_START,
			payload: {
				documents: {
					[zvCislo]: {
						...initStatePartDocuments,
						isLoading: true
					}
				}
			}
		})

		const query = {
			...queryParams
		}
		try {
			const documentsData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/zmluvne-vztahy/${zvCislo}/dokumenty`, query)
			const documents = get(documentsData, 'response.content', [])

			dispatch({
				type: ZMLUVNY_VZTAH_DOCUMENTS_LOAD_DONE,
				payload: {
					documents: {
						[zvCislo]: {
							...initStatePartDocuments,
							data: documents,
							isLoading: false
						}
					}
				}
			})

			return documents
		} catch (e) {
			dispatch({
				type: ZMLUVNY_VZTAH_DOCUMENTS_LOAD_FAIL,
				payload: {
					documents: {
						[zvCislo]: {
							...initStatePartDocuments,
							isFailure: true
						}
					}
				}
			})
		}
	}
}

export const clearZmluvnyVztah = (zvCislo) => {
	return async (dispatch) => {
		dispatch({
			type: ZMLUVNY_VZTAH_DETAIL_CLEAR
		})
		if (zvCislo) {
			dispatch({
				type: ZMLUVNY_VZTAH_LIST_CLEAR,
				payload: {
					zmluvnyVztahList: {
						[zvCislo]: undefined
					}
				}
			})
		}
	}
}

export const clearDocuments = (zvCislo) => {
	return async (dispatch) => {
		dispatch({
			type: ZMLUVNY_VZTAH_DOCUMENTS_LIST_CLEAR,
			payload: {
				documents: {
					[zvCislo]: undefined
				}
			}
		})
	}
}

export const clearZmluvneVztahy = () => {
	return async (dispatch) => {
		dispatch({
			type: ZMLUVNE_VZTAHY_CLEAR
		})
	}
}
