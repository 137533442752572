import React from 'react'
import { Tooltip } from 'react-tippy'
import { get, isEmpty, isEqual } from 'lodash'
import { getFormInitialValues, getFormSyncErrors, isValid, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Button from '../../../atoms/Button'
import DokumentyField from '../../GenericUkon/fields/DokumentyField'

import { FORMS } from '../../../utils/enums'
import { getNameOrCompany, renderZmluvneVztahy } from '../../../utils/prilezitosti'

function UpravaPrilezitostiConfirm(props) {
	const { title, formValues, onSubmit, originalValues, onCancelClick, onBackClick } = props
	const { t } = useTranslation()

	const isFormValid = useSelector((state) => isValid(FORMS.PRILEZITOSTI_UPRAVA)(state))
	const formErrors = useSelector((state) => getFormSyncErrors(FORMS.PRILEZITOSTI_UPRAVA)(state))
	const initialFormValues = useSelector((state) => getFormInitialValues(FORMS.PRILEZITOSTI_UPRAVA)(state))

	const isFormUnchanged = isEqual(initialFormValues, formValues)

	return (
		<form>
			<div className='content-header clearfix'>
				<div className='pull-right'>
					<div className='lead-edit-atcion-btn-box'>
						<Button type={'cancel'} label={t('translation:Common.Zrušiť')} onClick={onCancelClick} />
						<Tooltip
							key='plan-splatok'
							html={isFormUnchanged && <p>{t('translation:Prilezitosti.Na príležitosti nedošlo k žiadnej zmene')}</p>}
							position='left'
							trigger='mouseenter'
							theme='light'
							disabled={isEmpty(formErrors) && !isFormUnchanged}
						>
							<Button
								classes={'continue-btn'}
								label={t('translation:Common.Dokončiť')}
								onClick={(e) => {
									if (isFormValid || !isFormUnchanged) {
										onSubmit()
									} else {
										e.preventDefault()
									}
								}}
								disabled={isFormUnchanged}
							/>
						</Tooltip>
					</div>
				</div>
				<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
					{t('translation:Common.Späť')}
				</button>
				<div className='header-title pull-left'>{title}</div>
			</div>
			<div className='content-wrapper'>
				<div className='box'>
					<div className='box-content'>
						<table className='content-table padded bordered' cellSpacing='0'>
							<thead>
								<tr>
									<th />
									<th>{t('translation:Common.Aktuálne hodnoty')}</th>
									<th>{t('translation:Common.Nové hodnoty')}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<strong>{t('translation:Prilezitosti.Stav')}</strong>
									</td>
									<td>{get(originalValues, 'stav.nazov', '---')}</td>
									<td>{get(formValues, 'stav.label', '---')}</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Prilezitosti.Obchodný partner')}</strong>
									</td>
									<td>
										{getNameOrCompany(
											originalValues?.opLead?.meno,
											originalValues?.opLead?.priezvisko,
											originalValues?.opLead?.obchodneMeno
										)}
									</td>
									<td>{get(formValues, 'leadOp.label', '---')}</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Prilezitosti.Agent')}</strong>
									</td>
									<td>{get(originalValues, 'riesitel', '---')}</td>
									<td>{get(formValues, 'riesitel', '---')}</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Prilezitosti.Poznámka')}</strong>
									</td>
									<td>{get(originalValues, 'poznamkaInterna', '---')}</td>
									<td>{get(formValues, 'poznamkaInterna', '---')}</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Prilezitosti.Súvisiace zmluvy')}</strong>
									</td>
									<td>{renderZmluvneVztahy(originalValues?.suvisiaceZmluvy)}</td>
									<td>
										{formValues?.suvisiaceZmluvy?.map((zmluva) => {
											return <p>{zmluva.label}</p>
										})}
									</td>
								</tr>
							</tbody>
						</table>
						<DokumentyField input={{ value: formValues?.dokumenty }} field='dokumenty' showDocumentOnConfirm />
					</div>
				</div>
			</div>
		</form>
	)
}

UpravaPrilezitostiConfirm.propTypes = {
	title: PropTypes.string.isRequired,
	onCancelClick: PropTypes.func.isRequired,
	onBackClick: PropTypes.func.isRequired,
	originalValues: PropTypes.any.isRequired,
	formValues: PropTypes.any.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default reduxForm({
	form: FORMS.PRILEZITOSTI_UPRAVA,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(UpravaPrilezitostiConfirm)
