import PropTypes from 'prop-types'
import cx from 'classnames'

function Button(props) {
	const { type = 'primary', size, label, prefixIcon, suffixIcon, onClick, classes, disabled, children } = props

	return (
		<button
			className={cx(
				'custom-button',
				{ primary: type === 'primary' },
				{ secondary: type === 'secondary' },
				{ cancel: type === 'cancel' },
				{ line: type === 'line' },
				{ small: size === 'small' },
				classes
			)}
			type='button'
			onClick={onClick}
			disabled={disabled}
		>
			{prefixIcon && <div className={cx('icon', { right: label })}>{prefixIcon}</div>}
			{label}
			{suffixIcon && <div className={cx('icon', { left: label })}>{suffixIcon}</div>}
			{children}
		</button>
	)
}

Button.propTypes = {
	type: PropTypes.oneOf(['primary', 'secondary', 'cancel', 'line']),
	size: PropTypes.oneOf(['small']),
	label: PropTypes.string,
	prefixIcon: PropTypes.element,
	suffixIcon: PropTypes.element,
	onClick: PropTypes.func,
	classes: PropTypes.string,
	disabled: PropTypes.bool,
	children: PropTypes.element
}

export default Button
