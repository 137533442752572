import { get } from 'lodash'
import { ENV_LOAD_START, ENV_LOAD_DONE, ENV_LOAD_FAIL, PRILEZITOSTI_RESET_COLUMNS_SETTING, PRILEZITOSTI_UPDATE_COLUMNS_SETTING } from '../types/settings'

import { ENVIRONMENT, PRILEZITOSTI_KEYS } from '../utils/enums'

const DEFAULT_LEAD_COLUMNS = [
	PRILEZITOSTI_KEYS.STAV,
	PRILEZITOSTI_KEYS.ZDROJ,
	PRILEZITOSTI_KEYS.KANAL,
	PRILEZITOSTI_KEYS.PRODUKT,
	PRILEZITOSTI_KEYS.PRODUKTOVA_RODINA,
	PRILEZITOSTI_KEYS.KOMODITA,
	PRILEZITOSTI_KEYS.AGENT,
	PRILEZITOSTI_KEYS.LEAD,
	PRILEZITOSTI_KEYS.CISLO_OP,
	PRILEZITOSTI_KEYS.MENO_OP,
	PRILEZITOSTI_KEYS.PRIEZVISKO_OP,
	PRILEZITOSTI_KEYS.OBCHODNY_NAZOV,
	PRILEZITOSTI_KEYS.TELEFON,
	PRILEZITOSTI_KEYS.EMAIL,
	PRILEZITOSTI_KEYS.VYTVORENY_OD,
	PRILEZITOSTI_KEYS.POZNAMKA
]

export const initState = {
	settings: {
		env: ENVIRONMENT.TEST,
		isFailure: false,
		isLoading: false,
		isLoaded: false
	},
	leadSettings: {
		columns: DEFAULT_LEAD_COLUMNS
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case ENV_LOAD_START:
			return {
				...state,
				settings: {
					...state.settings,
					isLoading: true,
					isFailure: false
				}
			}
		case ENV_LOAD_DONE:
			return {
				...state,
				settings: {
					...state.settings,
					env: get(action, 'payload.env'),
					isLoading: false,
					isFailure: false,
					isLoaded: true
				}
			}
		case ENV_LOAD_FAIL:
			return {
				...state,
				settings: {
					...initState.settings,
					isLoading: false,
					isFailure: true
				}
			}
		case PRILEZITOSTI_UPDATE_COLUMNS_SETTING:
			return {
				...state,
				leadSettings: {
					columns: get(action, 'payload.columns')
				}
			}
		case PRILEZITOSTI_RESET_COLUMNS_SETTING:
			return {
				...state,
				leadSettings: {
					...initState.settings
				}
			}
		default:
			return state
	}
}
