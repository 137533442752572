import React from 'react'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { get, upperFirst } from 'lodash'

// atoms
import OptionsPickerField from '../../../atoms/OptionsPickerField'

// config
import { EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class KonkurentField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		typyEvidencie: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired,
		moznosti: PropTypes.array.isRequired
	}

	constructor(props) {
		super(props)
	}

	getOptions(options) {
		return options?.map((option) => {
			return {
				id: option?.id,
				originalValue: option,
				label: option?.nazov
			}
		})
	}

	parseValue(value) {
		return value.originalValue
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Konkurent je povinný')
		}
	}

	render() {
		const { field, editMode, t, value, moznosti } = this.props

		const options = this.getOptions(moznosti)

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('components:GenericFields.Konkurent')}</strong>
							</td>
							<td />
							<td>
								{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) && (
									<Field
										name={field}
										component={OptionsPickerField}
										selectedOptionId={value?.id}
										options={options}
										parse={this.parseValue}
										validate={this.validate}
									/>
								)}
								{(editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) && upperFirst(get(value, 'nazov'))}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}
export default compose(withTranslation('components'))(KonkurentField)
