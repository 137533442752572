import { persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import storageLocal from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'
import { reducer as formReducer } from 'redux-form'

import authReducer from './authReducer'
import obchodnyPartnerReducer from './obchodnyPartnerReducer'
import searchReducer from './searchReducer'
import interakcieReducer from './interakcieReducer'
import fakturyReducer from './fakturyReducer'
import statusReducer from './statusReducer'
import podpisovanieDokumentovReducer from './podpisovanieDokumentov'
import ciselniky from './ciselniky'
import ukonyReduces from './ukonyReducer'
import widgetyReducer from './widgetyReducer'
import trackingReducer from './trackingReducer'
import selectedFiltersReducer from './selectedFiltersReducer'
import formAddressesReducer from './formAddresses'
import ciselnikySelectBox from './ciselnikySelectBox'
import dataReducer from './dataReducer'
import addressReducer from './addressReducer'
import planySplatokReducer from './planySplatokReducer'
import otvoreneFakturyReducer from './otvoreneFakturyReducer'
import preplatkyReducer from './preplatkyReducer'
import blokovanieUpominaniaReducer from './blokovanieUpominaniaReducer'
import settingsReducer from './settingsReducer'
import platbyReducer from './platbyReducer'
import platobnePrikazyReducer from './platobnePrikazyReducer'
import miestaSpotrebyReducer from './miestaSpotrebyReducer'
import odberneMiestaReducer from './odberneMiestaReducer'
import zmluvneVztahyReducer from './zmluvneVztahyReducer'
import zmluvneUctyReducer from './zmluvneUctyReducer'
import zostatokZmluvneUctyReducer from './zostatokZmluvneUctyReducer'
import zmluvneVztahyConfigReducer from './zmluvneVztahyConfigReducer'
import ponukaReducer from './ponukaReducer'
import ukonEditOpReducer from './ukonEditOpReducer'
import ukonEditZuReducer from './ukonEditZuReducer'
import procesnyKonfiguratorReducer from './procesnyKonfiguratorReducer'
import ukonPlanSplatokPKContextReducer from './ukonPlanSplatokPKContextReducer'
import ukonZmluvnyVztahPrepisReducer from './ProcesnyKonfigurator/ukonZmluvnyVztahPrepisReducer'
import ukonZmluvnyVztahNovyReducer from './ProcesnyKonfigurator/ukonZmluvnyVztahNovyReducer'
import ukonGenericReducer from './ProcesnyKonfigurator/ukonGenericReducer'
import cesCiselniky from './cesCiselniky'
import externalIntegrationsReducer from './externalIntegrations'
import prilezitostiReducer from './prilezitostiReducer'
import { EXTERNAL_INTEGRATIONS } from '../utils/enums'

export const REDUCER_KEYS = {
	AUTH: 'auth',
	FORM: 'form',
	SEARCH: 'search',
	OBCHODNY_PARTNER: 'obchodnyPartner',
	FAKTURY: 'faktury',
	INTERAKCIE: 'interakcie',
	STATUSES: 'statuses',
	PODPISOVANIE_DOKUMENTOV: 'podpisovanieDokumentov',
	CISELNIKY: 'ciselniky',
	CES_CISELNIKY: 'cesCisleniky',
	CISELNIKY_SELECT_BOX: 'ciselnikySelectBox',
	UKONY: 'ukony',
	WIDGETY: 'widgety',
	TRACKING: 'tracking',
	SELECTED_FILTERS: 'selectedFilters',
	FORM_ADDRESSES: 'formAddresses',
	DATA: 'data',
	ADDRESS: 'address',
	PLANY_SPLATOK: 'planySplatok',
	OTVORENE_FAKTURY: 'otvoreneFaktury',
	PREPLATKY: 'preplatky',
	BLOKOVANIE_UPOMINANIA: 'blokovanieUpominania',
	SETTINGS: 'settings',
	PLATBY: 'platby',
	PLATOBNE_PRIKAZY: 'platobnePrikazy',
	MIESTA_SPOTREBY: 'miestaSpotreby',
	ODBERNE_MIESTA: 'odberneMiesta',
	OBCHODNI_PARTNERI: 'obchodniPartneri',
	ZMLUVNE_VZTAHY: 'zmluvneVztahy',
	ZMLUVNE_UCTY: 'zmluvneUcty',
	ZOSTATOK_ZMLUVNE_UCTY: 'zostatokZmluvneUcty',
	ZMLUVNE_VZTAHY_CONFIG: 'zmluvneVztahyConfig',
	PONUKA: 'ponuka',
	UKON_EDIT_OP: 'ukonEditOp',
	UKON_EDIT_ZU: 'ukonEditZU',
	PROCESNY_KONFIGURATOR: 'procesnyKonfigurator',
	UKON_PLAN_SPLATOK_PK_CONTEXT: 'ukonPlanSplatokPKContext',
	PK_ZMLUVNY_VZTAH_PREPIS_CONTEXT: 'pkZmluvnyVztahPrepisContext',
	PK_ZMLUVNY_VZTAH_NOVY_CONTEXT: 'pkZmluvnyVztahNovyContext',
	PK_UKON_GENERIC_CONTEXT: 'pkUkonGenericContext',
	EXTERNAL_INTEGRATIONS: 'externalIntegrations',
	PRILEZITOSTI: 'prilezitosti'
}

// NOTE: We need to ensure that individual CARE sessions do not share session data with each other due to app usage in IFrames -> CP-3322
const isInIFrame = window.self !== window.top

const rootReducer = combineReducers({
	auth: persistReducer(
		{
			key: REDUCER_KEYS.AUTH,
			storage: storageLocal
		},
		authReducer
	),
	ciselniky: persistReducer(
		{
			key: REDUCER_KEYS.CISELNIKY,
			storage: storageLocal
		},
		ciselniky
	),
	cesCiselniky: persistReducer(
		{
			key: REDUCER_KEYS.CES_CISELNIKY,
			storage: storageLocal
		},
		cesCiselniky
	),
	form: persistReducer(
		{
			key: REDUCER_KEYS.FORM,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		formReducer
	),
	search: persistReducer(
		{
			key: REDUCER_KEYS.SEARCH,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		searchReducer
	),
	obchodnyPartner: persistReducer(
		{
			key: REDUCER_KEYS.OBCHODNY_PARTNER,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		obchodnyPartnerReducer
	),
	faktury: persistReducer(
		{
			key: REDUCER_KEYS.FAKTURY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		fakturyReducer
	),
	interakcie: persistReducer(
		{
			key: REDUCER_KEYS.INTERAKCIE,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		interakcieReducer
	),
	statuses: persistReducer(
		{
			key: REDUCER_KEYS.STATUSES,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		statusReducer
	),
	podpisovanieDokumentov: persistReducer(
		{
			key: REDUCER_KEYS.PODPISOVANIE_DOKUMENTOV,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		podpisovanieDokumentovReducer
	),
	procesnyKonfigurator: persistReducer(
		{
			key: REDUCER_KEYS.PROCESNY_KONFIGURATOR,
			storage: storageLocal,
			...(isInIFrame && { whitelist: [] })
		},
		procesnyKonfiguratorReducer
	),
	ciselnikySelectBox: persistReducer(
		{
			key: REDUCER_KEYS.CISELNIKY_SELECT_BOX,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		ciselnikySelectBox
	),
	ukony: persistReducer(
		{
			key: REDUCER_KEYS.UKONY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		ukonyReduces
	),
	widgety: persistReducer(
		{
			key: REDUCER_KEYS.WIDGETY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		widgetyReducer
	),
	tracking: persistReducer(
		{
			key: REDUCER_KEYS.TRACKING,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		trackingReducer
	),
	selectedFilters: persistReducer(
		{
			key: REDUCER_KEYS.SELECTED_FILTERS,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		selectedFiltersReducer
	),
	formAddresses: persistReducer(
		{
			key: REDUCER_KEYS.FORM_ADDRESSES,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		formAddressesReducer
	),
	data: persistReducer(
		{
			key: REDUCER_KEYS.DATA,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		dataReducer
	),
	address: persistReducer(
		{
			key: REDUCER_KEYS.ADDRESS,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		addressReducer
	),

	[REDUCER_KEYS.PLANY_SPLATOK]: persistReducer(
		{
			key: REDUCER_KEYS.PLANY_SPLATOK,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		planySplatokReducer
	),
	[REDUCER_KEYS.OTVORENE_FAKTURY]: persistReducer(
		{
			key: REDUCER_KEYS.OTVORENE_FAKTURY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		otvoreneFakturyReducer
	),
	[REDUCER_KEYS.UKON_PLAN_SPLATOK_PK_CONTEXT]: persistReducer(
		{
			key: REDUCER_KEYS.UKON_PLAN_SPLATOK_PK_CONTEXT,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		ukonPlanSplatokPKContextReducer
	),
	[REDUCER_KEYS.PREPLATKY]: persistReducer(
		{
			key: REDUCER_KEYS.PREPLATKY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		preplatkyReducer
	),
	[REDUCER_KEYS.BLOKOVANIE_UPOMINANIA]: persistReducer(
		{
			key: REDUCER_KEYS.BLOKOVANIE_UPOMINANIA,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		blokovanieUpominaniaReducer
	),
	[REDUCER_KEYS.SETTINGS]: persistReducer(
		{
			key: REDUCER_KEYS.SETTINGS,
			storage: storageLocal,
			...(isInIFrame && { whitelist: [] })
		},
		settingsReducer
	),
	[REDUCER_KEYS.PLATBY]: persistReducer(
		{
			key: REDUCER_KEYS.PLATBY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		platbyReducer
	),
	[REDUCER_KEYS.PLATOBNE_PRIKAZY]: persistReducer(
		{
			key: REDUCER_KEYS.PLATOBNE_PRIKAZY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		platobnePrikazyReducer
	),
	[REDUCER_KEYS.MIESTA_SPOTREBY]: persistReducer(
		{
			key: REDUCER_KEYS.MIESTA_SPOTREBY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		miestaSpotrebyReducer
	),
	[REDUCER_KEYS.ZMLUVNE_VZTAHY]: persistReducer(
		{
			key: REDUCER_KEYS.ZMLUVNE_VZTAHY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		zmluvneVztahyReducer
	),
	[REDUCER_KEYS.ODBERNE_MIESTA]: persistReducer(
		{
			key: REDUCER_KEYS.ODBERNE_MIESTA,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		odberneMiestaReducer
	),
	[REDUCER_KEYS.ZMLUVNE_UCTY]: persistReducer(
		{
			key: REDUCER_KEYS.ZMLUVNE_UCTY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		zmluvneUctyReducer
	),
	[REDUCER_KEYS.ZOSTATOK_ZMLUVNE_UCTY]: persistReducer(
		{
			key: REDUCER_KEYS.ZOSTATOK_ZMLUVNE_UCTY,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		zostatokZmluvneUctyReducer
	),
	[REDUCER_KEYS.ZMLUVNE_VZTAHY_CONFIG]: persistReducer(
		{
			key: REDUCER_KEYS.ZMLUVNE_VZTAHY_CONFIG,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		zmluvneVztahyConfigReducer
	),
	[REDUCER_KEYS.UKON_EDIT_OP]: persistReducer(
		{
			key: REDUCER_KEYS.UKON_EDIT_OP,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		ukonEditOpReducer
	),
	[REDUCER_KEYS.UKON_EDIT_ZU]: persistReducer(
		{
			key: REDUCER_KEYS.UKON_EDIT_ZU,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		ukonEditZuReducer
	),
	[REDUCER_KEYS.PK_ZMLUVNY_VZTAH_PREPIS_CONTEXT]: persistReducer(
		{
			key: REDUCER_KEYS.PK_ZMLUVNY_VZTAH_PREPIS_CONTEXT,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		ukonZmluvnyVztahPrepisReducer
	),
	[REDUCER_KEYS.PK_ZMLUVNY_VZTAH_NOVY_CONTEXT]: persistReducer(
		{
			key: REDUCER_KEYS.PK_ZMLUVNY_VZTAH_NOVY_CONTEXT,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		ukonZmluvnyVztahNovyReducer
	),
	[REDUCER_KEYS.PONUKA]: persistReducer(
		{
			key: REDUCER_KEYS.PONUKA,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		ponukaReducer
	),
	[REDUCER_KEYS.PK_UKON_GENERIC_CONTEXT]: persistReducer(
		{
			key: REDUCER_KEYS.PK_UKON_GENERIC_CONTEXT,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		ukonGenericReducer
	),
	[REDUCER_KEYS.PRILEZITOSTI]: persistReducer(
		{
			key: REDUCER_KEYS.PRILEZITOSTI,
			storage: storageSession,
			...(isInIFrame && { whitelist: [] })
		},
		prilezitostiReducer
	),
	[REDUCER_KEYS.EXTERNAL_INTEGRATIONS]: persistReducer(
		{
			key: REDUCER_KEYS.EXTERNAL_INTEGRATIONS,
			storage: storageSession,
			// CP-3322 - FinesseId, ExternalSessionId a Source neukladame do session storage, pretoze sa otvara v ramci iframe, kde sa potom prepisovali data
			blacklist: ['finesseId', EXTERNAL_INTEGRATIONS.EXTERNAL_SESSION_ID, EXTERNAL_INTEGRATIONS.SOURCE_QUERY]
		},
		externalIntegrationsReducer
	)
})

export default rootReducer
