import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { map, isEmpty, debounce, get } from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import ReactQueryParams from 'react-query-params'

// layouts
import Header from './Layout/Header'

// components
import SearchResultRow from '../components/TableRows/SearchResultTableRow'
import SearchResultInteractionTableRow from '../components/TableRows/SearchResultInteractionTableRow'
import ElementLoading from '../components/ElementLoading'
import ZoznamPrilezitosti from '../components/Prilezitosti/ZoznamPrilezitosti/ZoznamPrilezitosti'

// actions
import * as SearchActions from '../actions/SearchActions'
import * as StatusActions from '../actions/StatusActions'
import * as PonukaActions from '../actions/PonukaActions'
import InterakcieActions from '../actions/Interakcie'
import ObchoniPartneriActions from '../actions/ObchodniPartneri'

// utils
import { history } from '../utils/history'
import * as RoutesDef from '../utils/routes'
import Permissions, { PERMISSIONS } from '../utils/permissionsHoc'
import { EXTERNAL_INTEGRATIONS, UKONY_CISELNIK } from '../utils/enums'

// resources
import SearchIcon from '../resources/img/icons/search.svg'

class SearchPage extends ReactQueryParams {
	static propTypes = {
		search: PropTypes.shape({
			isLoading: PropTypes.bool.isRequired,
			obchodniPartneri: PropTypes.array.isRequired,
			strankovanie: PropTypes.object.isRequired,
			isReady: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired,
			status: PropTypes.array
		}).isRequired,
		searchAction: PropTypes.shape({
			searchUsers: PropTypes.func.isRequired
		}).isRequired,
		obchodniPartneriAction: PropTypes.shape({
			loadObchodnyPartnerDetail: PropTypes.func.isRequired
		}).isRequired,
		interakcieAction: PropTypes.shape({
			loadDnesnaHistoriaInterakcii: PropTypes.func.isRequired,
			zaciatokInterakcie: PropTypes.func.isRequired
		}).isRequired,
		procesnyKonfiguratorAction: PropTypes.shape({
			loadProcesnyKonfigurator: PropTypes.func.isRequired
		}),
		interakcie: PropTypes.shape({
			data: PropTypes.array,
			isLoading: PropTypes.bool.isRequired,
			page: PropTypes.number,
			isFailure: PropTypes.bool.isRequired
		}).isRequired,
		statuses: PropTypes.array,
		statusAction: PropTypes.shape({
			statusPop: PropTypes.func
		}).isRequired,
		ponukaActions: PropTypes.shape({
			loadPonukaSearchPage: PropTypes.func.isRequired
		}).isRequired,
		ponuka: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		auth: PropTypes.shape()
	}

	constructor(props) {
		super(props)
		this.state = {
			searchSlug: ''
		}
	}

	onChangeSlug = (e) => {
		this.setState({
			searchSlug: e.target.value
		})
	}

	startSearch = () => {
		// prevent to dispach search user action if search string is empty
		if (!isEmpty(this.state.searchSlug)) {
			// clean previous error messages
			if (this.props.statuses.length > 0) {
				this.props.statusAction.statusPop(this.props.statuses.length)
			}
			this.props.searchAction.searchUsers(this.state.searchSlug)
		}
	}

	startSearchHistoryInteraction = (searchValue) => {
		// fetch new history of interacions filtered by search values
		// TODO: caka sa na zadefinovanie nazvu pre query param urceneho na vyhladavanie
		this.props.interakcieAction.loadDnesnaHistoriaInterakcii(1, null, { search: searchValue })
	}

	startSearchHistoryInteractionDebounce = debounce(this.startSearchHistoryInteraction, 300)

	selectUser = (opCislo) => {
		this.props.interakcieAction.zaciatokInterakcie(opCislo)
		// TODO: treba doriesit co ak zlyha interakcia
		history.push(RoutesDef.setRouteParams(RoutesDef.PREHLAD, opCislo))
	}

	loadMore = () => {
		const nextPage = this.props.interakcie.page + 1
		this.props.interakcieAction.loadDnesnaHistoriaInterakcii(nextPage)
	}

	handleKeyDown = (e) => {
		// start searching on key press Enter
		if (e.keyCode === 13) {
			e.target.blur() // call blur cause CARE-790
			this.startSearch()
		}
	}

	async componentDidMount() {
		const { auth, ponukaActions } = this.props

		const activeBusinessChannel = get(auth, 'businessChannel.actual.id')

		this.props.interakcieAction.loadDnesnaHistoriaInterakcii()

		ponukaActions.loadPonukaSearchPage({
			kanalId: activeBusinessChannel
		})

		// CP-3253
		const phoneQueryValue = this.queryParams[EXTERNAL_INTEGRATIONS.KONTAKTNY_TELEFON_QUERY]
		if (phoneQueryValue) {
			const phoneNumberRegex = /^\+?(\d{6,12})$/
			if (phoneNumberRegex.test(phoneQueryValue) === false) {
				this.setQueryParams({
					[EXTERNAL_INTEGRATIONS.KONTAKTNY_TELEFON_QUERY]: undefined
				})
				return
			}

			this.setState({ searchSlug: phoneQueryValue })
			this.props.searchAction.searchUsers(phoneQueryValue)
		}
	}

	render() {
		// TODO: ponuka sa zatial nevyuziva na render buttonov na stránke vyhladavania OP. Viac info v CP-2986
		const { t, ponuka } = this.props
		const searchResult = this.formatSearchResult()

		const anonymnyUkon = ponuka?.data?.find((ponukaItem) => {
			return ponukaItem?.ukon?.typ?.id === UKONY_CISELNIK.ANONYMNY_VSEOBECNY_UKON
		})

		return this.commonContentContainer(
			<>
				<div className='search-results-container'>
					<div className='row' style={{ marginTop: '80px', marginBottom: '20px' }}>
						<div className='col-12'>
							<div className='search-wrapper'>
								<input
									type='text'
									autoComplete='false'
									value={this.state.searchSlug}
									onChange={this.onChangeSlug}
									onKeyDown={this.handleKeyDown}
									placeholder={t('containers:SearchPage.Hľadať podľa mena, adresy alebo čísla OP')}
								/>
								<div
									className='button circle search-button'
									data-color='blue'
									style={{ backgroundImage: `url(${SearchIcon})` }}
									onClick={() => this.startSearch()}
								/>
							</div>
						</div>
						<div className='col-12'>
							<div className='clearfix search-buttons'>
								<Permissions
									allowed={[PERMISSIONS.UKON_ZALOZENIE_OP]}
									render={(hasPerm, actions) => {
										if (hasPerm) {
											return (
												<Link
													to={RoutesDef.ZALOZENIE_OP}
													className='button subtle uppercase pull-right'
													type='button'
													data-color='blue'
												>
													{t('translation:Common.Pridať nového OP')}
												</Link>
											)
										}

										return (
											<Link
												to={RoutesDef.ZALOZENIE_OP}
												className='button subtle uppercase pull-right'
												type='button'
												data-color='silver'
												onClick={(e) => {
													e.preventDefault()
													actions.openForbiddenModal()
												}}
											>
												<Tooltip
													html={<span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
													position='left'
													trigger='mouseenter'
													theme='light'
												>
													{t('translation:Common.Pridať nového OP')}
												</Tooltip>
											</Link>
										)
									}}
								/>
								{/* TODO: Pridať button z ponuky a ošetriť správne permissions */}
								<Permissions
									allowed={[PERMISSIONS.UKON_ANONYM]}
									render={(hasPerm, actions) => {
										if (hasPerm) {
											return (
												<Link
													to={{ pathname: RoutesDef.ANONYMNY_VSEOBECNY_UKON, anonymnyUkon }}
													className='button subtle uppercase pull-right'
													type='button'
													data-color='blue'
												>
													{t('translation:Common.Anonymný všeobecný úkon')}
												</Link>
											)
										}

										return (
											<Link
												to={RoutesDef.ANONYMNY_VSEOBECNY_UKON}
												className='button subtle uppercase pull-right'
												type='button'
												data-color='silver'
												onClick={(e) => {
													e.preventDefault()
													actions.openForbiddenModal()
												}}
											>
												<Tooltip
													html={<span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
													position='left'
													trigger='mouseenter'
													theme='light'
												>
													{t('translation:Common.Anonymný všeobecný úkon')}
												</Tooltip>
											</Link>
										)
									}}
								/>
							</div>
						</div>
					</div>
					{searchResult}
					{this.interactionHistory()}
				</div>
				<Permissions allowed={[PERMISSIONS.VIEW_LEADS]}>
					<div style={{ marginTop: '60px', marginBottom: '20px', marginLeft: '40px', marginRight: '40px' }}>
						<ZoznamPrilezitosti />
					</div>
				</Permissions>
			</>
		)
	}

	interactionHistory = () => {
		const { interakcie, t } = this.props
		const elementLoading = interakcie.isLoading && interakcie.page === 1 ? <ElementLoading /> : ''
		const interakcieList = map(interakcie.data, (interakcia) => (
			<SearchResultInteractionTableRow
				key={`interakcia-${interakcia.id}`}
				interakcia={interakcia}
				onClick={() => get(interakcia, 'opCislo') && this.selectUser(get(interakcia, 'opCislo'))}
			/>
		))

		const text = interakcie.isLoading && interakcie.page > 1 ? t('containers:Nacitavam') : t('containers:Zobraziť viac')
		let lodMoreBtn = <p />
		if (!interakcie.isLastPage) {
			lodMoreBtn = (
				<div className='button toggle-button' data-color='blue' style={{ margin: '20px 0' }} onClick={this.loadMore}>
					{text}
				</div>
			)
		}

		return (
			<>
				<h3 className='clearfix' style={{ marginTop: '60px', marginBottom: '20px' }}>
					<span className='pull-left'>{t('containers:SearchPage.Dnešná história interakcií')}</span>
				</h3>
				<div className='table-wrapper'>
					{elementLoading}
					<table className='search-results-table no-wrap' cellSpacing='0'>
						<thead>
							<tr>
								<th style={{ width: '20%' }}>{t('containers:SearchPage.Meno')}</th>
								<th style={{ width: '15%' }}>{t('containers:SearchPage.Čas interakcie')}</th>
								<th style={{ width: '40%' }}>{t('containers:SearchPage.Adresa')}</th>
								<th style={{ width: '25%' }}>{t('containers:SearchPage.Úkony')}</th>
							</tr>
						</thead>
						<tbody
							data-empty={t('containers:SearchPage.Neboli nájdené žiadne výsledky pre hľadaný výraz')}
							data-error={t('containers:SearchPage.Pri načítavaní dát sa vyskytla neočakávaná chyba')}
						>
							{interakcieList}
						</tbody>
					</table>
				</div>
				<div style={{ textAlign: 'center' }}>{lodMoreBtn}</div>
			</>
		)
	}

	commonContentContainer = (content) => {
		return (
			<>
				<Header />
				{content}
			</>
		)
	}

	formatSearchResult = () => {
		const { t, search } = this.props
		const { obchodniPartneri, isLoading, isFailure, isReady } = search

		if (!isReady) {
			return null
		}
		if (isLoading) {
			return (
				<div>
					<ElementLoading />
				</div>
			)
		}
		if (isFailure) {
			return (
				<div className='alert' data-color='red'>
					{t('containers:SearchPage.Pri vyhľadávaní obchodných partnerov nastala chyba')}
				</div>
			)
		}

		const results = map(obchodniPartneri, (obchodnyPartner, index) => {
			return <SearchResultRow key={`res${index}`} {...obchodnyPartner} onClick={() => this.selectUser(obchodnyPartner.cislo)} showMOP />
		})

		return (
			<div>
				<h3 style={{ marginTop: '60px' }}>{t('containers:SearchPage.Výsledky hľadania')}</h3>
				<table className='search-results-table no-wrap' cellSpacing='0'>
					<thead>
						<tr>
							<th className='partner-number'>{t('containers:SearchPage.Číslo OP')}</th>
							<th className='name'>{t('containers:SearchPage.Meno')}</th>
							<th>{t('containers:SearchPage.Adresa')}</th>
							<th className='date-of-birth'>{t('containers:SearchPage.Dátum narodenia')}</th>
							<th className='ico'>{t('containers:SearchPage.IČO')}</th>
							<th className='phone-column'>{t('containers:SearchPage.Telefón')}</th>
							<th>{t('containers:SearchPage.E-mail')}</th>
							<th className='segment'>{t('containers:SearchPage.Segment')}</th>
						</tr>
					</thead>
					<tbody
						data-empty={t(
							'containers:SearchPage.Nenašli sa žiadne výsledky Použite všeobecnejšie parametre vyhľadávania Napr kombináciu meno, priezvisko, adresa, dátum narodenia alebo email'
						)}
					>
						{results}
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	search: state.search,
	interakcie: state.interakcie.index,
	obchodnyPartner: state.obchodnyPartner.detail,
	statuses: state.statuses.statuses,
	auth: state.auth,
	ponuka: state.ponuka.searchPage
})

const mapDispatchToProps = (dispatch) => ({
	searchAction: bindActionCreators(SearchActions, dispatch),
	interakcieAction: bindActionCreators(InterakcieActions, dispatch),
	obchodniPartneriAction: bindActionCreators(ObchoniPartneriActions, dispatch),
	statusAction: bindActionCreators(StatusActions, dispatch),
	ponukaActions: bindActionCreators(PonukaActions, dispatch)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps))(SearchPage)
