import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as CloseIcon } from '../../resources/img/icons/new-close.svg'

const modalRoot = document.getElementById('modal-root')

function PrilezitostiModal(props) {
	const { isOpen, title, size = 'm', handleOutsideClick, body, children, onCloseButton, footer, modalBodyClasses, destroyOnClose } = props
	const elRef = useRef(document.createElement('div'))

	useEffect(() => {
		const el = elRef.current
		modalRoot.appendChild(el)

		const handleClick = (e) => {
			if (el.childNodes[0] === e.target && handleOutsideClick) {
				handleOutsideClick()
			}
		}

		el.addEventListener('click', handleClick, false)

		return () => {
			modalRoot.removeChild(el)
			el.removeEventListener('click', handleClick, false)
		}
	}, [handleOutsideClick])

	const element = isOpen && (
		<div className={cx('modal', 'dismissible', { shown: !!isOpen })} data-size={size}>
			<div className='modal-wrapper custom-modal-wrapper'>
				{children || (
					<>
						<div className='modal-header custom-modal-header'>
							{title}
							<div className='close-btn' onClick={onCloseButton}>
								<CloseIcon />
							</div>
						</div>
						<div className={cx('modal-content', 'custom-modal-content', modalBodyClasses)}>{body}</div>
						<div className='modal-footer custom-modal-footer clearfix'>{footer}</div>
					</>
				)}
			</div>
		</div>
	)

	return ReactDOM.createPortal(element, elRef.current)
}

PrilezitostiModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	title: PropTypes.element.isRequired,
	onCloseButton: PropTypes.func.isRequired,
	size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
	handleOutsideClick: PropTypes.func,
	footer: PropTypes.element,
	body: PropTypes.element,
	children: PropTypes.element,
	modalBodyClasses: PropTypes.string,
	destroyOnClose: PropTypes.bool
}

export default PrilezitostiModal
