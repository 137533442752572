import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate } from '../../../utils/date'
import { COLUMNS_COUNT } from '../../../containers/GenericUkon/genericUkonConfig'

function UkoncenieZmluvyDatumReadOnlyField(props) {
	const { originalValue, value, columnsCount = COLUMNS_COUNT.THREE } = props
	const { t } = useTranslation()

	return (
		<div className='inner-box'>
			<table className='content-table padded bordered' cellSpacing='0'>
				<tbody>
					<tr>
						<td>
							<strong>{t('translation:Common.Dátum ukončenia')}</strong>
						</td>
						{columnsCount === COLUMNS_COUNT.THREE && <td>{formatDate(originalValue, null, 'DD.MM.YYYY')}</td>}
						<td>{formatDate(value, null, 'DD.MM.YYYY')}</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}

UkoncenieZmluvyDatumReadOnlyField.propTypes = {
	value: PropTypes.shape().isRequired,
	originalValue: PropTypes.shape(),
	columnsCount: PropTypes.number
}

export default UkoncenieZmluvyDatumReadOnlyField
