import { every, get, includes } from 'lodash'

import Qs from 'qs'
import { getReq } from '../utils/request'

import {
	OP_PRILEZITOSTI_LOAD_DONE,
	OP_PRILEZITOSTI_LOAD_FAIL,
	OP_PRILEZITOSTI_LOAD_START,
	PRILEZITOST_DETAIL_LOAD_DONE,
	PRILEZITOST_DETAIL_LOAD_FAIL,
	PRILEZITOST_DETAIL_LOAD_START,
	OP_PRILEZITOSTI_MOZNOSTI_LOAD_DONE,
	OP_PRILEZITOSTI_MOZNOSTI_LOAD_FAIL,
	OP_PRILEZITOSTI_MOZNSOTI_LOAD_START,
	PRILEZITOSTI_LOAD_DONE,
	PRILEZITOSTI_LOAD_FAIL,
	PRILEZITOSTI_LOAD_START,
	PRILEZITOSTI_RESET_FILTERS,
	PRILEZITOSTI_RESET_OP_FILTERS,
	PRILEZITOSTI_SET_FILTERS,
	PRILEZITOSTI_SET_OP_FILTERS,
	PRILEZITOSTI_IDENTITA_LOAD_START,
	PRILEZITOSTI_IDENTITA_LOAD_FAIL,
	PRILEZITOSTI_IDENTITA_LOAD_DONE
} from '../types/prilezitosti'
import { PERMISSIONS } from '../utils/permissionsHoc'

// TODO - delete when permissions are processed into BB
const supervisorPermissions = [PERMISSIONS.VIEW_LEADS, PERMISSIONS.EDIT_LEADS_BASE, PERMISSIONS.EDIT_LEADS_EXTENDED, PERMISSIONS.EDIT_LEADS_OWN]

export const loadPrilezitosti = (queryParams = {}, oddelenieKod) => {
	return async (dispatch, getStore) => {
		dispatch({
			type: PRILEZITOSTI_LOAD_START
		})

		const paramsSerializer = (query) => Qs.stringify(query, { arrayFormat: 'comma', skipNulls: true })

		try {
			let query = {
				...queryParams
			}

			const store = getStore()
			const prilezitosti = get(store, 'prilezitosti.prilezitosti', [])
			const auth = get(store, 'auth', {})

			const isSupervisor = every(supervisorPermissions, (permission) => includes(auth.user.roles, permission))

			// NOTE: add the oddelenie query parameter only for permissions that are not in the supervisor role
			if (!isSupervisor) {
				query = {
					...query,
					oddelenie: oddelenieKod
				}
			}

			const { response } = await getReq('/api/v0/prilezitosti', query, null, null, { paramsSerializer })

			let data = { ...response }

			if (queryParams.page !== 0) {
				data = { ...data, content: [...prilezitosti.data, ...response.content] }
			}

			dispatch({
				type: PRILEZITOSTI_LOAD_DONE,
				payload: {
					prilezitosti: data
				}
			})

			return response
		} catch (e) {
			dispatch({
				type: PRILEZITOSTI_LOAD_FAIL
			})
		}
	}
}

export const setFilters = (filters) => {
	return (dispatch) => {
		dispatch({
			type: PRILEZITOSTI_SET_FILTERS,
			payload: {
				filters: { ...filters }
			}
		})
	}
}

export const resetFilters = () => {
	return (dispatch) => {
		dispatch({
			type: PRILEZITOSTI_RESET_FILTERS
		})
	}
}

export const loadOpPrilezitosti = (queryParams = {}, opCislo, oddelenieKod) => {
	return async (dispatch, getStore) => {
		dispatch({
			type: OP_PRILEZITOSTI_LOAD_START
		})

		const paramsSerializer = (query) => Qs.stringify(query, { arrayFormat: 'comma', skipNulls: true })

		try {
			let query = {
				...queryParams,
				opCislo
			}

			const store = getStore()
			const prilezitosti = get(store, 'prilezitosti.opPrilezitosti', [])
			const auth = get(store, 'auth', {})

			const isSupervisor = every(supervisorPermissions, (permission) => includes(auth.user.roles, permission))

			// NOTE: add the oddelenie query parameter only for permissions that are not in the supervisor role
			if (!isSupervisor) {
				query = {
					...query,
					oddelenie: oddelenieKod
				}
			}

			const { response } = await getReq('/api/v0/prilezitosti', query, null, null, { paramsSerializer })

			let data = { ...response }

			if (queryParams.page !== 0) {
				data = { ...data, content: [...prilezitosti.data, ...response.content] }
			}

			dispatch({
				type: OP_PRILEZITOSTI_LOAD_DONE,
				payload: {
					prilezitosti: data
				}
			})

			return response
		} catch (e) {
			dispatch({
				type: OP_PRILEZITOSTI_LOAD_FAIL
			})
		}
	}
}

export const setOpFilters = (filters) => {
	return (dispatch) => {
		dispatch({
			type: PRILEZITOSTI_SET_OP_FILTERS,
			payload: {
				filters: { ...filters }
			}
		})
	}
}

export const resetOpFilters = () => {
	return (dispatch) => {
		dispatch({
			type: PRILEZITOSTI_RESET_OP_FILTERS
		})
	}
}

export const loadDetailPrilezitosti = (prilezitostId) => {
	return async (dispatch) => {
		dispatch({
			type: PRILEZITOST_DETAIL_LOAD_START
		})

		try {
			const { response } = await getReq(`/api/v0/prilezitosti/${prilezitostId}`)
			const prilezitost = get(response, 'content', {})

			dispatch({
				type: PRILEZITOST_DETAIL_LOAD_DONE,
				payload: {
					prilezitost
				}
			})

			return response
		} catch (e) {
			dispatch({
				type: PRILEZITOST_DETAIL_LOAD_FAIL
			})
		}
	}
}

export const loadMoznosti = (id, queryParams) => {
	return async (dispatch) => {
		dispatch({
			type: OP_PRILEZITOSTI_MOZNSOTI_LOAD_START
		})

		const paramsSerializer = (query) => Qs.stringify(query, { arrayFormat: 'comma', skipNulls: true })

		try {
			const query = {
				...queryParams
			}

			const { response } = await getReq(`/api/v0/prilezitosti/${id}/moznosti`, query, null, null, { paramsSerializer })

			let data = { ...response?.content }

			dispatch({
				type: OP_PRILEZITOSTI_MOZNOSTI_LOAD_DONE,
				payload: {
					moznosti: data
				}
			})

			return data
		} catch (e) {
			dispatch({
				type: OP_PRILEZITOSTI_MOZNOSTI_LOAD_FAIL
			})
		}
	}
}

export const getRiesitelIndetita = (riesitel) => {
	return async (dispatch) => {
		dispatch({
			type: PRILEZITOSTI_IDENTITA_LOAD_START
		})

		try {
			const query = {
				riesitel
			}

			const { response } = await getReq(`/api/v0/prilezitosti/identita`, query)

			let data = { ...response?.content }

			dispatch({
				type: PRILEZITOSTI_IDENTITA_LOAD_DONE,
				payload: {
					identita: data
				}
			})

			return data
		} catch (e) {
			dispatch({
				type: PRILEZITOSTI_IDENTITA_LOAD_FAIL
			})
		}
	}
}
