import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import { compose } from 'redux'

import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'
import {
	setRouteParams,
	PREHLAD,
	OP_DETAIL,
	FAKTURY_ZOZNAM,
	ROZPISY_ZALOH_ZOZNAM,
	VZTAHY_ZOZNAM,
	PLANY_SPLATOK_ZOZNAM,
	UPOMIENKY_ZOZNAM,
	ZMLUVY_ZOZNAM,
	POHLADAVKY_ZOZNAM,
	ZOZNAM_SUHLASOV,
	PRILEZITOSTI
} from '../../utils/routes'
import { PAGES } from '../../utils/enums'

class SidebarMenu extends React.Component {
	static propTypes = {
		page: PropTypes.string,
		sidebarOpened: PropTypes.bool.isRequired,
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				opCislo: PropTypes.string.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		t: PropTypes.func.isRequired
	}

	render() {
		const { t, interakcia, page, sidebarOpened } = this.props
		const { opCislo } = interakcia.data
		return (
			<div className='sidebar menu-sidebar'>
				<div className='sidebar-content'>
					<ul className='side-menu'>
						<li className={cx({ active: page === 'dashboard' })}>
							{!sidebarOpened ? (
								<Tooltip html={t('components:SidebarMenu.Prehľad')} position='right' distance='-60' trigger='mouseenter' theme='light'>
									<Link to={setRouteParams(PREHLAD, opCislo)}>
										<span className='icon dashboard-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Prehľad')}</span>
									</Link>
								</Tooltip>
							) : (
								<Link to={setRouteParams(PREHLAD, opCislo)}>
									<span className='icon dashboard-icon' />
									<span className='menu-item-label'>{t('components:SidebarMenu.Prehľad')}</span>
								</Link>
							)}
						</li>
						<Permissions allowed={[PERMISSIONS.VIEW_OP_ZMLUVY]}>
							<li className={cx({ active: page === 'obchodny-partner' })}>
								{!sidebarOpened ? (
									<Tooltip
										html={t('components:SidebarMenu.Obchodný partner')}
										position='right'
										distance='-60'
										trigger='mouseenter'
										theme='light'
									>
										<Link to={setRouteParams(OP_DETAIL, opCislo)}>
											<span className='icon partner-icon' />
											<span className='menu-item-label'>{t('components:SidebarMenu.Obchodný partner')}</span>
										</Link>
									</Tooltip>
								) : (
									<Link to={setRouteParams(OP_DETAIL, opCislo)}>
										<span className='icon partner-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Obchodný partner')}</span>
									</Link>
								)}
							</li>
						</Permissions>
						<Permissions allowed={[PERMISSIONS.VIEW_SPLNOMOCNENIE]}>
							<li className={cx({ active: this.props.page === 'splnomocnene-osoby' })}>
								{!sidebarOpened ? (
									<Tooltip html={t('components:SidebarMenu.Vzťahy')} position='right' distance='-60' trigger='mouseenter' theme='light'>
										<Link to={setRouteParams(VZTAHY_ZOZNAM, opCislo)}>
											<span className='icon mandates-icon' />
											<span className='menu-item-label'>{t('components:SidebarMenu.Vzťahy')}</span>
										</Link>
									</Tooltip>
								) : (
									<Link to={setRouteParams(VZTAHY_ZOZNAM, opCislo)}>
										<span className='icon mandates-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Vzťahy')}</span>
									</Link>
								)}
							</li>
						</Permissions>
						<Permissions allowed={[PERMISSIONS.VIEW_LEADS]}>
							<li className={cx({ active: page === PAGES.PRILEZITOSTI })}>
								{!sidebarOpened ? (
									<Tooltip html={t('components:SidebarMenu.Príležitosti')} position='right' distance='-60' trigger='mouseenter' theme='light'>
										<Link to={setRouteParams(PRILEZITOSTI, opCislo)}>
											<span className='icon wallet-icon' />
											<span className='menu-item-label'>{t('components:SidebarMenu.Príležitosti')}</span>
										</Link>
									</Tooltip>
								) : (
									<Link to={setRouteParams(PRILEZITOSTI, opCislo)}>
										<span className='icon wallet-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Príležitosti')}</span>
									</Link>
								)}
							</li>
						</Permissions>
						<Permissions allowed={[PERMISSIONS.VIEW_OP_ZMLUVY]}>
							<li className={cx({ active: page === 'zmluvy' })}>
								{!sidebarOpened ? (
									<Tooltip html={t('components:SidebarMenu.Zmluvy')} position='right' distance='-60' trigger='mouseenter' theme='light'>
										<Link to={setRouteParams(ZMLUVY_ZOZNAM, opCislo)}>
											<span className='icon contracts-icon' />
											<span className='menu-item-label'>{t('components:SidebarMenu.Zmluvy')}</span>
										</Link>
									</Tooltip>
								) : (
									<Link to={setRouteParams(ZMLUVY_ZOZNAM, opCislo)}>
										<span className='icon contracts-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Zmluvy')}</span>
									</Link>
								)}
							</li>
						</Permissions>
						<Permissions allowed={[PERMISSIONS.VIEW_POHLADAVKY]}>
							<li className={cx({ active: page === 'stav-uctu' })}>
								{!sidebarOpened ? (
									<Tooltip html={t('components:SidebarMenu.Stav účtu')} position='right' distance='-60' trigger='mouseenter' theme='light'>
										<Link to={setRouteParams(POHLADAVKY_ZOZNAM, opCislo)}>
											<span className='icon balance-icon' />
											<span className='menu-item-label'>{t('components:SidebarMenu.Stav účtu')}</span>
										</Link>
									</Tooltip>
								) : (
									<Link to={setRouteParams(POHLADAVKY_ZOZNAM, opCislo)}>
										<span className='icon balance-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Stav účtu')}</span>
									</Link>
								)}
							</li>
						</Permissions>
						<Permissions allowed={[PERMISSIONS.VIEW_FAKTURY]}>
							<li className={cx({ active: page === 'faktury' })}>
								{!sidebarOpened ? (
									<Tooltip html={t('components:SidebarMenu.Faktúry')} position='right' distance='-60' trigger='mouseenter' theme='light'>
										<Link to={setRouteParams(FAKTURY_ZOZNAM, opCislo)}>
											<span className='icon invoices-icon' />
											<span className='menu-item-label'>{t('components:SidebarMenu.Faktúry')}</span>
										</Link>
									</Tooltip>
								) : (
									<Link to={setRouteParams(FAKTURY_ZOZNAM, opCislo)}>
										<span className='icon invoices-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Faktúry')}</span>
									</Link>
								)}
							</li>
						</Permissions>
						<Permissions allowed={[PERMISSIONS.VIEW_PREDDAVKOVE_PLATBY]}>
							<li className={cx({ active: this.props.page === 'rozpisy-zaloh' })}>
								{!sidebarOpened ? (
									<Tooltip html={t('components:SidebarMenu.Rozpis záloh')} position='right' distance='-60' trigger='mouseenter' theme='light'>
										<Link to={setRouteParams(ROZPISY_ZALOH_ZOZNAM, opCislo)}>
											<span className='icon deposits-icon' />
											<span className='menu-item-label'>{t('components:SidebarMenu.Rozpis záloh')}</span>
										</Link>
									</Tooltip>
								) : (
									<Link to={setRouteParams(ROZPISY_ZALOH_ZOZNAM, opCislo)}>
										<span className='icon deposits-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Rozpis záloh')}</span>
									</Link>
								)}
							</li>
						</Permissions>
						<Permissions allowed={[PERMISSIONS.VIEW_PLAN_SPLATOK]}>
							<li className={cx({ active: this.props.page === 'plany-splatok' })}>
								{!sidebarOpened ? (
									<Tooltip html={t('components:SidebarMenu.Plán splátok')} position='right' distance='-60' trigger='mouseenter' theme='light'>
										<Link to={setRouteParams(PLANY_SPLATOK_ZOZNAM, opCislo)}>
											<span className='icon payment-calendar-icon' />
											<span className='menu-item-label'>{t('components:SidebarMenu.Plán splátok')}</span>
										</Link>
									</Tooltip>
								) : (
									<Link to={setRouteParams(PLANY_SPLATOK_ZOZNAM, opCislo)}>
										<span className='icon payment-calendar-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Plán splátok')}</span>
									</Link>
								)}
							</li>
						</Permissions>
						<Permissions allowed={[PERMISSIONS.VIEW_UPOMIENKY]}>
							<li className={cx({ active: this.props.page === 'upomienky' })}>
								{!sidebarOpened ? (
									<Tooltip html={t('components:SidebarMenu.Upomienky')} position='right' distance='-60' trigger='mouseenter' theme='light'>
										<Link to={setRouteParams(UPOMIENKY_ZOZNAM, opCislo)}>
											<span className='icon reminders-icon' />
											<span className='menu-item-label'>{t('components:SidebarMenu.Upomienky')}</span>
										</Link>
									</Tooltip>
								) : (
									<Link to={setRouteParams(UPOMIENKY_ZOZNAM, opCislo)}>
										<span className='icon reminders-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Upomienky')}</span>
									</Link>
								)}
							</li>
						</Permissions>
						<Permissions allowed={[PERMISSIONS.VIEW_SUHLASY]}>
							<li className={cx({ active: page === PAGES.SPRAVA_SUHLASOV })}>
								{!sidebarOpened ? (
									<Tooltip html={t('components:SidebarMenu.Súhlasy')} position='right' distance='-60' trigger='mouseenter' theme='light'>
										<Link to={setRouteParams(ZOZNAM_SUHLASOV, opCislo)}>
											<span className='icon consent-icon' />
											<span className='menu-item-label'>{t('components:SidebarMenu.Súhlasy')}</span>
										</Link>
									</Tooltip>
								) : (
									<Link to={setRouteParams(ZOZNAM_SUHLASOV, opCislo)}>
										<span className='icon consent-icon' />
										<span className='menu-item-label'>{t('components:SidebarMenu.Súhlasy')}</span>
									</Link>
								)}
							</li>
						</Permissions>
					</ul>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail
})

export default compose(withTranslation('components'), connect(mapStateToProps))(SidebarMenu)
